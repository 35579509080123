import Dexie from "dexie";
import { SentryService } from "services";

const indexes = {
  primaryKey: "id",
  collectorLayouts: {
    uniqueFullPath:
      "&[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId+collectorId]",
    duplicated: "duplicated",
    layoutId: "[orderId+docId+layoutId]",
    hasUnsuccessPhotoLayouts: "[orderId+docId+hasUnsuccessPhotoLayouts]",
    neighborReview: "[templateId+serviceTaskId+reviewId]",
    owned: {
      service: "[orderId+docId+templateId+serviceId]",
      serviceTask: "[orderId+docId+templateId+serviceId+serviceTaskId]",
      review: "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId]",
      group:
        "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId]",
      subgroup:
        "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId]",
      dupReview:
        "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+duplicated]",
      dupGroup:
        "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+duplicated]",
      dupSubgroup:
        "[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId+duplicated]",
    },
  },
  collectorLayoutPhotos: {
    uniqueFullPath:
      "&[orderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId+collectorId+photoId]",
    layoutPhotoId: "layoutPhotoId",
    collectorLayoutId: "collectorLayoutId",
    duplicated: "duplicated",
  },
  collectorValues: {
    uniqueFullPath:
      "&[auditOrderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId+collectorId]",
  },
  assets: {
    uniqueFullPath:
      "&[auditOrderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId+collectorId+photoId]",
  },
  data: {
    serviceTask: "[auditOrderId+docId+templateId+serviceId+serviceTaskId]",
    review: "[auditOrderId+docId+templateId+serviceId+serviceTaskId+reviewId]",
    group:
      "[auditOrderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId]",
    subgroup:
      "[auditOrderId+docId+templateId+serviceId+serviceTaskId+reviewId+groupId+subgroupId]",
    neighborServiceTask: "[auditOrderId+docId+templateId+serviceTaskId]",
    neighborReview: "[auditOrderId+docId+templateId+serviceTaskId+reviewId]",
    neighborGroup:
      "[auditOrderId+docId+templateId+serviceTaskId+reviewId+groupId]",
    neighborSubgroup:
      "[auditOrderId+docId+templateId+serviceTaskId+reviewId+groupId+subgroupId]",
    status: "[auditOrderId+docId+status]",
    anonymousNeighborReview: "[templateId+serviceTaskId+reviewId+status]",
  },
  status: "status",
  inventoryItemId: "inventoryItemId",
};

const db = new Dexie("syn4pse");
db.version(28).stores({
  collectorLayouts: `
    ${indexes.primaryKey},
    ${indexes.collectorLayouts.uniqueFullPath},
    ${indexes.collectorLayouts.duplicated},
    ${indexes.collectorLayouts.layoutId},
    ${indexes.collectorLayouts.hasUnsuccessPhotoLayouts},
    ${indexes.collectorLayouts.neighborReview},
    ${indexes.collectorLayouts.owned.service},
    ${indexes.collectorLayouts.owned.serviceTask},
    ${indexes.collectorLayouts.owned.review},
    ${indexes.collectorLayouts.owned.group},
    ${indexes.collectorLayouts.owned.subgroup},
    ${indexes.collectorLayouts.owned.dupReview},
    ${indexes.collectorLayouts.owned.dupGroup},
    ${indexes.collectorLayouts.owned.dupSubgroup}
  `,
  collectorLayoutPhotos: `
    ${indexes.primaryKey},
    ${indexes.collectorLayoutPhotos.uniqueFullPath},
    ${indexes.collectorLayoutPhotos.layoutPhotoId},
    ${indexes.collectorLayoutPhotos.collectorLayoutId},
    ${indexes.collectorLayoutPhotos.duplicated}
  `,
  collectorValues: `
    ${indexes.primaryKey},
    ${indexes.status},
    ${indexes.inventoryItemId},
    ${indexes.collectorValues.uniqueFullPath},
    ${indexes.data.serviceTask},
    ${indexes.data.review},
    ${indexes.data.group},
    ${indexes.data.subgroup},
    ${indexes.data.neighborServiceTask},
    ${indexes.data.neighborReview},
    ${indexes.data.neighborGroup},
    ${indexes.data.neighborSubgroup},
    ${indexes.data.anonymousNeighborReview}
  `,
  assets: `
    ${indexes.primaryKey},
    ${indexes.assets.uniqueFullPath},
    ${indexes.status},
    ${indexes.data.serviceTask},
    ${indexes.data.review},
    ${indexes.data.group},
    ${indexes.data.subgroup},
    ${indexes.data.neighborServiceTask},
    ${indexes.data.neighborReview},
    ${indexes.data.neighborGroup},
    ${indexes.data.neighborSubgroup},
    ${indexes.data.status},
    ${indexes.data.anonymousNeighborReview}
  `,
});

export default class DatabaseOperation {
  static indexes = indexes;
  static storeNames = {
    collectorLayouts: "collectorLayouts",
    collectorLayoutPhotos: "collectorLayoutPhotos",
    collectorValues: "collectorValues",
    assets: "assets",
  };
  static stores = {
    [this.storeNames.collectorLayouts]: db.collectorLayouts,
    [this.storeNames.collectorLayoutPhotos]: db.collectorLayoutPhotos,
    [this.storeNames.collectorValues]: db.collectorValues,
    [this.storeNames.assets]: db.assets,
  };

  static add(storeName, item) {
    return db[storeName].add(item).catch((err) => {
      if (err.name === "ConstraintError") {
        return this.put(storeName, item);
      }
      SentryService.sendError(err);
    });
  }

  static bulkAdd(storeName, items) {
    return db[storeName].bulkAdd(items).catch(Dexie.BulkError, (err) => err);
  }

  static put(storeName, item) {
    return db[storeName].put(item);
  }

  static bulkPut(storeName, items, keys) {
    return db[storeName].bulkPut(items, keys);
  }

  static where(storeName, clause) {
    return db[storeName].where(clause);
  }

  static get(storeName, clause) {
    return db[storeName].get(clause);
  }

  static bulkGet(storeName, keys) {
    return db[storeName].bulkGet(keys);
  }

  static update(storeName, id, item) {
    return db[storeName].update(id, item).catch(async (err) => {
      if (err.message.includes("ConstraintError")) {
        const storedItem = await this.get(storeName, item.id);
        await this.delete(storeName, id);
        return this.put(storeName, storedItem);
      }
      SentryService.sendError(err);
    });
  }

  static delete(storeName, id) {
    return db[storeName].delete(id);
  }

  static bulkDelete(storeName, keys) {
    return db[storeName].bulkDelete(keys);
  }

  static clear(storeName) {
    return db[storeName].clear();
  }

  static transaction(store, callback) {
    return db.transaction("rw", store, callback);
  }
}
