//Libs
import React from "react";
import { useSelector } from "react-redux";
//Selectors
import { selectReviewManage } from "components/components/Collector/selectors";
//Hooks
import { useCollectorLayout, useInitializeCollectorEcosystem } from "hooks";
//Components
import {
  Wrapper,
  CollectorLayout,
  AvatarCarousel,
  CollectorUtils,
  Title,
} from "components/components";
import { EmptyIllustration } from "components/components/Illustrations";

function ReviewsTab({ order }) {
  const reviewManage = useSelector(selectReviewManage);
  const { transactionDocs } = reviewManage;
  const {
    segment,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
    onLoadSegmentedResources,
  } = useCollectorLayout({
    orderId: order.order_id,
    docId: transactionDocs.docId,
  });
  const initializeCollectorEcosystem = useInitializeCollectorEcosystem();

  const onAvatarClick = React.useCallback(
    ({ docId }) =>
      initializeCollectorEcosystem({ orderId: order.order_id, docId }),
    [order.order_id, transactionDocs.data]
  );

  //Get selected avatar id
  const selectedAvatarId = React.useMemo(
    () =>
      CollectorUtils.getAvatarIdFromSelectedTransactionDoc(
        transactionDocs.data,
        transactionDocs.docId
      ),
    [transactionDocs.data, transactionDocs.docId]
  );

  if (!transactionDocs.isFetching && transactionDocs.data.length === 0) {
    return (
      <Wrapper height="300px" justifyContent="center" flexDirection="column">
        <EmptyIllustration />
        <Title margin="16px 0 0 0" textAlign="center">
          No se encontró ningún reporte
        </Title>
      </Wrapper>
    );
  }

  return (
    <Wrapper padding="0" flexDirection="column" alignItems="stretch">
      <AvatarCarousel
        size="large"
        selectedAvatarId={selectedAvatarId}
        avatarList={CollectorUtils.formatAvatars(transactionDocs.data)}
        skeleton={transactionDocs.isFetching}
        spin={reviewManage.isFetching}
        onClick={onAvatarClick}
      />
      <CollectorLayout
        segment={segment}
        order={order}
        docId={transactionDocs.docId}
        readOnlyCollector={readOnlyCollector}
        collectorLayout={collectorLayout}
        duplicatedCollectorLayout={duplicatedCollectorLayout}
        filteredCollectorLayout={filteredCollectorLayout}
        collectorValues={collectorValues}
        resources={resources}
        onLoadSegmentedResources={onLoadSegmentedResources}
      />
    </Wrapper>
  );
}

export default ReviewsTab;
