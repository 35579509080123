/* eslint-disable array-callback-return */
// Database
import DatabaseOperation from "../init";
import { CollectorUtils } from "components/components";
// Utils
import GENERAL from "utils/constants/general";
import BroadCast from "core/Broadcast";
import CommonOperations from "../CommonOperations";
import {
  AdaptCollectorLayoutsToReviews,
  DeserializedCollectorLayout,
  SerializedCollectorLayout,
} from "./models";
import { GlobalUtils } from "utils";
import CollectorLayoutPhotoRepository from "../CollectorLayoutPhotos/CollectorLayoutPhotoRepository";
import { SentryService } from "services";

const { TEMPLATES } = GENERAL.ENV;

const adaptCollectorLayoutsToReviews = (collectorLayouts) =>
  new AdaptCollectorLayoutsToReviews(collectorLayouts);
const serializeCollectorLayout = (item) => new SerializedCollectorLayout(item);
const deserializeCollectorLayout = (cl) =>
  cl && new DeserializedCollectorLayout(cl);
const deserializeCollectorLayouts = (collectorLayouts) =>
  collectorLayouts.map(deserializeCollectorLayout);

const mergePhotoLayouts = async (items, { isDuplicated } = {}) => {
  const item = (i) => {
    const collectorLayout = serializeCollectorLayout(i);
    return {
      ...deserializeCollectorLayout(collectorLayout), // We need some attributes of DeserializeCollectorLayout(SerializeCollectorLayout), eg: duplicatedId and collectorId
      ...i,
      photos: undefined,
      collectorLayoutId: collectorLayout.id,
    };
  };

  const photos = items.reduce((acc, i) => {
    acc = [
      ...acc,
      ...GlobalUtils.checkArray(i.photos).map((p) => {
        delete p.collectorLayoutId;
        return {
          ...item(i),
          ...p,
        };
      }),
    ];

    if (i.layoutPhotoId !== undefined) acc.push(item(i));

    return acc;
  }, []);
  if (!photos.length) return;

  return CollectorLayoutPhotoRepository.merge(photos, isDuplicated);
};

const loadPhotoLayouts = async (collectorLayouts, orderId, docId) => {
  const ownedSerializedCollectorLayoutId = (cl) =>
    serializeCollectorLayout({ ...cl, orderId, docId }).id;
  const isDuplicated = (value) => value === "true";

  const collectorLayoutIds = [
    ...new Set(
      collectorLayouts.reduce((acc, cl) => {
        if (isDuplicated(cl.duplicated))
          return [...acc, cl.id, ownedSerializedCollectorLayoutId(cl)];

        return [...acc, cl.id];
      }, [])
    ),
  ];

  const storedPhotos = await CollectorLayoutPhotoRepository.load(
    collectorLayoutIds
  );

  for (let cl of collectorLayouts) {
    cl.photos = storedPhotos.filter(
      (sp) =>
        sp.collectorLayoutId === cl.id ||
        (isDuplicated(cl.duplicated) &&
          sp.collectorLayoutId === ownedSerializedCollectorLayoutId(cl))
    );
  }

  return collectorLayouts;
};

const checkBaseLoadParams = (seg) => seg.templateId && seg.serviceId;

const addDuplicatedId = (items) =>
  items.map((i) => ({ ...i, duplicatedId: i.duplicatedId || i.id }));

const buildNeighborhood = (items) =>
  items.reduce((acc, i) => {
    return CollectorUtils.serializeNeighborhood(
      {
        ...i,
        ...serializeCollectorLayout(i),
      },
      acc
    );
  }, {});

export default class CollectorLayoutRepository {
  static storeName = DatabaseOperation.storeNames.collectorLayouts;

  static put(item) {
    const collectorLayout = serializeCollectorLayout(item);
    delete collectorLayout.duplicatedId;

    return DatabaseOperation.put(this.storeName, collectorLayout);
  }

  static delete(id) {
    return DatabaseOperation.delete(this.storeName, id);
  }

  static loadCollectorLayout(seg) {
    if (!checkBaseLoadParams(seg)) return [];

    const {
      orderId,
      docId,
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
    } = CommonOperations.segment(seg);

    const serviceLevel = () => {
      const index = DatabaseOperation.indexes.collectorLayouts.owned.service;
      const query = ["null", "null", templateId, serviceId];

      return DatabaseOperation.where(this.storeName, index)
        .equals(query)
        .or(index)
        .equals([orderId, docId, ...query.slice(2)]);
    };

    const serviceTaskLevel = () => {
      const index =
        DatabaseOperation.indexes.collectorLayouts.owned.serviceTask;
      const query = ["null", "null", templateId, serviceId, serviceTaskId];

      return DatabaseOperation.where(this.storeName, index)
        .equals(query)
        .or(index)
        .equals([orderId, docId, ...query.slice(2)]);
    };

    const reviewLevel = () => {
      const index = DatabaseOperation.indexes.collectorLayouts.owned.review;
      const query = [
        "null",
        "null",
        templateId,
        serviceId,
        serviceTaskId,
        reviewId,
      ];

      return DatabaseOperation.where(this.storeName, index)
        .equals(query)
        .or(index)
        .equals([orderId, docId, ...query.slice(2)]);
    };

    const groupLevel = () => {
      const index = DatabaseOperation.indexes.collectorLayouts.owned.group;
      const query = [
        "null",
        "null",
        templateId,
        serviceId,
        serviceTaskId,
        reviewId,
        groupId,
      ];

      return reviewLevel()
        .or(index)
        .equals(query)
        .or(index)
        .equals([orderId, docId, ...query.slice(2)]);
    };

    const subgroupLevel = () => {
      const index = DatabaseOperation.indexes.collectorLayouts.owned.subgroup;
      const query = [
        "null",
        "null",
        templateId,
        serviceId,
        serviceTaskId,
        reviewId,
        groupId,
        subgroupId,
      ];

      return groupLevel()
        .or(index)
        .equals(query)
        .or(index)
        .equals([orderId, docId, ...query.slice(2)]);
    };

    const load = () => {
      if (subgroupId) return subgroupLevel();
      if (groupId) return groupLevel();
      if (reviewId) return reviewLevel();
      if (serviceTaskId) return serviceTaskLevel();
      return serviceLevel();
    };

    return load()
      .toArray()
      .then((collectorLayouts) =>
        loadPhotoLayouts(collectorLayouts, orderId, docId)
      )
      .then(deserializeCollectorLayouts)
      .then(adaptCollectorLayoutsToReviews)
      .catch(SentryService.sendError);
  }

  static unsuccessDuplicatedCollectorLayout(seg) {
    const { orderId, docId } = CommonOperations.segment(seg);
    if (!orderId) return;

    const query = [orderId, docId];
    return DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.collectorLayouts.layoutId
    )
      .equals([...query, "null"])
      .or(DatabaseOperation.indexes.collectorLayouts.hasUnsuccessPhotoLayouts)
      .equals([...query, "true"])
      .toArray()
      .then((collectorLayouts) =>
        loadPhotoLayouts(collectorLayouts, orderId, docId)
      )
      .then(deserializeCollectorLayouts)
      .catch(SentryService.sendError);
  }

  static primaryKeys({ isDuplicated } = {}) {
    const duplicated = isDuplicated ? "true" : "false";

    return DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.collectorLayouts.duplicated
    )
      .equals(duplicated)
      .primaryKeys();
  }

  static async mergeOriginalCollectorLayouts(templates) {
    try {
      const collectorLayoutTemplates = templates.filter(
        (t) => t.template_type_id === TEMPLATES.TYPES.AUDIT_ORDER
      );
      const items = collectorLayoutTemplates.reduce((acc, t) => {
        t.data_structure_object.forEach((review) =>
          review.collectors.forEach((collector) => {
            acc.push({
              ...collector,
              serviceTaskName: review.serviceTaskName,
              serviceTaskProps: review.serviceTaskProps,
              templateId: t.id,
            });
          })
        );

        return acc;
      }, []);

      // Delete all current layouts
      const keys = await this.primaryKeys();
      await DatabaseOperation.bulkDelete(this.storeName, keys);

      // Upsert new layouts
      const collectorLayouts = items.map(serializeCollectorLayout);
      await DatabaseOperation.bulkPut(this.storeName, collectorLayouts);

      // Upsert photo layouts
      await mergePhotoLayouts(items);

      new BroadCast().postReloadFullCollectorLayout();
    } catch (err) {
      SentryService.sendError(err);
    }
  }

  static async mergeDuplicatedCollectorLayouts(_items) {
    try {
      const items = addDuplicatedId(_items);

      const collectorLayouts = items
        .filter((i) => i.layoutPhotoId === undefined)
        .map(serializeCollectorLayout);

      const neighborhood = buildNeighborhood(items);

      const batchSize = 10;
      await DatabaseOperation.transaction(
        DatabaseOperation.stores.collectorLayouts,
        async () => {
          for (let i = 0; i < collectorLayouts.length; i += batchSize) {
            const slicedCollectorLayouts = collectorLayouts.slice(
              i,
              i + batchSize
            );
            const slicedCollectorLayoutIds = [
              ...new Set(
                slicedCollectorLayouts
                  .reduce((acc, ncl) => [...acc, ncl.id, ncl.duplicatedId], [])
                  .filter((ncl) => !!ncl)
              ),
            ];

            const storedCollectorLayouts = await DatabaseOperation.bulkGet(
              this.storeName,
              slicedCollectorLayoutIds
            ).then((scls) => scls.filter((scl) => !!scl));

            const promises = slicedCollectorLayouts.map((ncl) => {
              const scl = storedCollectorLayouts.find((scl) =>
                [ncl.id, ncl.duplicatedId].includes(scl.id)
              );

              const deleteDuplicatedRecordId =
                ncl.id !== ncl.duplicatedId && ncl.duplicatedId;

              if (!scl) return !ncl.collectorLayoutDeleted && this.put(ncl);

              const shouldUpdate =
                new Date(ncl.createdAt) - new Date(scl.createdAt) >= 0;
              if (!shouldUpdate) return;

              if (ncl.collectorLayoutDeleted) return this.delete(scl.id);

              return Promise.all([
                deleteDuplicatedRecordId &&
                  this.delete(deleteDuplicatedRecordId),
                this.put({
                  ...scl,
                  ...CommonOperations.removeFalsifyProps(ncl),
                }),
              ]);
            });

            await Promise.all(promises);
          }

          return this.syncNeighboringRecords(neighborhood);
        }
      );

      await mergePhotoLayouts(items, { isDuplicated: true });

      new BroadCast().postReloadFullCollectorLayout(neighborhood);
    } catch (err) {
      SentryService.sendError(err);
    }
  }

  static async syncNeighboringRecords(neighborhood) {
    for (let [key, value] of Object.entries(neighborhood)) {
      const [templateId, serviceTaskId, reviewName, groupName, subgroupName] =
        CollectorUtils.deserializeNeighborhood(key);

      const reviewId = CollectorUtils.getDuplicableKeyOrId({
        level: !groupName && "review",
        reviewName,
        reviewId: value.reviewId,
      });

      const reviewLevel = () => {
        const { neighborReview: index } =
          DatabaseOperation.indexes.collectorLayouts;
        const reviewQuery = [
          Number(templateId),
          Number(serviceTaskId),
          reviewId,
        ];

        return DatabaseOperation.where(this.storeName, index)
          .equals(reviewQuery)
          .and((r) => r.reviewName === reviewName)
          .toArray();
      };

      const promises = await reviewLevel().then((records) => {
        return records.reduce((acc, record) => {
          const changes = {};

          if (
            CommonOperations.isSynchedId(value.reviewId) &&
            CollectorUtils.checkDuplicable(record.reviewId)
          )
            changes.reviewId = value.reviewId;

          if (
            CommonOperations.isSynchedId(value.groupId) &&
            CollectorUtils.checkDuplicable(record.groupId) &&
            groupName &&
            record.groupName === groupName
          )
            changes.groupId = value.groupId;

          if (
            CommonOperations.isSynchedId(value.subgroupId) &&
            CollectorUtils.checkDuplicable(record.subgroupId) &&
            subgroupName &&
            record.subgroupName === subgroupName
          )
            changes.subgroupId = value.subgroupId;

          if (!Object.keys(changes).length) return acc;

          changes.id = CommonOperations.collectorLayoutId({
            ...record,
            ...changes,
          });

          acc.push(
            DatabaseOperation.update(this.storeName, record.id, changes)
          );

          return acc;
        }, []);
      });

      await Promise.all(promises);
    }
  }

  static async deleteDuplicatedCollectorLayout(deleteElement) {
    try {
      const {
        orderId,
        docId,
        templateId,
        serviceId,
        serviceTaskId,
        reviewId,
        groupId,
        subgroupId,
      } = CommonOperations.segment(deleteElement);

      if (!orderId && !docId) return;

      const reviewLevel = () => {
        return DatabaseOperation.where(
          this.storeName,
          DatabaseOperation.indexes.collectorLayouts.owned.dupReview
        ).equals([
          orderId,
          docId,
          templateId,
          serviceId,
          serviceTaskId,
          reviewId,
          "true",
        ]);
      };

      const groupLevel = () => {
        return DatabaseOperation.where(
          this.storeName,
          DatabaseOperation.indexes.collectorLayouts.owned.dupGroup
        ).equals([
          orderId,
          docId,
          templateId,
          serviceId,
          serviceTaskId,
          reviewId,
          groupId,
          "true",
        ]);
      };

      const subgroupLevel = () => {
        return DatabaseOperation.where(
          this.storeName,
          DatabaseOperation.indexes.collectorLayouts.owned.dupSubgroup
        ).equals([
          orderId,
          docId,
          templateId,
          serviceId,
          serviceTaskId,
          reviewId,
          groupId,
          subgroupId,
          "true",
        ]);
      };

      const load = () => {
        if (subgroupId) return subgroupLevel();
        if (groupId) return groupLevel();
        return reviewLevel();
      };

      const keys = await load().primaryKeys();
      await DatabaseOperation.bulkDelete(this.storeName, keys);

      new BroadCast().postReloadFullCollectorLayout();
    } catch (err) {
      SentryService.sendError(err);
    }
  }

  static async cleanDuplicatedCollectorLayouts() {
    try {
      await CollectorLayoutPhotoRepository.cleanDuplicatedCollectorLayoutPhotos();

      const keys = await this.primaryKeys({ isDuplicated: true });
      await DatabaseOperation.bulkDelete(this.storeName, keys);
      new BroadCast().postReloadFullCollectorLayout();
    } catch (err) {
      SentryService.sendError(err);
    }
  }
}
