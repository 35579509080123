/* eslint-disable array-callback-return */
// Database
import DatabaseOperation from "../init";
// Utils
import {
  SerializedCollectorLayoutPhoto,
  DeserializedCollectorLayoutPhoto,
} from "./models";
import CollectorLayoutPhotoUtils from "./CollectorLayoutPhotoUtils";
import { SentryService } from "services";
import CommonOperations from "../CommonOperations";

const serializeCollectorLayoutPhoto = (item) =>
  new SerializedCollectorLayoutPhoto(item);
const deserializeCollectorLayoutPhoto = (clp) =>
  clp && new DeserializedCollectorLayoutPhoto(clp);
const deserializeCollectorLayoutPhotos = (collectorLayoutPhotos) =>
  collectorLayoutPhotos.map(deserializeCollectorLayoutPhoto);

const storeName = DatabaseOperation.storeNames.collectorLayoutPhotos;

const put = (item) => {
  delete item.duplicatedId;
  return DatabaseOperation.put(storeName, item);
};

const buildNeighborhood = (items) =>
  items.reduce((acc, i) => {
    return CollectorLayoutPhotoUtils.serializeNeighborhood(
      {
        ...i,
        ...serializeCollectorLayoutPhoto(i),
      },
      acc
    );
  }, {});

const getRelativeDuplicatedIdMask = (duplicatedId) => {
  const regex = /^.*?duplicated#\d+/;
  const mask = duplicatedId.match(regex);

  if (!mask?.length) return duplicatedId;

  return mask[0];
};

export default class CollectorLayoutPhotoRepository {
  static storeName = storeName;

  static delete(id) {
    return DatabaseOperation.delete(this.storeName, id);
  }

  static load(collectorLayoutIds) {
    return DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.collectorLayoutPhotos.collectorLayoutId
    )
      .anyOf(collectorLayoutIds)
      .toArray()
      .then(deserializeCollectorLayoutPhotos);
  }

  static async merge(items, isDuplicated) {
    const batchSize = 10;

    const collectorLayoutPhotos = items
      .map(serializeCollectorLayoutPhoto)
      .filter(
        (nclp) => nclp.isPhoto && (!isDuplicated || nclp.duplicated === "true")
      );

    return DatabaseOperation.transaction(
      DatabaseOperation.stores.collectorLayoutPhotos,
      async () => {
        for (let i = 0; i < collectorLayoutPhotos.length; i += batchSize) {
          const slicedCollectorLayoutPhotos = collectorLayoutPhotos.slice(
            i,
            i + batchSize
          );
          const slicedCollectorLayoutPhotoIds = [
            ...new Set(
              slicedCollectorLayoutPhotos
                .reduce((acc, nclp) => [...acc, nclp.id, nclp.duplicatedId], [])
                .filter((nclp) => !!nclp)
            ),
          ];

          const storedCollectorLayoutPhotos = await DatabaseOperation.bulkGet(
            this.storeName,
            slicedCollectorLayoutPhotoIds
          ).then((sclps) => sclps.filter((sclp) => !!sclp));

          const promises = slicedCollectorLayoutPhotos.map((nclp) => {
            const sclp = storedCollectorLayoutPhotos.find((sclp) =>
              [nclp.id, nclp.duplicatedId].includes(sclp.id)
            );

            const deleteDuplicatedRecordId =
              nclp.id !== nclp.duplicatedId && nclp.duplicatedId;

            if (!sclp) return !nclp.photoLayoutDeleted && put(nclp);

            const shouldUpdate =
              new Date(nclp.createdAt) - new Date(sclp.createdAt) >= 0;
            if (!shouldUpdate) return;

            if (nclp.photoLayoutDeleted) return this.delete(sclp.id);

            return Promise.all([
              deleteDuplicatedRecordId && this.delete(deleteDuplicatedRecordId),
              put(nclp),
            ]);
          });

          await Promise.all(promises);
        }

        const neighborhood = buildNeighborhood(items);
        return this.syncNeighboringRecords(neighborhood);
      }
    );
  }

  static async syncNeighboringRecords(neighborhood) {
    if (!Object.keys(neighborhood).length) return;

    const photoLevel = async () => {
      const { collectorLayoutId: index } =
        DatabaseOperation.indexes.collectorLayoutPhotos;

      const neighborhoodKeys = [
        ...new Set(Object.keys(neighborhood).map(getRelativeDuplicatedIdMask)),
      ];
      if (!neighborhoodKeys.length) return Promise.resolve([]);

      let totalRecords = [];
      for (let relativeCollectorLayoutId of neighborhoodKeys) {
        const records = await DatabaseOperation.where(this.storeName, index)
          .startsWith(relativeCollectorLayoutId)
          .toArray();
        totalRecords = totalRecords.concat(records);
      }

      return totalRecords;
    };

    const promises = await photoLevel().then((records) => {
      return records.reduce((acc, record) => {
        const deserializedCollectorEcosystemId =
          CommonOperations.removeFalsifyProps(
            CommonOperations.deserializeCollectorEcosystemId(
              record.collectorLayoutId
            )
          );
        const neighbor = CommonOperations.getNearestNeighbor(
          neighborhood,
          record.collectorLayoutId
        );
        const collectorLayoutPhoto = serializeCollectorLayoutPhoto({
          ...deserializeCollectorLayoutPhoto(record),
          ...deserializedCollectorEcosystemId,
          ...neighbor,
          collectorLayoutId: undefined,
        });
        const changes = {};

        if (
          !!neighbor &&
          CollectorLayoutPhotoUtils.isDuplicatedCollectorLayoutPhotoOutOfSync(
            record.collectorLayoutId
          )
        )
          changes.collectorLayoutId = collectorLayoutPhoto.collectorLayoutId;

        if (!Object.keys(changes).length) return acc;

        changes.id = collectorLayoutPhoto.id;

        acc.push(DatabaseOperation.update(this.storeName, record.id, changes));

        return acc;
      }, []);
    });

    return Promise.all(promises);
  }

  static async cleanDuplicatedCollectorLayoutPhotos() {
    try {
      const keys = await DatabaseOperation.where(
        this.storeName,
        DatabaseOperation.indexes.collectorLayoutPhotos.duplicated
      )
        .equals("true")
        .primaryKeys();

      return DatabaseOperation.bulkDelete(this.storeName, keys);
    } catch (err) {
      SentryService.sendError(err);
    }
  }
}
