//Libs
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
//Components
import { Link } from "react-router-dom";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Select } from "antd";
import {
	Wrapper,
	Imagotype,
	OnboardMainButton,
	Subtitle,
} from "components/components";
//Utils
import { filterOption } from "utils/libs";
import AuthService from "utils/libs/auth/AuthService";
import GENERAL from "utils/constants/general";
//Actions
import * as actions from "./actions";

//Styles
import "./index.css";
const styles = (theme) => ({
	textField: {
		width: "200px",
		minHeight: "48px",
		height: "48px",
		paddingLeft: "15px",
		backgroundColor: "#E8E8E8",
		color: "rgba(0, 0, 0, 0.6)",
		marginBottom: "10px",
		fontFamily: "Raleway",
	},
	icon: {
		color: "rgba(0, 0, 0, 0.6)",
	},
});

const { Option } = Select;
const { ENV } = GENERAL;

class Signup extends Component {
	constructor(...props) {
		super(...props);

		this.Auth = new AuthService();
	}

	/******************** LIFECYCLES ********************/
	componentDidMount() {
		this.props.getSignupDataWorker();
	}

	componentWillMount() {
		if (this.Auth.loggedIn()) {
			return <Redirect to="/" />;
		}
	}

	componentWillUnmount() {
		this.props.doReset();
	}

	/******************** JSX RENDER ********************/
	render() {
		const {
			classes,
			idc,
			name,
			phone,
			password,
			entity_id,
			organization_id,
			company_id,
			status,
			loading,
			showPassword,
			mutateDirectProps,
			doSignup,
			companies,
			organizations,
			entities,
		} = this.props;
		const filteredOrganizations = organizations.filter(
			(org) => org.company_id === company_id
		);
		const filteredEntities = entities.filter(
			(entity) => entity.organization_id === organization_id
		);

		return (
			<Wrapper
				className="animated fadeIn"
				padding="0"
				position="relative"
				display="flex"
				flexDirection="column"
			>
				<Wrapper
					position="relative"
					display="flex"
					flexDirection="column"
					padding="4em 1.5em 1.5em 1.5em"
					maxWidth="370px"
					height="500px"
					borderRadius="50px"
					boxShadow="0 8px 16px 0 rgb(0 0 0)"
				>
					{/* LOGO */}
					<Imagotype position="absolute" top="-55px" opacity=".7" />

					{/* IDENTIFICATION */}
					<Input
						name="idc"
						placeholder="Identidad*"
						className={classes.textField}
						onChange={(e) => mutateDirectProps({ idc: e.target.value?.trim() })}
						value={idc}
						type="text"
					/>

					{/* NAME */}
					<Input
						name="name"
						placeholder="Nombre*"
						className={classes.textField}
						onChange={(e) => mutateDirectProps({ name: e.target.value })}
						value={name}
						type="text"
					/>

					{/* TELEFONO */}
					<Input
						className={classes.textField}
						name="phone"
						type="number"
						placeholder="Teléfono*"
						value={phone}
						onChange={(e) =>
							mutateDirectProps({ phone: e.target.value?.trim() })
						}
					/>

					{/* PASSWORD */}
					<Input
						placeholder="Contraseña*"
						id="adornment-password"
						name="password"
						className={classes.textField}
						type={showPassword ? "text" : "password"}
						value={password}
						onChange={(e) =>
							mutateDirectProps({ password: e.target.value?.trim() })
						}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									onClick={() =>
										mutateDirectProps({ showPassword: !showPassword })
									}
									onMouseDown={(e) => e.preventDefault()}
								>
									{showPassword ? (
										<VisibilityOff className={classes.icon} />
									) : (
										<Visibility className={classes.icon} />
									)}
								</IconButton>
							</InputAdornment>
						}
					/>

					{/* COMPANY */}
					<Select
						id="container-signup-form-selects"
						showSearch
						value={company_id}
						placeholder="Empresa*"
						filterOption={filterOption}
						onChange={(value) =>
							mutateDirectProps({
								company_id: value,
								organization_id: undefined,
								entity_id: undefined,
							})
						}
					>
						{companies.map((company, idx) => (
							<Option key={idx} value={company.id}>
								<em>{company.name}</em>
							</Option>
						))}
					</Select>

					{/* ORGANIZATION */}
					<Select
						id="container-signup-form-selects"
						showSearch
						value={organization_id}
						placeholder="Organización*"
						filterOption={filterOption}
						onChange={(value) =>
							mutateDirectProps({
								organization_id: value,
								entity_id: undefined,
							})
						}
					>
						{filteredOrganizations.map((organization, idx) => (
							<Option key={idx} value={organization.id}>
								<em>{organization.name}</em>
							</Option>
						))}
					</Select>

					{/* ENTITY */}
					<Select
						id="container-signup-form-selects"
						showSearch
						value={entity_id}
						placeholder="Entidad*"
						filterOption={filterOption}
						onChange={(value) => mutateDirectProps({ entity_id: value })}
					>
						{filteredEntities.map((entity, idx) => (
							<Option key={idx} value={entity.id}>
								<em>{entity.name}</em>
							</Option>
						))}
					</Select>

					{/* LOGIN-BUTTON */}
					<div className="container-signup-form-action-buttons">
						{loading ? (
							<div
								style={{
									maxWidth: "800px",
									marginTop: "15px",
									minWidth: 24,
									textAlign: "center",
								}}
							>
								<CircularProgress
									variant="indeterminate"
									size={24}
									thickness={4}
								/>
							</div>
						) : (
							<div className="container-signup-form-action-buttons">
								{/* BUTTON */}
								<OnboardMainButton
									variant={status === "error" ? "contained" : "outlined"}
									color={status === "error" ? "secondary" : "primary"}
									onClick={() =>
										doSignup(
											idc,
											name,
											phone,
											password,
											entity_id,
											organization_id
										)
									}
								>
									Registrarme
								</OnboardMainButton>

								{/* LABEL */}
								<Wrapper
									width="100%"
									padding="0"
									margin="10px 0 0 0"
									justifyContent="space-around"
								>
									<Subtitle>¿Ya tiene cuenta?</Subtitle>
									<Link to={ENV.ROUTES.PATHS.LOGIN}>Ingresar</Link>
								</Wrapper>
							</div>
						)}
					</div>
				</Wrapper>
			</Wrapper>
		);
	}
}

/******************** PROPTYPES ********************/
Signup.propTypes = {
	//Props
	classes: PropTypes.object.isRequired,
	//Functions
	mutateDirectProps: PropTypes.func.isRequired,
	doSignup: PropTypes.func.isRequired,
	doReset: PropTypes.func.isRequired,
};

/******************** EXPORT ********************/
export default connect(
	(state) => ({
		idc: state.signup.idc,
		name: state.signup.name,
		phone: state.signup.phone,
		password: state.signup.password,
		entity_id: state.signup.entity_id,
		organization_id: state.signup.organization_id,
		company_id: state.signup.company_id,
		status: state.signup.status,
		loading: state.signup.loading,
		showPassword: state.signup.showPassword,
		companies: state.signup.companies,
		organizations: state.signup.organizations,
		entities: state.signup.entities,
	}),
	actions
)(withStyles(styles)(Signup));
