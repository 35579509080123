// Libs
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "underscore";
// Utils
import {
  CollectorLayoutRepository,
  CollectorValueRepository,
} from "core/database";
import { CollectorUtils } from "components/components";
//Actions
import {
  mutate1Object,
  setAutoFillCollector,
  setCollectorValuesV2,
} from "components/components/Collector/actions";
// Selectors
import { selectResources } from "components/components/UploadResource/selectors";
import {
  selectReadOnlyCollector,
  selectCollectorLayouts,
  selectDuplicatedCollectorLayout,
  selectCollectorValues,
  selectCompleteOrderId,
  selectApi,
  selectOrders,
  selectTransactionDocs,
  selectSelectedTransactionDocId,
  // selectCollectorEcosystemAutoSync,
} from "components/components/Collector/selectors";
import BroadCast from "core/Broadcast";
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
import { updateResources } from "components/components/UploadResource/actions";

const localStorageSegmentKey = "syn4pseCollectorLayoutSegment";
const useCollectorData = () => {
  //Selectors
  const dispatch = useDispatch();
  const readOnlyCollector = useSelector(selectReadOnlyCollector);
  const { templates } = useSelector(selectApi);
  const docId = useSelector(selectSelectedTransactionDocId);
  const transactionDocs = useSelector(selectTransactionDocs);
  const collectorLayout = useSelector(selectCollectorLayouts);
  const duplicatedCollectorLayout = useSelector(
    selectDuplicatedCollectorLayout
  );
  const collectorValues = useSelector(selectCollectorValues);
  const resources = useSelector(selectResources);
  const orderId = useSelector(selectCompleteOrderId);
  const orderState = useSelector(selectOrders);
  // const collectorEcosystemAutoSync = useSelector(
  //   selectCollectorEcosystemAutoSync
  // );
  const [segment, _setSegment] = useState({
    templateId: undefined,
    serviceId: undefined,
    serviceTaskId: undefined,
    reviewId: undefined,
    groupId: undefined,
    subgroupId: undefined,
    reviewName: undefined,
    groupName: undefined,
    subgroupName: undefined,
    reload: true,
    resetCollectorLayout: false,
  });
  const setSegment = (newProps) =>
    _setSegment((prev) => ({ ...prev, reload: true, ...newProps }));

  const order = useMemo(
    () =>
      CollectorUtils.getSelectedOrderFromReviewManage(
        orderId,
        orderState.control.orders
      ),
    [orderId, orderState.control.orders]
  );

  const otd = useMemo(
    () => transactionDocs.find((o) => o.id === docId),
    [docId, transactionDocs]
  );

  const templateId = useMemo(() => {
    if (!order) return;

    return (
      otd?.templateId ||
      templates.find(
        (t) =>
          t.template_type_id === 4 && t.department_id === order.department_id
      )?.id
    );
  }, [order, otd, templates]);

  const handleLoadFromDatabase = () =>
    Promise.all([
      // Load Collector Layout & Duplicated Collector Layout
      CollectorLayoutRepository.loadCollectorLayout({
        ...segment,
        orderId: order.order_id,
        docId,
      }).then(async (collectorLayout) => {
        // The following lines are necessary to avoid ghost panels in the DOM,
        // which even if the array of items is updated, the DOM is not updated
        // and panels that should not be shown continue to be shown.
        // For more information, you can see: https://app.asana.com/0/1129003424391092/1207462555081079/f
        if (segment.resetCollectorLayout) {
          dispatch(mutate1Object("reviewManage", { collectorLayout: [] }));
          setSegment({ reload: false, resetCollectorLayout: false });
          // await new Promise((resolve) => setTimeout(resolve, 2));
        }
        //////////////////////////////////////////////////////////////////
        dispatch(mutate1Object("reviewManage", { collectorLayout }));
      }),
      // Collector values
      CollectorValueRepository.loadSegment({
        ...segment,
        auditOrderId: order.order_id,
        docId,
      }).then((collectorValues) =>
        dispatch(setCollectorValuesV2(collectorValues))
      ),
      // Assets
      UploadResourceUtils.loadSegment({
        ...segment,
        orderId: order.order_id,
        docId,
      }).then((resources) => dispatch(updateResources(resources))),
    ]);

  const handleBroadcastSegment = useCallback(
    debounce((neighborhood) => {
      const segment = JSON.parse(
        localStorage.getItem(localStorageSegmentKey) || "{}"
      );

      (() => {
        if (!neighborhood) return;
        if (!CollectorUtils.isDuplicable(segment)) return;

        const serializedSegment = CollectorUtils.buildNeighborhood(segment, {});
        const key = Object.keys(serializedSegment)?.[0];
        if (!key) return;

        const values = neighborhood[key];
        if (!values) return;

        const { reviewId, groupId, subgroupId } = values;

        if (reviewId) segment.reviewId = reviewId;
        if (groupId) segment.groupId = groupId;
        if (subgroupId) segment.subgroupId = subgroupId;
      })();

      setSegment({ ...segment, reload: true });
    }, 150),
    []
  );

  useEffect(() => {
    localStorage.setItem(localStorageSegmentKey, JSON.stringify(segment));
  }, [segment]);

  useEffect(() => {
    if (!order || !templateId) return;

    setSegment({
      templateId,
      serviceId: order.service_id,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, templateId]);

  useEffect(() => {
    if (!order) return;
    if (!segment.reload) return;
    if (!segment.templateId || !segment.serviceId) return;

    setSegment({ reload: false });

    handleLoadFromDatabase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    order,
    docId,
    segment,
    // collectorEcosystemAutoSync.running,
    // collectorEcosystemAutoSync.resource,
  ]);

  useEffect(() => {
    const unsubscribe = new BroadCast().onReloadFullCollectorLayout((event) => {
      const { data: neighborhood } = event;
      handleBroadcastSegment(neighborhood);
    });

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    segment,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    collectorValues,
    resources,
    setAutoFillCollector,
    onLoadSegmentedResources: setSegment,
  };
};

export default useCollectorData;
