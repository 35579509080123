// Utils
import GENERAL from "utils/constants/general";
const { TEMPLATES } = GENERAL.ENV;

export default class TemplateUtils {
  static deleteDataStructureObjectFromCollectorLayoutTemplates(templates) {
    return templates.map((t) => {
      if (t.template_type_id !== TEMPLATES.TYPES.AUDIT_ORDER) return t;

      delete t.data_structure_object;
      return t;
    });
  }
}
