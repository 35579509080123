//Libs
import Immutable from "immutable";
import { createSelector } from "reselect";
//Utils
import CollectorUtils from "./CollectorUtils";

const selectCollector = ({ collector }) => collector;
const selectLogin = ({ login }) => login;
const selectUploadResource = (state) => state.uploadResource;

export const selectOrders = ({ orders }) => orders;
export const selectApi = ({ api }) => api;

export const selectReviewManage = createSelector(
  [selectCollector],
  ({ reviewManage }) => reviewManage
);

export const selectCompleteOrderId = createSelector(
  [selectReviewManage],
  ({ orderId }) => orderId
);
export const selectIsControlPanelOpen = createSelector(
  [selectReviewManage],
  ({ isControlPanelOpen }) => isControlPanelOpen
);
export const selectWorkflow = createSelector(
  [selectReviewManage],
  ({ workflow }) => workflow
);
export const selectTransactionDoc = createSelector(
  [selectReviewManage],
  ({ transactionDocs }) => ({
    ...transactionDocs,
    data: Immutable.List(transactionDocs.data).toJS(),
  })
);
export const selectSelectedTransactionDocId = createSelector(
  [selectTransactionDoc],
  ({ docId }) => docId
);
export const selectTransactionDocs = createSelector(
  [selectTransactionDoc],
  ({ data }) => data
);
export const selectCollectorValues = createSelector(
  [selectReviewManage],
  ({ collectorValues }) => Immutable.List(collectorValues).toJS()
);
export const selectCollectorLayout = createSelector(
  [selectReviewManage],
  ({ collectorLayout }) => Immutable.List(collectorLayout).toJS()
);
export const selectDuplicatedCollectorLayout = createSelector(
  [selectReviewManage],
  ({ duplicatedCollectorLayout }) =>
    Immutable.List(duplicatedCollectorLayout).toJS()
);
export const selectPendingCollectorCountFromOrder = createSelector(
  [selectCompleteOrderId, selectCollectorValues],
  (orderId, collectorValues) =>
    CollectorUtils.getUnsuccessCollectorCountFromOrder(orderId, collectorValues)
);
export const selectReadOnlyCollector = createSelector(
  [
    selectReviewManage,
    selectSelectedTransactionDocId,
    selectTransactionDocs,
    selectLogin,
  ],
  ({ forceReadOnlyCollector }, docId, transactionDocs, login) =>
    forceReadOnlyCollector ||
    CollectorUtils.checkReadOnlyCollector(docId, transactionDocs, login.profile)
);
export const selectAvailableReports = createSelector(
  [selectSelectedTransactionDocId, selectTransactionDocs],
  (docId, transactionDocs) =>
    CollectorUtils.getAvailableReports(docId, transactionDocs)
);
export const selectGeneratedReports = createSelector(
  [selectTransactionDoc],
  ({ generatedReports }) => generatedReports
);
export const selectCollectorEcosystemAutoSync = createSelector(
  [selectReviewManage, selectUploadResource],
  ({ isFetching, sendingCollectorValues, autoSync }, uploadResource) => ({
    active: autoSync.active,
    duplicatedLayoutIdsActive: autoSync.duplicatedLayoutIdsActive,
    resource: uploadResource.autoSync.active,
    running: autoSync.isFetching || isFetching || sendingCollectorValues,
  })
);
export const selectSearchInData = createSelector(
  [selectReviewManage],
  ({ searchInData }) => searchInData
);
export const selectCollectorLayouts = createSelector(
  [
    selectOrders,
    selectSelectedTransactionDocId,
    selectCollectorLayout,
    selectDuplicatedCollectorLayout,
    selectCompleteOrderId,
  ],
  ({ control }, docId, collectorLayout, duplicatedCollectorLayout, orderId) => {
    // const _templates = Immutable.List(templates).toJS();
    //Get Selected Order
    const order = CollectorUtils.getSelectedOrderFromReviewManage(
      orderId,
      control.orders
    );
    if (!order) return [];

    // const totalCollectorLayout = CollectorUtils.addDuplicatedCollectorLayout(
    //   order.order_id,
    //   docId,
    //   collectorLayout,
    //   duplicatedCollectorLayout
    // );

    return collectorLayout;
  }
);
