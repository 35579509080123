//Libs
import React from "react";
import { connect } from "react-redux";
//Hooks
import { useNetwork } from "hooks";
//Actions
import { autoSyncCollectorValues, setAutoSync } from "./actions";
//Selectors
import {
  selectCollectorEcosystemAutoSync,
  selectReviewManage,
  selectSelectedTransactionDocId,
} from "./selectors";
//Utils
import CollectorUtils from "./CollectorUtils";
//Components
import { Progress } from "components/components";
import { ApiServiceV2 } from "services";
import {
  CollectorLayoutRepository,
  CollectorValueRepository,
} from "core/database";
import { selectAutoSyncOfflineData } from "components/containers/Orders/selectors";

const AutoSyncCollectors = ({
  //Redux
  autoSyncOfflineData,
  collectorEcosystemAutoSync,
  reviewManage,
  setAutoSync,
  autoSyncCollectorValues,
  docId,
}) => {
  //Use Network to know isOnline
  const isOnline = useNetwork();
  //Validating isOnline
  React.useEffect(() => {
    if (!isOnline && collectorEcosystemAutoSync.active) {
      setTimeout(() => setAutoSync({ active: false }), 500);
    } else if (isOnline) {
      // setAutoSync({ duplicatedLayoutIdsActive: true });
      if (!collectorEcosystemAutoSync.active)
        setTimeout(() => setAutoSync({ active: true }), 500);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  //Start collectorEcosystemAutoSync if Active
  React.useEffect(() => {
    if (!collectorEcosystemAutoSync.active) return;

    CollectorValueRepository.loadUnsuccess().then(
      (unsuccessCollectorValues) => {
        if (!autoSyncOfflineData.isOpen)
          unsuccessCollectorValues = unsuccessCollectorValues.filter(
            (cv) => cv.status !== "error"
          );

        if (!unsuccessCollectorValues.length) {
          setTimeout(() => setAutoSync({ active: false }), 500);
        } else {
          setTimeout(
            () => autoSyncCollectorValues(unsuccessCollectorValues),
            500
          );
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectorEcosystemAutoSync.active]);

  //Delete success collector values
  React.useEffect(() => {
    if (reviewManage.orderId) return;

    CollectorValueRepository.removeSuccess();
  }, [reviewManage.orderId]);

  //Update duplicated collector layout ids
  React.useEffect(() => {
    if (!reviewManage.orderId && !docId) return;
    if (!collectorEcosystemAutoSync.duplicatedLayoutIdsActive) return;

    setAutoSync({ duplicatedLayoutIdsActive: false });

    CollectorLayoutRepository.unsuccessDuplicatedCollectorLayout({
      orderId: reviewManage.orderId,
      docId,
    })
      .then(
        (duplicatedCollectorLayouts) =>
          !!duplicatedCollectorLayouts.length &&
          ApiServiceV2.post({
            url: "/collectorManager/v1/syncDuplicatedCollectorLayoutIds",
            data: CollectorUtils.formatToUpdateDuplicatedCollectorLayoutIds(
              duplicatedCollectorLayouts
            ),
          })
      )
      .then(
        (duplicatedCollectorLayoutIds) =>
          !!duplicatedCollectorLayoutIds.length &&
          CollectorUtils.mergeDuplicatedCollectorLayouts({
            docId,
            orderId: reviewManage.orderId,
            collectorLayouts: duplicatedCollectorLayoutIds,
          })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    collectorEcosystemAutoSync.duplicatedLayoutIdsActive,
    reviewManage.orderId,
    docId,
  ]);

  return (
    CollectorUtils.checkAutoSyncActive(
      collectorEcosystemAutoSync.active,
      reviewManage.sendingCollectorValues
    ) && (
      <Progress
        style={{ top: "-9px", opacity: 0.3 }}
        percent={100}
        status="active"
        showInfo={false}
        size="small"
      />
    )
  );
};

const mapStateToProps = (state) => ({
  docId: selectSelectedTransactionDocId(state),
  reviewManage: selectReviewManage(state),
  autoSyncOfflineData: selectAutoSyncOfflineData(state),
  collectorEcosystemAutoSync: selectCollectorEcosystemAutoSync(state),
});

const actions = {
  setAutoSync,
  autoSyncCollectorValues,
};

export default connect(mapStateToProps, actions)(AutoSyncCollectors);
