//Libs
import React from "react";
import { connect } from "react-redux";
//Hooks
import { useNetwork } from "hooks";
//Actions
import { autoSyncResource, updateAutoSync } from "./actions";
//Selectors
import { selectUploadResourceAutoSync, selectResources } from "./selectors";
//Components
import { Wrapper, Title } from "components/components";
import {
  DisconnectedIllustration,
  ConfirmedIllustration,
} from "components/components/Illustrations";
import { UploadResource } from ".";
import { AssetRepository } from "core/database";

const HeaderTitle = ({ title }) => (
  <Title ellipsis maxWidth="300px">
    {title}
  </Title>
);

const AutoSyncUploadResources = ({
  uploadResourceAutoSync,
  resources,
  updateAutoSync,
  autoSyncResource,
}) => {
  //Use Network to know isOnline
  const isOnline = useNetwork();

  const [processingResource, setProcessingResource] = React.useState(undefined);
  const [pendingResourceCount, setPendingResourceCount] = React.useState(0);

  //Validating isOnline
  React.useEffect(() => {
    if (!isOnline && uploadResourceAutoSync.active) {
      setTimeout(() => updateAutoSync({ active: false }), 500);
    } else if (isOnline && !uploadResourceAutoSync.active) {
      setTimeout(() => updateAutoSync({ active: true }), 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  //Start uploadResourceAutoSync if Active
  React.useEffect(() => {
    let isMounted = true;
    if (!uploadResourceAutoSync.active) return;

    AssetRepository.loadUnsuccess().then(([pendingResource] = []) => {
      if (!pendingResource) {
        if (isMounted) setProcessingResource(undefined);
        setTimeout(() => updateAutoSync({ active: false }), 500);
      } else {
        if (isMounted) setProcessingResource(pendingResource);
        setTimeout(() => autoSyncResource(pendingResource), 500);
      }
    });
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResourceAutoSync.active]);

  React.useEffect(() => {
    let isMounted = true;

    AssetRepository.unsuccessCount().then(
      (count) => isMounted && setPendingResourceCount(count)
    );

    return () => {
      isMounted = false;
    };
  }, [processingResource]);

  //Delete success resources
  React.useEffect(() => {
    AssetRepository.removeSuccess();
  }, []);

  //Set Title
  const title = React.useMemo(() => {
    if (!isOnline) return "Esperando conexión...";
    if (pendingResourceCount > 0)
      return `Sincronizando... ${
        pendingResourceCount === 1 ? "Falta" : "Faltan"
      } ${pendingResourceCount} ${
        pendingResourceCount === 1 ? "foto" : "fotos"
      }`;
    return "Fotos sincronizadas";
  }, [isOnline, pendingResourceCount]);

  return (
    <Wrapper
      padding="110px 0 0 0"
      width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {!isOnline ? (
        <Wrapper width="100%" justifyContent="center">
          <DisconnectedIllustration />
        </Wrapper>
      ) : !processingResource ? (
        <Wrapper width="100%" justifyContent="center">
          <ConfirmedIllustration />
        </Wrapper>
      ) : (
        <Wrapper padding="0" flexDirection="column">
          <UploadResource
            width="200px"
            height="200px"
            fileProps={processingResource}
            options={{
              showActions: false,
              upload: false,
              erase: false,
              hideLabel: true,
            }}
            resources={resources}
          />
        </Wrapper>
      )}
      <HeaderTitle title={title} />
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  uploadResourceAutoSync: selectUploadResourceAutoSync(state),
  resources: selectResources(state),
});
const actions = {
  updateAutoSync,
  autoSyncResource,
};

export default connect(mapStateToProps, actions)(AutoSyncUploadResources);
