// Libs
import React, { createContext, useEffect, useState, useMemo } from "react";
// Coord Manager Actions
import { getGeolocation, getGeocode, getDate, sendCoords } from "../actions";

const COORD_MANAGER_LSKEY = "synapse_coord_manager";

const CoordContext = createContext({});
const { Provider } = CoordContext;
const isGranted = (permissionState) => permissionState === "granted";

const _getPersistedState = () => {
  const persistedState = localStorage.getItem(COORD_MANAGER_LSKEY);
  if (!persistedState) return {};
  return JSON.parse(persistedState);
};

const _setPersistedState = async ({ coords, enableHighAccuracy }) => {
  try {
    const persistedState = _getPersistedState();
    const newState = {
      ...persistedState,
      coords,
      enableHighAccuracy,
    };
    localStorage.setItem(COORD_MANAGER_LSKEY, JSON.stringify(newState));
  } catch (err) {
    console.error(err);
  }
};

const CoordProvider = ({
  children,
  connectedLink,
  timeout = 30000, //ms Timeout getting coords
  syncDelay = 30000, //ms: Time delay to re-get coords
  onDisconnectedLink,
  onSyncCoords,
  errorHandler,
}) => {
  const [state, _setState] = useState(() => ({
    stopAutoSync: !connectedLink,
    coords: undefined,
    coordSync: false,
    manuallyDisabledGPS: false,
    enableHighAccuracy: true,
    maximumAge: undefined,
  }));
  const setState = (newState, persist = true) => {
    _setState((prev) => {
      const combinedState = { ...prev, ...newState };
      if (persist) _setPersistedState(combinedState);
      return combinedState;
    });
  };

  useEffect(() => {
    setState({ stopAutoSync: !connectedLink }, false);
  }, [connectedLink]);

  //Load persisted state
  useEffect(() => {
    const persistedState = _getPersistedState();
    setState(persistedState, false);
    console.log("Persisted CoordManager state: Loaded!");
  }, []);

  //Check user permission
  useEffect(() => {
    let isMounted = true;
    navigator.permissions
      .query({ name: "geolocation" })
      .then(
        (result) =>
          isMounted &&
          setState({ manuallyDisabledGPS: !isGranted(result.state) }, false)
      )
      .catch(errorHandler);

    return () => {
      isMounted = false;
    };
  }, []);

  // CoordSync
  useEffect(() => {
    let isMounted = true;
    if (!state.coordSync) return;

    getGeolocation({
      enableHighAccuracy: state.enableHighAccuracy,
      maximumAge: state.maximumAge,
      timeout,
    })
      .then(async ({ coords }) => {
        if (!isMounted) return;
        const newState = { manuallyDisabledGPS: false, coordSync: false };
        if (coords?.latitude && coords.longitude) {
          const [now, geocode] = await Promise.all([
            getDate(),
            getGeocode(coords),
          ]);
          newState.coords = {
            ...coords,
            geocode,
            createdAt: now.UTC,
            createdAtSource: now.SOURCE,
          };
        }
        setState(newState);
        if (newState.coords) {
          await sendCoords(newState.coords);
          if (onSyncCoords) onSyncCoords(newState.coords);
        }
      })
      .catch(
        () =>
          isMounted &&
          setState({ manuallyDisabledGPS: true, coordSync: false }, false)
      );

    return () => {
      isMounted = false;
    };
  }, [state.coordSync]);

  // Auto toggle CoordSync active
  const delay = () => new Promise((resolve) => setTimeout(resolve, syncDelay));
  useEffect(() => {
    let isMounted = true;

    const autoCoordSync = async () => {
      if (!isMounted) return;
      if (state.stopAutoSync) {
        onDisconnectedLink();
        return;
      }
      if (!state.coordSync) setState({ coordSync: true });
      await delay();
      await autoCoordSync();
    };
    autoCoordSync();
    return () => {
      isMounted = false;
    };
  }, [state.stopAutoSync, syncDelay]);

  const value = useMemo(
    () => ({
      coords: state.coords,
      coordSync: state.coordSync,
      manuallyDisabledGPS: state.manuallyDisabledGPS,
      setState,
    }),
    [state.coords, state.coordSync, state.manuallyDisabledGPS]
  );

  return <Provider value={value}>{children}</Provider>;
};

export { CoordProvider, CoordContext };
