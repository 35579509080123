export default Object.freeze({
  STATUS: {
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    ERROR: "ERROR",
    UNAUTHORIZED: "UNAUTHORIZED",
  },
  ACTIONS: {
    GET_PROJECTS: "CORE_GET_PROJECTS",
    GET_SERVICES: "CORE_GET_SERVICES",
    GET_DATA_FROM_PROCESSED_SQL: "CORE_GET_DATA_FROM_PROCESSED_SQL",
    GET_EXPERTS: "CORE_GET_EXPERTS",
    MUTATE_DIRECT_PROPS: "CORE_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "CORE_MUTATE_1OBJECT",
    TOGGLE_DATASOURCE: "CORE_TOGGLE_DATASOURCE",
    MUTATE_SUB_DATA_FILTER: "CORE_MUTATE_SUB_DATA_FILTER",
    MUTATE_DATA_FILTER_USER_PARAMS: "CORE_MUTATE_DATA_FILTER_USER_PARAMS",
    SET_DATASOURCE: "CORE_SET_DATASOURCE",
    SET_SEARCH_DATASOURCE: "CORE_SET_SEARCH_DATASOURCE",
    RESET_DATASOURCE: "CORE_RESET_DATASOURCE",
    RESET_DISPLAY: "CORE_RESET_DISPLAY",
    RESET_ACTION_FORM: "CORE_RESET_ACTION_FORM",
    RESET_DATA_FILTERS: "CORE_RESET_DATA_FILTERS",
    RESET_DATA_FILTER_USER_PARAMS: "CORE_RESET_DATA_FILTER_USER_PARAMS",
    UPDATE_DATASOURCE_ITEM: "CORE_UPDATE_DATASOURCE_ITEM",
    RESET_CUSTOM_FILTER_PROPS_IN_DATA_FILTER:
      "CORE_RESET_CUSTOM_FILTER_PROPS_IN_DATA_FILTER",
    RESET: "CORE_RESET",
  },
  ROUTES: {
    FEED: {
      ID: "feed",
      ITEM: "Synapse Feed",
      TITLE: "Synapse Feed",
      PATH: "/feed",
    },
    FEED_MODULE: {
      ID: "feed.module",
      PATH: "/feed/*",
    },
    FEED_MODULE_ELEMENT: {
      ID: "feed.module.element",
      PATH: "/feed/*/:elementId",
    },
  },
  BROADCAST_CHANNELS: {
    RELOAD_FULL_COLLECTOR_LAYOUT: "reloadFullCollectorLayout",
  },
});
