export default Object.freeze({
  NOTIFICATIONS: {
    TYPES: {
      GLOBAL: "GLOBAL",
      COPY_TO_CLIPBOARD: "COPY_TO_CLIPBOARD",
    },
  },
  DIVISION_ID: {
    ENTITY: 1,
    ADM: 2,
    EXP: 3,
  },
  ENV: {
    DEVICE_TYPE:
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
        ? "Mobile"
        : "Desktop",
    ROUTES: {
      IDS: {
        ORDERS: "ors",
        ORDERS_MANAGE: "ors_mng",
        // ORDERS_PHOTO_REPORT: "ors_ph_rpt",
        // ORDERS_BILLING_REPORT: "ors_blg_rpt",
        ORDERS_COMPLETE: "ors_cmplt",
        PAYMENTS: "pys",
        PAYMENTS_MANAGE: "pys_mng",
        WAREHOUSE: "wrs",
        WAREHOUSE_STOCK: "wrs_stk",
        WAREHOUSE_TRANSACTIONS: "wrs_trs",
        WAREHOUSE_TRANSACTIONS_MANAGE: "wrs_trs_mng",
        WAREHOUSE_TRANSACTIONS_INVENTORY: "wrs_trs_inty_mble",
        WAREHOUSE_TRANSACTIONS_ITEMS: "wrs_trs_itms_mble",
      },
      ITEMS: {
        ORDERS: "Ordenes",
        PAYMENTS: "Pagos",
        WAREHOUSE: "Almacenes",
        WAREHOUSE_STOCK: "Inventario",
        WAREHOUSE_TRANSACTIONS: "Transacciones",
      },
      TITLES: {
        DASHBOARD: "Syn4pse EXP",
        ORDERS_MANAGE: "Ordenes",
        // ORDERS_PHOTO_REPORT: "Rep. Fotográfico",
        // ORDERS_BILLING_REPORT: "Materiales",
        ORDERS_COMPLETE: "Completar",
        PAYMENTS_MANAGE: "Pagos",
        WAREHOUSE_STOCK: "Inventario",
        WAREHOUSE_TRANSACTIONS_MANAGE: "Transacciones",
        WAREHOUSE_TRANSACTIONS_INVENTORY: "Enviando",
        WAREHOUSE_TRANSACTIONS_ITEMS: "Resumen",
      },
      PATHS: {
        ROOT: "/",
        LOGIN: "/login",
        SIGNUP: "/signup",
        RECOVERY_PASSWORD: "/recovery-password",
        DASHBOARD: "/dashboard",
        ORDERS: "/dashboard/orders",
        ORDERS_MANAGE: "/dashboard/orders/manage",
        // ORDERS_PHOTO_REPORT: "/dashboard/orders/photo-report",
        // ORDERS_BILLING_REPORT: "/dashboard/orders/billing-report",
        ORDERS_COMPLETE: "/dashboard/orders/complete",
        PAYMENTS: "/dashboard/payments",
        PAYMENTS_MANAGE: "/dashboard/payments/manage",
        WAREHOUSE: "/dashboard/warehouse",
        WAREHOUSE_STOCK: "/dashboard/warehouse/stock",
        WAREHOUSE_TRANSACTIONS: "/dashboard/warehouse/transactions",
        WAREHOUSE_TRANSACTIONS_MANAGE:
          "/dashboard/warehouse/transactions/manage",
        WAREHOUSE_TRANSACTIONS_INVENTORY:
          "/dashboard/warehouse/transactions/inventory",
        WAREHOUSE_TRANSACTIONS_ITEMS: "/dashboard/warehouse/transactions/items",
      },
    },
    MANAGE_PROPS: {
      PROPS: {
        COLLECTOR_AUTO_FILL: {
          NAME: "autoFillCollector",
        },
      },
      FLAGS: {
        FREEZE_TIMER: "freezeTimer",
        BILLED: "billed",
      },
    },
    //TEMPLATES
    TEMPLATES: {
      TYPES: {
        AUDIT_ORDER: 4,
      },
      DATA_STRUCTURE_OBJECT: {
        FILTER_TO_UNINSTALL_INVENTORY_ITEMS: "filterToUninstallInventoryItems",
      },
    },
    //UPLOAD_RESOURCE
    UPLOAD_RESOURCE: {
      RESOURCE_TYPES: {
        COLLECTOR_PHOTO: "collectorPhoto",
      },
      STATUS: {
        LOADING: "loading",
        ERROR: "error",
        SUCCESS: "success",
      },
      COLLECTOR_PHOTO: {
        COMPRESS: {
          STRATEGIES: {
            PHOTON: "photon",
            PICA: "pica",
            RESAMPLE: "resample",
            BACKEND: "backend",
          },
        },
      },
    },
    DEPARTMENTS: {
      PROPS: {
        EXP: {
          NAME: "exp",
          ACTIONS: {
            ORDERS: {
              BILL_ORDER: { NAME: "billOrder" },
              AUDIT_ORDER: { NAME: "auditOrder" },
            },
          },
        },
        COLLECTOR_PHOTO: {
          NAME: "collectorPhoto",
          KEYS: {
            VERSION: "version",
            REQUIRED: "required",
            GET_COORDS: "getCoords",
            CAPTURE: "capture",
            ACCEPT: "accept",
            COMPRESS: {
              NAME: "compress",
              FORMAT: "format",
              WIDTH: "width",
              HEIGHT: "height",
              QUALITY: "quality",
              STRATEGY: "strategy",
              SAMPLING_FILTER: "samplingFilter",
            },
          },
        },
        EDITABLE_INVENTORY: {
          NAME: "editableInventory",
          INTERVAL: "interval",
          UNIT: "unit",
        },
      },
      ICON: {
        TYPES: {
          INITIAL: "initial",
          IMAGE: "image",
        },
      },
    },
    //SERVICES
    SERVICES: {
      ICON: {
        TYPES: {
          INITIAL: "initial",
          IMAGE: "image",
        },
      },
    },
    ORDER_EVENTS: {
      PROPS: {
        AUDITED_ORDER: "auditedOrder",
      },
    },
    INVENTORY: {
      SERIALIZED: {
        GET: {
          ITEMS: "items",
          PEPS: "peps",
        },
      },
    },
  },
  APIDATA: {
    //GENERAL
    MUTATE_DIRECT_PROPS: "APIDATA_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "APIDATA_MUTATE_1OBJECT",
    //USERS
    GET_ORGANIZATION_USERS: "APIDATA_GET_ORGANIZATION_USERS",
    //ORDERS
    GET_ORDER_STATES: "APIDATA_GET_ORDER_STATES",
    GET_ORDER_EVENTS: "APIDATA_GET_ORDER_EVENTS",
    GET_COMPLETED_CODES: "APIDATA_GET_COMPLETED_CODES",
    //PAYMENTS
    GET_PAYMENTS: "APIDATA_GET_PAYMENTS",
    GET_PAYMENT_RESUME: "APIDATA_GET_PAYMENT_RESUME",
    GET_GAINS: "APIDATA_GET_GAINS",
    GET_COINS: "APIDATA_GET_COINS",
    //TEMPLATES
    GET_MY_TEMPLATES: "APIDATA_GET_MY_TEMPLATES",
    //WAREHOUSE
    GET_INVENTORY_TO_INVOICE: "APIDATA_GET_INVENTORY_TO_INVOICE",
    GET_INVENTORY_TO_UNINSTALL: "APIDATA_GET_INVENTORY_TO_UNINSTALL",
    GET_INCOME_METHODS: "APIDATA_GET_INCOME_METHODS",
    GET_WAREHOUSES: "APIDATA_GET_WAREHOUSES",
    GET_SERIE_HISTORY: "APIDATA_GET_SERIE_HISTORY",
    //CITIES
    GET_CITIES: "APIDATA_GET_CITIES",
    //TOWNSHIPS
    GET_TOWNSHIPS: "APIDATA_GET_TOWNSHIPS",
    //ZONES
    GET_ZONES: "APIDATA_GET_ZONES",
    //DEPARTMENTS
    GET_DEPARTMENTS: "APIDATA_GET_DEPARTMENTS",
    //CONTRACT TYPES
    GET_CONTRACT_TYPES: "APIDATA_GET_CONTRACT_TYPES",
    //RESET MODULE DATA
    RESET_MODULE_DATA: "APIDATA_RESET_MODULE_DATA",
  },
  SIGNUP: {
    MUTATE_DIRECT_PROPS: "SIGNUP_MUTATE_DIRECT_PROPS",
    SIGNUP: "SIGNUP_LOGIN",
    GET_SIGNUP_DATA: "SIGNUP_GET_SIGNUP_DATA",
    TOAST_MESSAGE: "SIGNUP_TOAST_MESSAGE",
    RESET_REGISTRY: "SIGNUP_RESET_REGISTRY",
    RESET: "SIGNUP_RESET",
  },
  LOGIN: {
    CHANGE_INPUT: "LOGIN_CHANGE_INPUT",
    SHOW_PASSWORD: "LOGIN_SHOW_PASSWORD",
    LOGIN: "LOGIN_LOGIN",
    LOGGED: "LOGIN_LOGGED",
    LOGOUT: "LOGIN_LOGOUT",
    MUTATE_DIRECT_PROPS: "LOGIN_MUTATE_DIRECT_PROPS",
    SHOW_NEW_PASSWORD_INPUT: "LOGIN_SHOW_NEW_PASSWORD_INPUT",
    SET_MY_PROFILE: "LOGIN_SET_MY_PROFILE",
    GET_MY_PROFILE: "LOGIN_GET_MY_PROFILE",
    RESET: "LOGIN_RESET",
  },
  DASHBOARD: {
    SHOW_NAV_BAR: "DASHBOARD_SHOW_NAV_BAR",
    TOAST_MESSAGE: "DASHBOARD_SHOW_TOAST_MESSAGE",
    SET_LINK: "DASHBOARD_SET_LINK",
    SET_LINK_SUCCESS: "DASHBOARD_SET_LINK_SUCCESS",
    REPORT_ME: "DASHBOARD_REPORT_ME",
    MUTATE_DIRECT_PROPS: "DASHBOARD_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "DASHBOARD_MUTATE_1OBJECT",
    GET_NOTIFICATIONS: "DASHBOARD_GET_NOTIFICATIONS",
    MARK_NOTIFICATION: "DASHBOARD_MARK_NOTIFICATION",
    MARK_NOTIFICATION_SUCCESS: "DASHBOARD_MARK_NOTIFICATION_SUCCESS",
    MARK_NOTIFICATION_SEEN: "DASHBOARD_MARK_NOTIFICATION_SEEN",
    MARK_NOTIFICATION_SEEN_SUCCESS: "DASHBOARD_MARK_NOTIFICATION_SEEN_SUCCESS",
    SHOW_MULTIPLE_ERRORS: "DASHBOARD_SHOW_MULTIPLE_ERRORS",
    RESET: "DASHBOARD_RESET",
    //New unified structure
    MUTATE_1OBJECT_IN_REGIONS: "DASHBOARD_MUTATE_1OBJECT_IN_REGIONS",
    MUTATE_1OBJECT_IN_AREAS: "DASHBOARD_MUTATE_1OBJECT_IN_AREAS",
    MUTATE_1OBJECT_IN_CENTRALS: "DASHBOARD_MUTATE_1OBJECT_IN_CENTRALS",
    MUTATE_1OBJECT_IN_DEALERS: "DASHBOARD_MUTATE_1OBJECT_IN_DEALERS",
    MUTATE_1OBJECT_IN_ORDERS: "DASHBOARD_MUTATE_1OBJECT_IN_ORDERS",
    MUTATE_1OBJECT_IN_ORDER_FILTERS:
      "DASHBOARD_MUTATE_1OBJECT_IN_ORDER_FILTERS",
    MUTATE_1OBJECT_IN_PROJECTS: "DASHBOARD_MUTATE_1OBJECT_IN_PROJECTS",
    MUTATE_1OBJECT_IN_SERVICES: "DASHBOARD_MUTATE_1OBJECT_IN_SERVICES",
    MUTATE_1OBJECT_IN_CHARTS: "DASHBOARD_MUTATE_1OBJECT_IN_CHARTS",
    MUTATE_1OBJECT_IN_EXPERTS: "DASHBOARD_MUTATE_1OBJECT_IN_EXPERTS",
    SET_ON_RECEIVED_NOTIFICATION: "DASHBOARD_SET_ON_RECEIVED_NOTIFICATION",
    GET_REGIONS: "DASHBOARD_GET_REGIONS",
    GET_AREAS: "DASHBOARD_GET_AREAS",
    GET_CENTRALS: "DASHBOARD_GET_CENTRALS",
    GET_DEALERS: "DASHBOARD_GET_DEALERS",
    GET_ORDERS: "DASHBOARD_GET_ORDERS",
    GET_PROJECTS: "DASHBOARD_GET_PROJECTS",
    GET_SERVICES: "DASHBOARD_GET_SERVICES",
    RESET_FILTERS_QUERY_MODAL: "DASHBOARD_RESET_FILTERS_QUERY_MODAL",
  },
  ORDERS: {
    MUTATE_DIRECT_PROPS: "ORDERS_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "ORDERS_MUTATE_1OBJECT",
    GET_ORDERS: "ORDERS_GET_ORDERS",
    MARK_ORDER: "ORDERS_MARK_ORDER_SEEN",
    SEND_EVENT: "ORDERS_SEND_EVENT",
    RESET_EVENT_MODAL: "ORDERS_RESET_EVENT_MODAL",
    RESET_ORDER_INFORMATION: "ORDERS_RESET_ORDER_INFORMATION",
    GET_ORDER_HISTORY: "ORDERS_GET_ORDER_HISTORY",
    GET_BILLING_MATERIALS: "ORDERS_GET_BILLING_MATERIALS",
    GET_PHOTO_REPORT: "ORDERS_GET_PHOTO_REPORT",
    GET_ORDER: "ORDERS_GET_ORDER",
    MAKE_DEEP_SEARCH: "ORDERS_MAKE_DEEP_SEARCH",
    MAKE_INVOICE_PERSIST_IN_INDEXEDDB:
      "ORDERS_MAKE_INVOICE_PERSIST_IN_INDEXEDDB",
    CREATE_NEW_INVOICE: "ORDERS_CREATE_NEW_INVOICE",
    SEND_ONE_PHOTO_TO_BACKEND: "ORDERS_SEND_ONE_PHOTO_TO_BACKEND",
    SEND_ONE_PHOTO_TO_BACKEND_STATE: "ORDERS_SEND_ONE_PHOTO_TO_BACKEND_STATE",
    SEND_INVOICE: "ORDERS_SEND_INVOICE",
    AUTO_SYNC_PHOTO_REPORTS: "ORDERS_AUTO_SYNC_PHOTO_REPORTS",
    IMPORT_PHOTO_REPORT: "ORDERS_IMPORT_PHOTO_REPORT",
    SEND_COMMENT_REPORT: "ORDERS_SEND_COMMENT_REPORT",
    SEND_ROUTING_ORDERS: "ORDERS_SEND_ROUTING_ORDERS",
    COMPLETE_ORDER: "ORDERS_COMPLETE_ORDER",
    RESET_INVOICE: "ORDERS_RESET_INVOICE",
    RESET_COMPLETE_ORDER_CONTROL: "ORDERS_RESET_COMPLETE_ORDER_CONTROL",
    RESET: "ORDERS_RESET",
  },
  PAYMENTS: {
    MUTATE_1OBJECT: "PAYMENTS_MUTATE_1OBJECT",
    SIGN_PAYMENT: "PAYMENTS_SIGN_PAYMENT",
    RESET: "PAYMENTS_RESET",
  },
  WAREHOUSE: {
    MUTATE_DIRECT_PROPS: "WAREHOUSE_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "WAREHOUSE_MUTATE_1OBJECT",
    MUTATE_STOCK_SERIES_MANAGE: "WAREHOUSE_MUTATE_STOCK_SERIES_MANAGE",
    GET_STOCK_ARTICLES: "WAREHOUSE_GET_STOCK_ARTICLES",
    GET_STOCK_SERIES: "WAREHOUSE_GET_STOCK_SERIES",
    GET_MY_FAMILIES: "WAREHOUSE_GET_MY_FAMILIES",
    GET_TRANSACTION_DOCS: "WAREHOUSE_GET_TRANSACTION_DOCS",
    RESET_CREATE_TRANSACTION: "WAREHOUSE_RESET_CREATE_TRANSACTION",
    GET_ITEMS_TRANSACTION_DOCS: "WAREHOUSE_GET_ITEMS_TRANSACTION_DOCS",
    GET_INVENTORY_TO_TRANSFER: "WAREHOUSE_GET_INVENTORY_TO_TRANSFER",
    SEND_TRANSFER_TRANSACTION: "WAREHOUSE_SEND_TRANSFER_TRANSACTION",
    GET_SEARCHING_SERIES: "WAREHOUSE_GET_SEARCHING_SERIES",
    DELETE_TRANSACTION: "WAREHOUSE_DELETE_TRANSACTION",
    SIGN_WTD: "WAREHOUSE_SIGN_WTD",
    RESET: "WAREHOUSE_RESET",
  },
  UPLOAD_RESOURCE: {
    UPLOAD: "UPLOAD_RESOURCE_UPLOAD",
    UPDATE_RESOURCES: "UPLOAD_RESOURCE_UPDATE_RESOURCES",
    UPDATE_AUTO_SYNC: "UPLOAD_RESOURCE_UPDATE_AUTO_SYNC",
    AUTO_SYNC: "UPLOAD_RESOURCE_AUTO_SYNC",
  },
  MODULES: {
    MUTATE_DIRECT_PROPS: "MODULES_MUTATE_DIRECT_PROPS",
    MUTATE_1OBJECT: "MODULES_MUTATE_1OBJECT",
    MUTATE_MARKETPLACE: "MODULES_MUTATE_MARKETPLACE",
    GET_MY_ACTIVE_MODULES: "MODULES_GET_MY_ACTIVE_MODULES",
    GET_MARKET_BANNERS: "MODULES_GET_MARKET_BANNERS",
    GET_MARKET_MODULES: "MODULES_GET_MARKET_MODULES",
    GET_MARKET_ITEMS: "MODULES_GET_MARKET_ITEMS",
    UPDATE_MARKETITEM_INSTALLATION_STATUS:
      "MODULES_UPDATE_MARKETITEM_INSTALLATION_STATUS",
    GET_DYNAMIC_MY_ACTIVE_MODULE_ITEMS:
      "MODULES_GET_DYNAMIC_MY_ACTIVE_MODULE_ITEMS",
  },
});
