import KEYWORDS from "core/keywords";
const { BROADCAST_CHANNELS: BC } = KEYWORDS;

class BroadCast {
  onReloadFullCollectorLayout(callback) {
    const bc = new BroadcastChannel(BC.RELOAD_FULL_COLLECTOR_LAYOUT);
    bc.onmessage = callback;

    return () => bc.close();
  }
  postReloadFullCollectorLayout(neighborhood) {
    const bc = new BroadcastChannel(BC.RELOAD_FULL_COLLECTOR_LAYOUT);
    bc.postMessage(neighborhood);
    bc.close();
  }
}

export default BroadCast;
