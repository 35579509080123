//Libs
import React from "react";
//Components
import ToolbarNotifications from "../Notifications";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { Toolbar } from "react-onsenui";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { Popover, Select, Icon } from "antd";
import { ToolbarTitle } from "components/components";
import { HamburguerComponent } from "../";
//Styles
import "../index.css";

const { Option } = Select;

export function Pays({
  title,
  showNavBar,
  searchInData,
  mutate1ObjectInPayments,
  selectNotification,
}) {
  return (
    <Toolbar className="toolbar">
      {/* LEFT */}
      <div className="left toolbar__left toolbar--material__left hamburger-button-container">
        {/* Hamburger */}
        <HamburguerComponent handleOnClick={showNavBar} />
      </div>

      {/* CENTER */}
      <div className="center toolbar__center toolbar__title toolbar--material__center">
        {/* Title */}
        <ToolbarTitle>{title}</ToolbarTitle>
      </div>

      {/* RIGHT */}
      <div className="right toolbar__right toolbar--material__right">
        {/* Search in Data */}
        <IconButton
          onClick={() =>
            mutate1ObjectInPayments("paymentsManage", {
              searchInData: { ...searchInData, renderToolbar: true },
            })
          }
        >
          <SearchIcon className="style-toolbar-buttons" />
        </IconButton>

        {/* Notifications */}
        <ToolbarNotifications selectNotification={selectNotification} />
      </div>
    </Toolbar>
  );
}

export function SearchPays({ mutate1ObjectInPayments, searchInData }) {
  const SearchBy = (
    <Select
      className="select-searchBy"
      defaultValue="all"
      value={searchInData.searchBy}
      onChange={(value) =>
        mutate1ObjectInPayments("paymentsManage", {
          searchInData: {
            ...searchInData,
            searchBy: value,
            showPopover: false,
          },
        })
      }
    >
      <Option value="id">Id</Option>
      <Option value="paid_to_name">Pagar a</Option>
      <Option value="state">Estado</Option>
      <Option value="creator">Creador</Option>
      <Option value="entity">Entidad</Option>
      <Option value="all">Todos</Option>
    </Select>
  );

  function setPlaceholderSearchBy(searchBy) {
    if (searchBy === "id") return "Id";
    else if (searchBy === "paid_to_name") return "Pagar a";
    else if (searchBy === "state") return "Estado";
    else if (searchBy === "creator") return "Creador";
    else if (searchBy === "entity") return "Entidad";
    else return "Todos";
  }

  return (
    <Toolbar className="toolbar">
      {/* FILTER ORDER */}
      <FormControl
        id="toolbar-search-order-container"
        className="center animated fadeIn"
      >
        <Input
          id="toolbar-search-order-input"
          style={{ height: "100%" }}
          autoFocus
          name="data"
          placeholder={setPlaceholderSearchBy(searchInData.searchBy)}
          value={searchInData.data}
          onChange={(e) =>
            mutate1ObjectInPayments("paymentsManage", {
              searchInData: {
                ...searchInData,
                [e.target.name]: e.target.value,
              },
            })
          }
          startAdornment={
            <Popover
              content={SearchBy}
              placement="rightBottom"
              title="Buscar por"
              trigger="click"
              visible={searchInData.showPopover}
              onVisibleChange={() =>
                mutate1ObjectInPayments("paymentsManage", {
                  searchInData: {
                    ...searchInData,
                    showPopover: !searchInData.showPopover,
                  },
                })
              }
            >
              <IconButton>
                <SearchIcon className="style-toolbar-buttons" />
              </IconButton>
            </Popover>
          }
          endAdornment={
            <IconButton
              onClick={() =>
                mutate1ObjectInPayments("paymentsManage", {
                  searchInData: {
                    ...searchInData,
                    renderToolbar: false,
                    data: "",
                    searchBy: "all",
                  },
                })
              }
            >
              <Icon type="close" className="style-toolbar-buttons" />
            </IconButton>
          }
        />
      </FormControl>
    </Toolbar>
  );
}
