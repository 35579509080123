import CommonOperations from "core/database/CommonOperations";

export default class SerializedCollectorLayoutPhoto {
  constructor(data) {
    // Photo
    this.photoId = data.photoId || data.id;
    this.name = data.photoName || data.name;
    this.photoProps = data.photoProps;
    // Layout
    this.layoutPhotoId = data.layoutPhotoId || "null";
    this.filters = data.filters;
    this.hideLabel = data.hideLabel;
    // Metadata
    this.duplicated = data.duplicated ? "true" : "false";
    this.required = data.required;
    this.sort = data.sort;
    this.createdAt = data.createdAt;
    this.photoLayoutDeleted = data.photoLayoutDeleted;
    this.id = CommonOperations.assetId({ ...data, photoId: this.photoId });
    this.duplicatedId =
      this.duplicated === "true" ? data.duplicatedId : undefined;
    this.collectorLayoutId =
      data.collectorLayoutId || CommonOperations.collectorLayoutId(data);
    this.isPhoto = data.layoutPhotoId !== undefined;
    this.photoIdx = data.photoIdx; //TODO: Pending to delete
  }
}
