//Libs
import React from "react";
import { connect } from "react-redux";
//Utils
import KEYWORDS from "./keywords";
//Context
import { PhotoProvider, PhotoContext } from "context";
//Actions
import { mutateConsumeInventoryControl } from "components/components/ConsumeInventory/actions";
import { sendToast } from "components/containers/Dashboard/actions";
import {
  mutate1Object as mutate1ObjectInCollector,
  updateCollectorRequiredValidation,
  onGenerateReport,
  completeAuditedReport,
  setAutoSync,
} from "./actions";
import { uploadResource } from "components/components/UploadResource/actions";
//Selectors
import {
  selectReviewManage,
  selectAvailableReports,
  selectGeneratedReports,
  selectCollectorEcosystemAutoSync,
} from "./selectors";
import { selectProfile } from "components/containers/Login/selectors";
//Components
import {
  Wrapper,
  Collapse as _Collapse,
  ServiceTaskSection,
  ServiceTaskHeader,
  CollectorUtils,
} from "components/components";
import CollectorModals from "./modals";

const { Collapse, Panel } = _Collapse;

const initialState = {
  duplicateElement: null,
  deleteElement: null,
};

const CollectorLayout = React.memo(
  ({
    //Props
    segment,
    collectorEcosystemAutoSync,
    order,
    docId,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
    //Redux
    profile,
    reviewManage,
    availableReports,
    generatedReports,
    mutate1ObjectInCollector,
    completeAuditedReport,
    updateCollectorRequiredValidation,
    uploadResource,
    onGenerateReport,
    setAutoSync,
    sendToast,
    mutateConsumeInventoryControl,
    onLoadSegmentedResources,
  }) => {
    const {
      orderId,
      showSendConfirmationModal,
      selectedCurrentCollector,
      collectorRequiredValidation,
      autoFillCollector,
      transactionDocs,
    } = reviewManage;
    const { highlight: highlightRequired } = collectorRequiredValidation;
    const { isPhotoBackupActive, isSynapseCameraActive } =
      React.useContext(PhotoContext);
    const [state, _setState] = React.useState(initialState);
    const setState = (newState) =>
      _setState((current) => ({ ...current, ...newState }));

    //Reset Collapse
    React.useEffect(() => {
      setState(initialState);
    }, [transactionDocs.docId]);

    //Get service tasks
    const serviceTasks = React.useMemo(
      () => CollectorUtils.getServiceTasks(filteredCollectorLayout),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filteredCollectorLayout, duplicatedCollectorLayout]
    );

    React.useEffect(() => {
      const hasInventory = !!collectorLayout.find(
        (review) =>
          !!review.collectors.find(
            (collector) =>
              collector.typeKey === KEYWORDS.COLLECTORS.TYPES.CONSUME_INVENTORY
          )
      );
      mutateConsumeInventoryControl({ controlledInventory: hasInventory });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectorLayout]);

    if (!orderId || !order) return null;

    return (
      <Wrapper padding="0" flexDirection="column" className="animated fadeIn">
        <Collapse
          accordion
          bordered={false}
          defaultActiveKey={["-1"]}
          activeKey={segment.serviceTaskId}
          destroyInactivePanel={true}
          onChange={(activeKey) => {
            onLoadSegmentedResources({
              serviceTaskId: activeKey,
              reviewId: undefined,
              groupId: undefined,
              subgroupId: undefined,
              resetCollectorLayout: true,
            });
          }}
        >
          {serviceTasks.map((task) => (
            <Panel
              key={task.serviceTaskId}
              header={
                <ServiceTaskHeader
                  task={task}
                  order={order}
                  docId={docId}
                  readOnlyCollector={readOnlyCollector}
                  highlightRequired={highlightRequired}
                  selectedCurrentCollector={selectedCurrentCollector}
                  autoFillCollector={autoFillCollector}
                  availableReports={availableReports}
                  generatedReports={generatedReports}
                  profile={profile}
                  mutate1ObjectInCollector={mutate1ObjectInCollector}
                  onGenerateReport={onGenerateReport}
                />
              }
            >
              <ServiceTaskSection
                collectorEcosystemAutoSync={collectorEcosystemAutoSync}
                segment={segment}
                task={task}
                profile={profile}
                order={order}
                docId={docId}
                readOnlyCollector={readOnlyCollector}
                highlightRequired={highlightRequired}
                selectedCurrentCollector={selectedCurrentCollector}
                isPhotoBackupActive={isPhotoBackupActive}
                isSynapseCameraActive={isSynapseCameraActive}
                collectorLayout={collectorLayout}
                duplicatedCollectorLayout={duplicatedCollectorLayout}
                filteredCollectorLayout={filteredCollectorLayout}
                collectorValues={collectorValues}
                resources={resources}
                handleOnDuplicateElement={(duplicateElement) =>
                  setState({ duplicateElement })
                }
                handleOnDeleteElement={(deleteElement) =>
                  setState({ deleteElement })
                }
                onLoadSegmentedResources={onLoadSegmentedResources}
              />
            </Panel>
          ))}
        </Collapse>

        {/* Modals */}
        <CollectorModals
          //State
          profile={profile}
          order={order}
          docId={docId}
          showSendConfirmationModal={showSendConfirmationModal}
          autoFillCollector={autoFillCollector}
          serviceTasks={serviceTasks}
          collectorLayout={collectorLayout}
          filteredCollectorLayout={filteredCollectorLayout}
          collectorValues={collectorValues}
          resources={resources}
          duplicateElement={state.duplicateElement}
          deleteElement={state.deleteElement}
          //Actions
          handleOnCloseDuplicateElement={() =>
            setState({ duplicateElement: null })
          }
          handleOnCloseDeleteElement={() => setState({ deleteElement: null })}
          mutate1ObjectInCollector={mutate1ObjectInCollector}
          updateCollectorRequiredValidation={updateCollectorRequiredValidation}
          uploadResource={uploadResource}
          completeAuditedReport={completeAuditedReport}
          setAutoSync={setAutoSync}
          sendToast={sendToast}
          onLoadSegmentedResources={onLoadSegmentedResources}
        />
      </Wrapper>
    );
  }
);

const CollectorLayoutWrapper = (props) => (
  <PhotoProvider>
    <CollectorLayout {...props} />
  </PhotoProvider>
);

const mapStateToProps = (state) => ({
  profile: selectProfile(state),
  reviewManage: selectReviewManage(state),
  availableReports: selectAvailableReports(state),
  generatedReports: selectGeneratedReports(state),
  collectorEcosystemAutoSync: selectCollectorEcosystemAutoSync(state),
});

const actions = {
  mutate1ObjectInCollector,
  completeAuditedReport,
  updateCollectorRequiredValidation,
  uploadResource,
  onGenerateReport,
  setAutoSync,
  sendToast,
  mutateConsumeInventoryControl,
};

export default connect(mapStateToProps, actions)(CollectorLayoutWrapper);
