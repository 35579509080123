//Libs
import moment from "moment";
import Immutable from "immutable";
//Utils
import GENERAL from "utils/constants/general";
import {
  GlobalUtils,
  ProjectUtils,
  ServiceUtils,
  OrderUtils,
  UserUtils,
} from "utils";
import { idbHandler } from "utils/libs";
import { formatDate } from "utils/libs/dateFormats";
import ManagePermissions from "./Authorization";
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
//Keywords
import COLLECTOR_KEYWORDS from "./keywords";
//Checks
import CheckUtils from "./CheckUtils";
import {
  AssetRepository,
  CollectorLayoutRepository,
  CollectorValueRepository,
} from "core/database";
import { SentryService } from "services";
import { SerializedCollectorValue } from "core/database/CollectorValues/models";
import { SerializedCollectorLayout } from "core/database/CollectorLayouts/models";
import { SerializedCollectorLayoutPhoto } from "core/database/CollectorLayoutPhotos/models";

const { ENV } = GENERAL;
const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;
const { STATUS } = ENV.UPLOAD_RESOURCE;

export default class CollectorUtils {
  //CHECKS
  //Check is array
  static checkArray(arr) {
    return Array.isArray(arr) ? arr : [];
  }
  static isTruthy(value) {
    return value !== "" && value !== null && value !== undefined;
  }
  //Check is collector have valid photos
  static checkCollectorPhotosValid = (collector) =>
    this.checkArray(collector.photos) && collector.photos.length > 0;
  //Check unsucess collector
  static checkSuccessCollector = (collector) =>
    collector.status === COLLECTOR_KEYWORDS.COLLECTORS.STATUS.SUCCESS;
  //Check unsucess collector
  static checkUnsuccessCollector = (collector) =>
    collector.status !== COLLECTOR_KEYWORDS.COLLECTORS.STATUS.SUCCESS;
  //Check collector lifetime
  static checkCollectorLifetime = (collector, now) => {
    const collectorCreatedAt = new Date(collector.createdAt);
    const collectorLifetimeOut = collectorCreatedAt.setHours(
      collectorCreatedAt.getHours() + 6
    );
    return (
      this.checkSuccessCollector(collector) && collectorLifetimeOut - now > 0
    );
  };
  //Check read-only collector
  static checkReadOnlyCollector(docId, transactionDocs, profile) {
    const tDoc = transactionDocs.find((t) => t.id === docId);
    //Return read-only if...
    if (tDoc) {
      //User not have permission
      if (!new ManagePermissions(profile).checkEditCollectors()) return true;
      //Document is closed
      if (tDoc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.CLOSED)
        return true;
      //EXP User is not creator
      if (
        profile?.user?.division_id === GENERAL.DIVISION_ID.EXP &&
        Number(tDoc.createdBy) !== Number(profile.user.id)
      )
        return true;
    }
  }
  //Check can edit report
  static checkEditOTDCollectors(doc, profile) {
    //Si el usuario tiene permisos para editar
    if (new ManagePermissions(profile).checkEditCollectors()) {
      //Si es un EXP, sólo puede editar sus propios reportes
      if (profile?.user?.division_id === GENERAL.DIVISION_ID.EXP) {
        return Number(doc.createdBy) === Number(profile.user.id);
      }
      //Si es un ADM, puede editar
      return profile?.user?.division_id === GENERAL.DIVISION_ID.ADM;
    }
    return false;
  }
  //Check autoSync active
  static checkAutoSyncActive(autoSyncActive, sendingCollectorValues) {
    return autoSyncActive || sendingCollectorValues;
  }
  //Check pending collector and resource
  static async checkIsCollectorAndResourceRequiredDataPending({
    isActiveDataValidator, //Data validator is active?
    validateData,
    updateCollectorRequiredValidation,
    sendToast,
  }) {
    updateCollectorRequiredValidation({
      highlight: {},
      state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.VALIDATING,
    });
    const { pendingCollectors, pendingCollectorResources } =
      await this.isPendingRequiredData(validateData);

    if (
      isActiveDataValidator &&
      (Object.keys(pendingCollectors).length > 0 ||
        Object.keys(pendingCollectorResources).length > 0)
    ) {
      sendToast({
        message: "Le he marcado los datos requeridos",
        type: "warn",
      });
      updateCollectorRequiredValidation({
        highlight: {
          ...pendingCollectors,
          ...pendingCollectorResources,
        },
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.REQUIRED,
      });
      updateCollectorRequiredValidation({
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING,
      });
      return false;
    } else {
      updateCollectorRequiredValidation({
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.APPROVED,
      });
      return true;
    }
  }

  //GETTERS
  //Get unsuccess collectors
  static getUnsuccessCollectors({ collectors, max = 50 }) {
    return this.checkArray(collectors)
      .filter((c) => this.checkUnsuccessCollector(c))
      .slice(0, max);
  }
  //Get unsuccess collector count from order id
  static getUnsuccessCollectorCountFromOrder(orderId, collectors) {
    return this.checkArray(collectors)
      .filter((c) => c.auditOrderId === orderId)
      .filter((c) => this.checkUnsuccessCollector(c)).length;
  }
  static async mergeCollectorValues(newCollectorValues, { format } = {}) {
    if (format === "beforeMatch")
      return CollectorValueRepository.merge(
        this.getFormattedCollectorValues(newCollectorValues)
      );

    //The purpose of this formatting is mainly to update the value of the "id" prop
    if (!format || format === "afterMatch")
      return CollectorValueRepository.merge(
        this.getFormattedCollectorValues(newCollectorValues, ["id"])
      );
  }
  //Get collector value from IDX
  static getCollectorValueFromIdx(collectorValues, idx) {
    if (CheckUtils.invalidIdx(idx)) {
      return null;
    }
    return collectorValues[idx];
  }
  //Get collector photo props
  static getCollectorPhotoProps(photo) {
    if (!photo || typeof photo !== "object") return {};
    return !photo["photoProps"] || typeof photo["photoProps"] !== "object"
      ? {}
      : photo["photoProps"];
  }
  //Get Collector IDX from Collector values
  static getCollectorIdxFromCollectorValues(collectorValues, collectorProps) {
    return this.checkArray(collectorValues).findIndex((collector) =>
      CheckUtils.collectorExistsInCollectorValues(collector, collectorProps)
    );
  }

  //Get duplicated group layout
  static getDuplicatedReview(
    newReviewId,
    newReviewName,
    { docId, orderId, review, createdAt }
  ) {
    const reviewId = `duplicated#${newReviewId}`;
    return review.collectors.map((collector) => ({
      ...review,
      ...collector,
      docId,
      orderId,
      id: reviewId,
      name: newReviewName,
      reviewId,
      reviewName: newReviewName,
      layoutId: null,
      createdAt,
      duplicated: true,
      photos: collector.photos.map((photo) => ({
        ...photo,
        layoutPhotoId: null,
        createdAt,
        duplicated: true,
      })),
    }));
  }
  //Get duplicated group layout
  static getDuplicatedGroup(
    newGroupId,
    newGroupName,
    { docId, orderId, collectors, review, createdAt }
  ) {
    return collectors.map((collector) => ({
      ...review,
      ...collector,
      docId,
      orderId,
      groupId: `duplicated#${newGroupId}`,
      groupName: newGroupName,
      layoutId: null,
      createdAt,
      duplicated: true,
      photos: collector.photos.map((photo) => ({
        ...photo,
        layoutPhotoId: null,
        createdAt,
        duplicated: true,
      })),
    }));
  }
  //Get duplicated subgroup layout
  static getDuplicatedSubgroup(
    newSubgroupId,
    newSubgroupName,
    { docId, orderId, collectors, review, createdAt }
  ) {
    return collectors.map((collector) => ({
      ...review,
      ...collector,
      docId,
      orderId,
      subgroupId: `duplicated#${newSubgroupId}`,
      subgroupName: newSubgroupName,
      layoutId: null,
      createdAt,
      duplicated: true,
      photos: collector.photos.map((photo) => ({
        ...photo,
        layoutPhotoId: null,
        createdAt,
        duplicated: true,
      })),
    }));
  }
  //Get duplicated photo layout
  static getDuplicatedPhoto(
    newPhotoId,
    newPhotoName,
    { docId, orderId, collector, photo, review, createdAt }
  ) {
    return [
      {
        ...review,
        ...collector,
        docId,
        orderId,
        createdAt,
        duplicated: true,
        photos: [
          {
            ...photo,
            id: `duplicated#${newPhotoId}`,
            photoId: `duplicated#${newPhotoId}`,
            name: newPhotoName,
            layoutPhotoId: null,
            createdAt,
            duplicated: true,
          },
        ],
      },
    ];
  }

  //Get duplicated review level Idx
  static getDuplicatedReviewIdx({
    orderId,
    serviceId,
    serviceTaskId,
    duplicatedReviewName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${duplicatedReviewName}`;
  }
  //Get duplicated group level Idx
  static getDuplicatedGroupIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    duplicatedGroupName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${duplicatedGroupName}`;
  }
  //Get duplicated group level Idx
  static getDuplicatedSubgroupIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    duplicatedSubgroupName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${duplicatedSubgroupName}`;
  }
  //Get duplicated collector level Idx
  static getDuplicatedCollectorIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    subgroupId,
    duplicatedCollectorName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${subgroupId}collector${duplicatedCollectorName}`;
  }
  static isDuplicable(record) {
    return (
      this.checkDuplicable(record.reviewName) ||
      this.checkDuplicable(record.groupName) ||
      this.checkDuplicable(record.subgroupName)
    );
  }
  static buildNeighborhood = (record, neighborhood) => {
    const {
      templateId,
      serviceTaskId,
      reviewName = null,
      groupName = null,
      subgroupName = null,
      reviewId,
      groupId,
      subgroupId,
    } = record;

    neighborhood[
      `${templateId}/${serviceTaskId}/${reviewName}/${groupName}/${subgroupName}`
    ] = { reviewId, groupId, subgroupId };

    return neighborhood;
  };
  static serializeNeighborhood(record, neighborhood) {
    const hasStatus = !!record.status;
    const isPendingStatus = record.status !== STATUS.SUCCESS;

    if (!this.isDuplicable(record) || (hasStatus && isPendingStatus))
      return neighborhood;

    return this.buildNeighborhood(record, neighborhood);
  }
  static deserializeNeighborhood(key) {
    let [templateId, serviceTaskId, reviewName, groupName, subgroupName] =
      key.split("/");

    groupName = groupName === "null" ? null : groupName;
    subgroupName = subgroupName === "null" ? null : subgroupName;

    return [templateId, serviceTaskId, reviewName, groupName, subgroupName];
  }

  //Get collector props
  static getCollectorProps = (order = {}, collector = {}, inventory = {}) =>
    new SerializedCollectorValue({
      ...collector,
      orderId: collector.auditOrderId || order.order_id,
      docId: collector.docId || order.docId,
      collectorId: collector.collectorId || collector.id,
      collectorName: collector.collectorName || collector.name,
      //Inventory
      inventoryItemId: inventory.itemId || collector.inventoryItemId,
      inventorySerieId: inventory.serieId || collector.inventorySerieId,
      inventoryAmount: inventory.amount || collector.inventoryAmount,
      inventoryInitialAmount:
        inventory.initialAmount || collector.inventoryInitialAmount,
      inventoryFinalAmount:
        inventory.finalAmount || collector.inventoryFinalAmount,
    });

  //Get formatted duplicate element
  static getFormattedDuplicateElement(
    level,
    { docId, orderId, collector, review, photo }
  ) {
    if (level === DUPLICATION.LEVELS.REVIEW) {
      return {
        name: review.name, //Label to show in delete confirmation message
        orderId,
        docId,
        templateId: review.templateId,
        serviceId: review.serviceId,
        serviceTaskId: review.serviceTaskId,
        reviewId: review.id,
        reviewName: review.name,
        createdAt: new Date().toISOString(),
      };
    }
    if (level === DUPLICATION.LEVELS.GROUP) {
      return {
        name: collector.groupName, //Label to show in delete confirmation message
        orderId,
        docId,
        templateId: collector.templateId,
        serviceId: review.serviceId,
        serviceTaskId: collector.serviceTaskId,
        reviewId: collector.reviewId,
        reviewName: review.name,
        groupId: collector.groupId,
        groupName: collector.groupName,
        createdAt: new Date().toISOString(),
      };
    }
    if (level === DUPLICATION.LEVELS.SUBGROUP) {
      return {
        name: collector.subgroupName, //Label to show in delete confirmation message
        orderId,
        docId,
        templateId: collector.templateId,
        serviceId: review.serviceId,
        serviceTaskId: collector.serviceTaskId,
        reviewId: collector.reviewId,
        reviewName: review.name,
        groupId: collector.groupId,
        groupName: collector.groupName,
        subgroupId: collector.subgroupId,
        subgroupName: collector.subgroupName,
        createdAt: new Date().toISOString(),
      };
    }
    //TODO: Implement collector duplicated element
    // if (level === DUPLICATION.LEVELS.COLLECTOR) {}
    if (level === DUPLICATION.LEVELS.PHOTO) {
      return {
        name: photo.photoName, //Label to show in delete confirmation message
        orderId: orderId || photo.auditOrderId,
        docId: docId || photo.docId,
        templateId: photo.templateId,
        serviceId: photo.serviceId,
        serviceTaskId: photo.serviceTaskId,
        reviewId: photo.reviewId,
        reviewName: photo.reviewName,
        groupId: photo.groupId,
        groupName: photo.groupName,
        subgroupId: photo.subgroupId,
        subgroupName: photo.subgroupName,
        collectorId: photo.collectorId,
        collectorName: photo.collectorName,
        photoId: photo.photoId,
        photoName: photo.photoName,
        createdAt: new Date().toISOString(),
        layoutPhotoId: null,
        duplicated: photo.duplicated,
      };
    }
  }
  //Get combine order and service task
  static getCombineOrderAndServiceTask(order, serviceTaskId) {
    return `order${order.order_id}serviceTaskId${serviceTaskId}`;
  }
  //Reload offline autoFillCollector
  static reloadOfflineAutoFillCollector() {
    return idbHandler.getAutoFillCollector();
  }
  //Get all collectors from collectorLayout
  static getAllCollectorsFromReviews(collectorLayout) {
    return collectorLayout.reduce(
      (acc, review) =>
        Array.isArray(review.collectors) ? [...acc, ...review.collectors] : acc,
      []
    );
  }
  //Get required collector pending
  static isRequiredCollectorPending(order, collectorValues, collectors) {
    //I'm looking for a required collector that hasn't been completed in <collectorValues>
    return collectors.reduce((acc, collector) => {
      if (!collector.required) return acc;

      const collectorProps = this.getCollectorProps(order, collector);
      const collectorValueIdx = this.getCollectorIdxFromCollectorValues(
        collectorValues,
        collectorProps
      );
      const collectorValue = collectorValues[collectorValueIdx];
      if (
        collectorValue?.value &&
        collectorValue.value !==
          COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.UNDEFINED_VALUE
      )
        return acc;

      return {
        ...acc,
        ...this.setHighlightCollector(collectorProps),
      };
    }, {});
  }
  //Get highlight collector
  static getHighlightCollector(
    level,
    highlight,
    {
      id,
      subgroupId,
      subgroupName,
      groupId,
      groupName,
      reviewId,
      serviceTaskId,
    }
  ) {
    return {
      [DUPLICATION.LEVELS.COLLECTOR]: highlight[`collectorId_${id}`],
      [DUPLICATION.LEVELS.SUBGROUP]:
        highlight[
          `subgroupId_${subgroupId || subgroupName}_groupId_${
            groupId || groupName
          }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`
        ],
      [DUPLICATION.LEVELS.GROUP]:
        highlight[
          `groupId_${
            groupId || groupName
          }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`
        ],
      [DUPLICATION.LEVELS.REVIEW]:
        highlight[`reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`],
      [DUPLICATION.LEVELS.SERVICE_TASK]:
        highlight[`serviceTaskId_${serviceTaskId}`],
    }[level];
  }
  static checkDuplicable(text) {
    const regex = /#\d+$/;
    return regex.test(text);
  }
  static getDuplicableKeyOrId(record) {
    const key = (text) => {
      if (!this.checkDuplicable(text)) return;

      const counterNumRegex = /\d+$/;
      const [counter] = text.match(counterNumRegex) || [];
      if (isNaN(counter)) return;

      return `duplicated#${counter}`;
    };

    if (record.level === "review")
      return (
        key(record.reviewName) || Number(record.reviewId) || record.reviewId
      );

    return record.reviewId;
  }
  //Set highlight collector
  static setHighlightCollector({
    id,
    subgroupId,
    subgroupName,
    groupId,
    groupName,
    reviewId,
    serviceTaskId,
  }) {
    return {
      [`collectorId_${id}`]: true,
      [`subgroupId_${subgroupId || subgroupName}_groupId_${
        groupId || groupName
      }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`groupId_${
        groupId || groupName
      }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`serviceTaskId_${serviceTaskId}`]: true,
    };
  }
  //Get pending required data
  static async isPendingRequiredData({
    order,
    filteredCollectorLayout,
    collectorValues,
  }) {
    const pendingCollectors = this.isRequiredCollectorPending(
      order,
      collectorValues,
      this.getAllCollectorsFromReviews(filteredCollectorLayout)
    );
    const collectors = this.getAllCollectorsFromReviews(
      filteredCollectorLayout
    );
    const pendingCollectorResources =
      await UploadResourceUtils.isRequiredCollectorResourcePending(
        order,
        collectors
      );

    return { pendingCollectors, pendingCollectorResources };
  }
  //Get grouped collectors from review
  static getGroupedCollectorsFromReview(collectors) {
    return this.checkArray(collectors).filter((c) => c.groupId);
  }
  //Get ungrouped collectors from review
  static getUngroupedCollectorsFromReview(collectors) {
    return this.checkArray(collectors).filter((c) => !c.groupId);
  }
  //Get ungrouped collectors from group
  static getGroupedCollectorsFromGroup(collectors, groupId) {
    return this.checkArray(collectors).filter(
      (collector) => collector.groupId === groupId
    );
  }
  //Get groups from review
  static getGroupsFromGroupedCollectors(
    level,
    { docId, orderId, review },
    collectors
  ) {
    const levelId =
      level === DUPLICATION.LEVELS.GROUP ? "groupId" : "subgroupId";
    return this.checkArray(collectors)
      .filter((collector) => collector[levelId])
      .reduce((groups, collector) => {
        const group = groups.find(
          (group) => group[levelId] === collector[levelId]
        );
        if (!group)
          groups.push(
            this.getFormattedDuplicateElement(level, {
              docId,
              orderId,
              collector,
              review,
            })
          );
        return groups;
      }, []);
  }
  //Get subgrouped collectors
  static getSubgroupedCollectors(collectors) {
    return this.checkArray(collectors).filter((c) => c.subgroupId);
  }
  //Get unsubgrouped collectors from review
  static getUnsubgroupedCollectors(collectors) {
    return this.checkArray(collectors).filter((c) => !c.subgroupId);
  }
  //Get unsubgrouped collectors from group
  static getSubgroupedCollectorsFromSubgroup(collectors, subgroupId) {
    return this.checkArray(collectors).filter(
      (collector) => collector.subgroupId === subgroupId
    );
  }
  //Get coords from value
  static getCoordsFromValue(value) {
    if (value) {
      try {
        const coords = JSON.parse(value);
        if (coords) return coords;
      } catch (err) {
        return {};
      }
    }
    return {};
  }
  //Get service tasks
  static getServiceTasks(collectorLayout) {
    return collectorLayout.reduce((acc, review) => {
      let idx = acc.findIndex((r) => r.serviceTaskId === review.serviceTaskId);
      if (idx === -1) {
        const { serviceTaskId, serviceTaskName, serviceTaskProps } = review;
        acc.push({
          serviceTaskId,
          serviceTaskName,
          serviceTaskProps,
        });
      }
      return acc;
    }, []);
  }
  //Get template id from collector layout
  static getTemplateIdFromCollectorLayout(collector, collectorLayout) {
    if (!collector || !Array.isArray(collectorLayout)) return null;
    return collectorLayout.reduce((acc, r) => {
      const coll = r.collectors.find((c) => c.layoutId === collector.layoutId);
      if (coll) acc = coll.templateId;
      return acc;
    }, null);
  }
  //Get saved DB collector values
  static getFormattedCollectorValues(collectorValues, onlyKeys) {
    return Immutable.List(collectorValues)
      .toJS()
      .map((cv) => {
        const collectorProps = this.getCollectorProps(
          { order_id: cv.auditOrderId, docId: cv.docId },
          cv
        );
        delete cv.photos;
        if (!onlyKeys)
          return {
            ...cv,
            ...collectorProps,
            duplicatedId: cv.id,
          };
        for (let key of onlyKeys) {
          cv.duplicatedId = cv.id;
          cv[key] = collectorProps[key];
        }
        return cv;
      });
  }
  //Get collector value
  static getCollectorValue({ value, typeKey, readOnlyCollector, profile }) {
    //Only Read Collector
    if (readOnlyCollector) {
      switch (typeKey) {
        //BOOLEAN
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.BOOLEAN:
          if (value === undefined)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === "true" || value === true)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.YES_RESPONSE;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NO_RESPONSE;
        //STRING
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.STRING:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return value;
        //NUMBER
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.NUMBER:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          if (!isNaN(parseInt(value))) return value;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
            .UNDEFINED_RESPONSE;
        //TIME - DATE - DATETIME
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.TIME:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATE:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATETIME:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return formatDate(value, profile);
        //VOID - SIGNERCANVAS
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.SIGNER_CANVAS:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.VOID:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_RESPONSE;
        //COORDS
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.COORDS:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return value;
        //LIST
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.LIST:
          return value;
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.FILE:
          return value;
        default:
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
            .UNDEFINED_RESPONSE;
      }
    }
    //Active Input Collector
    else {
      // eslint-disable-next-line default-case
      switch (typeKey) {
        //BOOLEAN
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.BOOLEAN:
          return value === "true" || value === true;
        //STRING
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.STRING:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_RESPONSE;
          return value;
        //NUMBER
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.NUMBER:
          return !isNaN(parseInt(value)) ? value : undefined;
        //TIME - DATE - DATETIME
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.TIME:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATE:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATETIME:
          return value && moment(value).isValid() ? moment(value) : null;
        //LIST
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.LIST:
          return value;
      }
    }
  }
  //Get avatar formatted
  static formatAvatars(data) {
    if (!Array.isArray(data)) return [];
    return data.map((otd) => ({
      id: otd.id,
      name: otd.creatorName,
      src: otd.creatorProfilePhoto,
      docId: otd.id,
    }));
  }
  //Get selected avatar id
  static getAvatarIdFromSelectedTransactionDoc(transactionDocs, selectedDocId) {
    const tDoc = this.checkArray(transactionDocs).find(
      (t) => t.id === selectedDocId
    );
    return tDoc?.id;
  }
  //Get available reports
  static getAvailableReports(selectedDocId, transactionDocs) {
    const tDoc = this.checkArray(transactionDocs).find(
      (t) => t.id === selectedDocId
    );
    return Immutable.List(tDoc?.availableReports).toJS() ?? [];
  }
  //Get url from available report
  static getUrlFromAvailableReport(serviceTaskId, availableReports) {
    return (
      this.checkArray(availableReports).find(
        (serviceTask) => serviceTask.id === serviceTaskId
      ) ?? {}
    );
  }
  //Get URI from Collector Ecosystem
  static getURI({ orderId, docId, state, workflow, context }) {
    switch (context) {
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_AUDITED_TRANSACTION_DOCS:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!orderId) return undefined;
          return `/order_transaction_docs/getTotalAuditedOTD/${orderId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!docId) return undefined;
          return `/collectorManager/v1/collectorValues/${orderId}/${docId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_SAVED_COLLECTOR_VALUES_BY_ORDER:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!orderId) return undefined;
          return `/collectorManager/v1/collectorValues/${orderId}/${docId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .UPDATE_STATE_AUDITED_TRANSACTION_DOC:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!docId || !state) return undefined;
          return `/order_transaction_docs/updateAuditedOTDState/${docId}/${state}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .SAVE_COLLECTOR_VALUES:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/saveAuditedOrderCollectorValues`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .AUTO_SYNC_COLLECTOR_VALUES:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/saveAuditedOrderCollectorValues`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .COMPLETE_AUDITED_REPORT:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/completeAuditedOrder`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.GENERATE_REPORT:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/generateReportFromAuditedOrder`;
        }
        break;
      default:
        return;
    }
  }
  //Get selected transaction doc
  static getSelectedDocFromTransactionDocs(transactionDocs, selectedDocId) {
    return transactionDocs.find((t) => t.id === selectedDocId);
  }

  //Get collector photo config
  static getCollectorPhotoConfig(order, profile) {
    return GlobalUtils.selectCurrentProps([
      ProjectUtils.getProjectPropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      ServiceUtils.getServicePropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      OrderUtils.getOrderPropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      UserUtils.getUserPropsFromProfile(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        profile
      ),
    ]);
  }

  /**
   * DUPLICATION ELEMENT
   */

  //Get duplicate label
  static getDuplicateLabel(level) {
    return DUPLICATION.NEW_NAME_LABEL[level];
  }
  //Get duplicate label
  static getDeleteLabel(level) {
    return DUPLICATION.DELETE_NAME_LABEL[level];
  }
  //Get original element name
  static getOriginalElementName(elementName) {
    if (typeof elementName !== "string" || !elementName.includes("#")) return;
    return elementName.split("#")[0].trim();
  }
  //Get original element name
  static getCurrentSequencialNumber(elementName) {
    if (typeof elementName !== "string" || !elementName.includes("#")) return;
    const currentNumber = elementName.split("#")[1]?.trim();
    return Number(currentNumber);
  }
  //Check duplicated level
  static isDuplicatedLevel(elementName) {
    const levelNumber = this.getCurrentSequencialNumber(elementName);
    return !isNaN(levelNumber) && levelNumber > 1;
  }
  //Get last sequence
  static getLastSequence(items) {
    return items
      .filter((sequencialNumber) => !!sequencialNumber)
      .sort((a, b) => (a > b ? -1 : b > a ? 1 : 0))[0]; //Sort in desc mode
  }
  //Get last sequence number
  static getLastSequenceNumber(originalElementName, elements) {
    return this.getLastSequence(
      elements
        .filter(({ name }) => name?.includes(originalElementName))
        .map(({ name }) => this.getCurrentSequencialNumber(name))
    );
  }
  //Get last sequence group number
  static getLastSequenceGroupNumber(
    level,
    originalElementName,
    { docId, orderId, review },
    collectors
  ) {
    const levelName =
      level === DUPLICATION.LEVELS.GROUP ? "groupName" : "subgroupName";

    return this.getLastSequence(
      this.getGroupsFromGroupedCollectors(
        level,
        { docId, orderId, review },
        collectors
      )
        .filter((record) => record[levelName]?.includes(originalElementName))
        .map((record) => this.getCurrentSequencialNumber(record[levelName]))
    );
  }
  //Get current last element
  static getCurrentLastElement(
    level,
    elementName,
    elements,
    { docId, orderId, review } = {}
  ) {
    //Get original element name (without sequencial number)
    const originalElementName = this.getOriginalElementName(elementName);
    if (!originalElementName) return {};

    //Get last sequence number
    let lastSequenceNumber;
    if (level === DUPLICATION.LEVELS.REVIEW) {
      lastSequenceNumber = this.getLastSequenceNumber(
        originalElementName,
        elements
      );
    } else if (
      level === DUPLICATION.LEVELS.GROUP ||
      level === DUPLICATION.LEVELS.SUBGROUP
    ) {
      lastSequenceNumber = this.getLastSequenceGroupNumber(
        level,
        originalElementName,
        { docId, orderId, review },
        elements
      );
    } else if (level === DUPLICATION.LEVELS.PHOTO) {
      lastSequenceNumber = this.getLastSequenceNumber(
        originalElementName,
        elements
      );
    }
    if (!lastSequenceNumber) return {};

    return { originalElementName, lastSequenceNumber };
  }
  //Get duplicated element name
  static getNewDuplicatedElement(
    level,
    elementName,
    elements,
    { docId, orderId, review }
  ) {
    const { originalElementName, lastSequenceNumber } =
      this.getCurrentLastElement(level, elementName, elements, {
        docId,
        orderId,
        review,
      });
    if (!originalElementName || !lastSequenceNumber) return;

    const newElementId = lastSequenceNumber + 1;

    return {
      newElementId,
      newElementName: `${originalElementName} #${newElementId}`,
    };
  }

  //Get duplicated collectors by review duplication process
  static reviewDuplicateProcess = ({
    docId,
    orderId,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const reviews = collectorLayout.filter(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId
    );

    const review = reviews.find((r) => r.id === element.reviewId);
    if (!review) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      DUPLICATION.LEVELS.REVIEW,
      review.name,
      reviews,
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;
    return {
      newElementName,
      newDuplicatedElements: this.getDuplicatedReview(
        newElementId,
        newElementName,
        {
          docId,
          orderId,
          review,
          createdAt: element.createdAt,
        }
      ),
    };
  };

  //Get duplicated collectors by group duplication process
  static groupDuplicateProcess = ({
    docId,
    orderId,
    level,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const review = collectorLayout.find(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId &&
        r.id === element.reviewId
    );
    if (!review) return;

    const duplicateCollectors = review.collectors.filter(
      (collector) =>
        collector.groupId === element.groupId &&
        (level === DUPLICATION.LEVELS.GROUP ||
          collector.subgroupId === element.subgroupId)
    );
    const levelName =
      !!duplicateCollectors.length &&
      duplicateCollectors[0][
        level === DUPLICATION.LEVELS.GROUP ? "groupName" : "subgroupName"
      ];
    if (!levelName) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      level,
      levelName,
      level === DUPLICATION.LEVELS.GROUP
        ? review.collectors
        : review.collectors.filter(
            (collector) => collector.groupId === element.groupId
          ),
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;
    return {
      newElementName,
      newDuplicatedElements:
        level === DUPLICATION.LEVELS.GROUP
          ? this.getDuplicatedGroup(newElementId, newElementName, {
              docId,
              orderId,
              collectors: duplicateCollectors,
              review,
              createdAt: element.createdAt,
            })
          : this.getDuplicatedSubgroup(newElementId, newElementName, {
              docId,
              orderId,
              collectors: duplicateCollectors,
              review,
              createdAt: element.createdAt,
            }),
    };
  };

  //Get duplicated photo by photo duplication process
  static photoDuplicateProcess = ({
    docId,
    orderId,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const review = collectorLayout.find(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId &&
        r.id === element.reviewId
    );
    if (!review) return;

    const collector = review.collectors.find(
      (collector) =>
        collector.groupId === element.groupId &&
        collector.subgroupId === element.subgroupId &&
        collector.id === element.collectorId
    );
    if (!collector) return;

    const photo = collector.photos.find(
      (photo) => photo.id === element.photoId
    );
    if (!photo?.name) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      DUPLICATION.LEVELS.PHOTO,
      photo.name,
      collector.photos,
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;

    return {
      newElementName,
      newDuplicatedElements: this.getDuplicatedPhoto(
        newElementId,
        newElementName,
        {
          docId,
          orderId,
          collector,
          photo,
          review,
          createdAt: element.createdAt,
        }
      ),
    };
  };

  //Run duplicate element process
  static runDuplicateElementProcess = ({
    docId,
    orderId,
    level,
    element,
    collectorLayout,
  }) => {
    const duplicateProcessByLevel = {
      [DUPLICATION.LEVELS.REVIEW]: (payload) =>
        this.reviewDuplicateProcess(payload),
      [DUPLICATION.LEVELS.GROUP]: (payload) =>
        this.groupDuplicateProcess(payload),
      [DUPLICATION.LEVELS.SUBGROUP]: (payload) =>
        this.groupDuplicateProcess(payload),
      // [DUPLICATION.LEVELS.COLLECTOR]: (payload) =>
      //   this.collectorDuplicateProcess(payload),
      [DUPLICATION.LEVELS.PHOTO]: (payload) =>
        this.photoDuplicateProcess(payload),
    }[level];
    if (!duplicateProcessByLevel) return {};

    return (
      duplicateProcessByLevel({
        level,
        docId,
        orderId,
        element,
        collectorLayout,
      }) || {}
    );
  };
  //Get selected order from review
  static getSelectedOrderFromReviewManage(orderId, orders) {
    return orders.find((ord) => ord.order_id === orderId);
  }

  //FILTERS
  //Filter collector values by docId || orderId
  static filterCollectorValues(collectorValues, docId, orderId) {
    return collectorValues.filter(
      (cv) => (docId && cv.docId === docId) || cv.auditOrderId === orderId
    );
  }

  //Filter collector layout by template id
  static filterCollectorLayoutByTemplateId(templateId, templates) {
    if (!templateId) return [];
    const template = this.checkArray(templates).find(
      (t) => t.id === templateId
    );
    return this.checkArray(template?.data_structure_object);
  }

  static getNonRemovableDuplicatedCollectorLayout(
    level,
    deletedAt,
    {
      docId,
      orderId,
      serviceId,
      serviceTaskId,
      duplicatedReviewName,
      duplicatedGroupName,
      duplicatedSubgroupName,
      duplicatedCollectorName,
      duplicatedPhotoName,
      duplicatedCollectorLayout,
    }
  ) {
    //Filter and remove collectors that now exists in collectorLayout
    return this.checkArray(duplicatedCollectorLayout).reduce(
      (acc, duplicatedReview) => {
        //Return if the review belongs to another order
        if (
          (!!docId &&
            !!duplicatedReview.docId &&
            duplicatedReview.docId !== docId) ||
          duplicatedReview.orderId !== orderId
        ) {
          acc.push(duplicatedReview);
          return acc;
        }

        //Return if the review belongs to another review
        if (level === DUPLICATION.LEVELS.REVIEW) {
          if (!this.isDuplicatedLevel(duplicatedReviewName)) {
            acc.push(duplicatedReview);
            return acc;
          }
          const review =
            duplicatedReview.reviewIdx ===
              this.getDuplicatedReviewIdx({
                orderId,
                serviceId,
                serviceTaskId,
                duplicatedReviewName,
              }) &&
            new Date(deletedAt) - new Date(duplicatedReview.createdAt) > 0; // Si <duplicatedReview.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedReview.createdAt> y no se borra
          if (!review) acc.push(duplicatedReview);
          return acc;
        }

        //Return collector doesn't exists in collectorLayout
        duplicatedReview.collectors = duplicatedReview.collectors.reduce(
          (acc, duplicatedCollector) => {
            //Return if the group belongs to another group
            if (level === DUPLICATION.LEVELS.GROUP) {
              if (!this.isDuplicatedLevel(duplicatedGroupName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const group =
                duplicatedCollector.groupIdx ===
                  this.getDuplicatedGroupIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    duplicatedGroupName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra
              if (!group) acc.push(duplicatedCollector);
              return acc;
            }
            //Return if the subgroup belongs to another subgroup
            if (level === DUPLICATION.LEVELS.SUBGROUP) {
              if (!this.isDuplicatedLevel(duplicatedSubgroupName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const subgroup =
                duplicatedCollector.subgroupIdx ===
                  this.getDuplicatedSubgroupIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    duplicatedSubgroupName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra;
              if (!subgroup) acc.push(duplicatedCollector);
              return acc;
            }

            //Return if the collector belongs to another collector
            if (level === DUPLICATION.LEVELS.COLLECTOR) {
              if (!this.isDuplicatedLevel(duplicatedCollectorName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const collector =
                duplicatedCollector.collectorIdx ===
                  this.getDuplicatedCollectorIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    subgroupId: duplicatedSubgroupName,
                    duplicatedCollectorName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra
              if (!collector) acc.push(duplicatedCollector);
              return acc;
            }

            //Return if the photo belongs to another photo
            if (level === DUPLICATION.LEVELS.PHOTO) {
              duplicatedCollector.photos = duplicatedCollector.photos.filter(
                (duplicatedPhoto) => {
                  if (!this.isDuplicatedLevel(duplicatedPhotoName)) return true;
                  const photo =
                    duplicatedPhoto.photoIdx ===
                      UploadResourceUtils.getDuplicatedPhotoIdx({
                        orderId,
                        serviceId,
                        serviceTaskId,
                        reviewId: duplicatedReviewName,
                        groupId: duplicatedGroupName,
                        subgroupId: duplicatedSubgroupName,
                        collectorId: duplicatedCollectorName,
                        duplicatedPhotoName,
                      }) &&
                    new Date(deletedAt) - new Date(duplicatedPhoto.createdAt) >
                      0; // Si <duplicatedPhoto.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedPhoto.createdAt> y no se borra;
                  return !photo;
                }
              );
              acc.push(duplicatedCollector);
            }

            return acc;
          },
          []
        );

        if (duplicatedReview.collectors[0]) acc.push(duplicatedReview);
        return acc;
      },
      []
    );
  }

  //Format to update duplicated collector layout ids
  static formatToUpdateDuplicatedCollectorLayoutIds(
    duplicatedCollectorLayouts
  ) {
    const isCollectorPendingToSync = (c) => !c.layoutId;
    const getPhotosPendingToSync = (photos) =>
      photos.filter((photo) => !photo.layoutPhotoId);

    return duplicatedCollectorLayouts.reduce((acc, c) => {
      const syncPhotos = getPhotosPendingToSync(c.photos);
      if (!isCollectorPendingToSync(c) && !syncPhotos.length) return acc;

      const syncCollector = {
        ...c,
        duplicatedId: new SerializedCollectorLayout(c).id,
        collectorId: c.id,
        collectorName: c.name,
        photos: undefined,
      };
      if (!syncPhotos.length) acc.push(syncCollector);
      else
        syncPhotos.forEach((photo) => {
          const syncPhoto = {
            ...syncCollector,
            ...photo,
            photoId: photo.id,
            photoName: photo.name,
            rliSort: syncCollector.sort,
          };

          syncPhoto.duplicatedId = new SerializedCollectorLayoutPhoto(
            syncPhoto
          ).id;

          acc.push(syncPhoto);
        });

      return acc;
    }, []);
  }

  static buildDuplicatedCollectorLayoutElements(
    orderId,
    duplicatedCollectorLayout,
    duplicatedCollectorLayoutIds
  ) {
    // 1: Obtener únicamente todos los que van a ser actualizados
    // 2: Actualizarlos
    // 3: Y pasárselos al método combineDuplicatedCollectorLayout
    return duplicatedCollectorLayoutIds.reduce(
      (
        updateReviews,
        {
          serviceId,
          serviceTaskId,
          reviewId,
          reviewName: duplicatedReviewName,
          groupId,
          groupName: duplicatedGroupName,
          subgroupId,
          subgroupName: duplicatedSubgroupName,
          collectorId,
          collectorName: duplicatedCollectorName,
          photoId,
          photoName: duplicatedPhotoName,
          layoutId,
          layoutPhotoId,
        }
      ) => {
        const duplicatedReview = duplicatedCollectorLayout.find(
          ({ reviewIdx }) =>
            this.getDuplicatedReviewIdx({
              orderId,
              serviceId,
              serviceTaskId,
              duplicatedReviewName,
            }) === reviewIdx
        );
        if (!duplicatedReview) return updateReviews;

        duplicatedReview.id = reviewId;

        const duplicatedCollector = duplicatedReview.collectors.find(
          ({ groupIdx, subgroupIdx, collectorIdx }) =>
            this.getDuplicatedGroupIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              duplicatedGroupName,
            }) === groupIdx &&
            this.getDuplicatedSubgroupIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              duplicatedSubgroupName,
            }) === subgroupIdx &&
            this.getDuplicatedCollectorIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              subgroupId: duplicatedSubgroupName,
              duplicatedCollectorName,
            }) === collectorIdx
        );
        if (!duplicatedCollector) return updateReviews;

        if (groupId) duplicatedCollector.groupId = groupId;
        if (subgroupId) duplicatedCollector.subgroupId = subgroupId;
        duplicatedCollector.id = collectorId;
        duplicatedCollector.reviewId = reviewId;
        duplicatedCollector.layoutId = layoutId;

        const duplicatedPhoto = duplicatedCollector.photos.find(
          ({ photoIdx }) =>
            UploadResourceUtils.getDuplicatedPhotoIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              subgroupId: duplicatedSubgroupName,
              collectorId: duplicatedCollectorName,
              duplicatedPhotoName,
            }) === photoIdx
        );

        if (duplicatedPhoto) {
          duplicatedPhoto.id = photoId;
          duplicatedPhoto.layoutPhotoId = layoutPhotoId;
        }

        const updateReviewIdx = updateReviews.findIndex(
          ({ reviewIdx }) =>
            this.getDuplicatedReviewIdx({
              orderId,
              serviceId,
              serviceTaskId,
              duplicatedReviewName,
            }) === reviewIdx
        );

        if (updateReviewIdx === -1)
          updateReviews.push({
            ...duplicatedReview,
            collectors: [duplicatedCollector],
          });
        else {
          const updateCollectorIdx = updateReviews[
            updateReviewIdx
          ].collectors.findIndex(
            ({ groupIdx, subgroupIdx, collectorIdx }) =>
              this.getDuplicatedGroupIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                duplicatedGroupName,
              }) === groupIdx &&
              this.getDuplicatedSubgroupIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                groupId: duplicatedGroupName,
                duplicatedSubgroupName,
              }) === subgroupIdx &&
              this.getDuplicatedCollectorIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                groupId: duplicatedGroupName,
                subgroupId: duplicatedSubgroupName,
                duplicatedCollectorName,
              }) === collectorIdx
          );

          if (updateCollectorIdx === -1)
            updateReviews[updateReviewIdx].collectors.push({
              ...duplicatedCollector,
              photos: duplicatedPhoto ? [duplicatedPhoto] : [],
            });
          else {
            if (duplicatedPhoto) {
              const updatePhotoIdx = updateReviews[updateReviewIdx].collectors[
                updateCollectorIdx
              ].photos.findIndex(
                ({ photoIdx }) =>
                  UploadResourceUtils.getDuplicatedPhotoIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    subgroupId: duplicatedSubgroupName,
                    collectorId: duplicatedCollectorName,
                    duplicatedPhotoName,
                  }) === photoIdx
              );

              if (updatePhotoIdx === -1)
                updateReviews[updateReviewIdx].collectors[
                  updateCollectorIdx
                ].photos.push(duplicatedPhoto);
              else
                updateReviews[updateReviewIdx].collectors[
                  updateCollectorIdx
                ].photos[updatePhotoIdx] = duplicatedPhoto;
            }
          }
        }
        return updateReviews;
      },
      []
    );
  }

  static async mergeDuplicatedCollectorLayouts({
    reviews,
    collectorLayouts: _collectorLayouts,
  }) {
    const collectorLayouts = (() => {
      try {
        if (!reviews) return this.checkArray(_collectorLayouts);

        return reviews.reduce((acc, r) => {
          r.collectors.forEach((c) => {
            acc.push({
              ...c,
              serviceTaskName: r.serviceTaskName,
              serviceTaskProps: r.serviceTaskProps,
              reviewIdx: r.reviewIdx,
              orderId: r.orderId,
              docId: r.docId,
            });
          });

          return acc;
        }, []);
      } catch (err) {
        SentryService.sendError(err);
        return [];
      }
    })();

    if (!collectorLayouts.length) return;

    return CollectorLayoutRepository.mergeDuplicatedCollectorLayouts(
      collectorLayouts
    );
  }

  static incrementCollectorSort(incrementedSortSequence, collectors) {
    return collectors.map((c) => {
      c.sort = (c.sort || Number(c.layoutId)) + incrementedSortSequence;
      return c;
    });
  }
  //Mutate Offline autoFillCollector
  static mutateOfflineAutoFillCollector(autoFillCollector) {
    if (typeof autoFillCollector !== "object") return;
    idbHandler.setAutoFillCollector(autoFillCollector);
  }
  static updateCollectorValue({ value, collectorProps }) {
    return {
      ...collectorProps,
      value,
      status: COLLECTOR_KEYWORDS.COLLECTORS.STATUS.LOADING,
      createdAt: new Date().toISOString(),
    };
  }
  //Change collector value
  static async onCollectorValueChange({ value, collectorProps }) {
    if (value === null) return;

    const collectorValue = this.updateCollectorValue({ value, collectorProps });

    if (value === undefined) {
      await CollectorValueRepository.delete(collectorProps.id);
    } else {
      await CollectorValueRepository.put(collectorValue);
    }
    return collectorValue;
  }
  static setDeleteCollectorValue() {
    return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_VALUE;
  }
  //Set auto fill service task collectors
  static async setAutoFillServiceTaskCollectors({
    order,
    collectorLayout,
    serviceTaskId,
    process,
    active,
  }) {
    const autoFillProcess = (savedCollector, autoFillValue) => {
      let value = autoFillValue;
      if (active) {
        //Only replace nullish collector values
        if (
          savedCollector?.value !== undefined &&
          savedCollector?.value !==
            COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_VALUE
        ) {
          return { mustReturn: true };
        }
      } else {
        //Only replace na collector values
        if (savedCollector?.value !== autoFillValue) {
          return { mustReturn: true };
        }
        value = this.setDeleteCollectorValue();
      }

      return { value };
    };

    //Select AutoFill Value
    const autoFillValue = {
      [COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.NA]:
        COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE,
      [COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.DUP]:
        COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.UNDEFINED_VALUE,
    }[process];

    if (!autoFillValue) return [];

    const collectorPropList = collectorLayout
      .filter((review) => review.serviceTaskId === serviceTaskId)
      .reduce((acc, review) => [...acc, ...review.collectors], [])
      .map((collector) => this.getCollectorProps(order, collector));

    const collectorValues = await CollectorValueRepository.bulkGet(
      collectorPropList.map((cp) => cp.id)
    );

    return collectorPropList.reduce((acc, collectorProps) => {
      const savedCollector = collectorValues.find(
        (cv) => cv.id === collectorProps.id
      );
      const { value, mustReturn } = autoFillProcess(
        savedCollector,
        autoFillValue
      );
      if (mustReturn) return acc;

      const updatedCollectorValue = this.updateCollectorValue({
        value,
        collectorProps,
      });
      acc.push(updatedCollectorValue);

      return acc;
    }, []);
  }
  //Delete duplicated collector values
  static deleteDuplicatedCollectorValues(
    level,
    { collectorId, subgroupId, groupId, reviewId, serviceTaskId },
    order,
    filteredCollectorLayout,
    uploadResource
  ) {
    const validation = (collector) => {
      return {
        [DUPLICATION.LEVELS.REVIEW]: collector.reviewId === reviewId,
        [DUPLICATION.LEVELS.GROUP]: collector.groupId === groupId,
        [DUPLICATION.LEVELS.SUBGROUP]: collector.subgroupId === subgroupId,
        [DUPLICATION.LEVELS.COLLECTOR]: collector.id === collectorId,
      }[level];
    };

    const value = this.setDeleteCollectorValue();
    return filteredCollectorLayout
      .filter(
        (review) =>
          review.serviceTaskId === serviceTaskId && review.id === reviewId
      )
      .reduce((acc, review) => [...acc, ...review.collectors], []) //Get flatten collectors
      .filter(validation)
      .reduce((acc, collector) => {
        const collectorProps = this.getCollectorProps(order, collector);
        const collectorValue = this.updateCollectorValue({
          value,
          collectorProps,
        });
        acc.push(collectorValue);
        collector.photos.forEach((photo) => {
          //FileProps
          const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
            { order_id: order.order_id, docId: order.docId },
            collector,
            photo
          );
          uploadResource(UploadResourceUtils.addFileResource({ fileProps }));
        });
        return acc;
      }, []);
  }
  //Delete collector value
  static getToDeleteCollectorValue(order, collector, collectorValues) {
    const collectorProps = this.getCollectorProps(order, collector);
    const savedCollectorIdx = this.getCollectorIdxFromCollectorValues(
      collectorValues,
      collectorProps
    );
    const savedCollector = this.getCollectorValueFromIdx(
      collectorValues,
      savedCollectorIdx
    );
    const value = this.setDeleteCollectorValue();
    if (
      savedCollector?.value === undefined ||
      savedCollector?.value === null ||
      savedCollector?.value === value
    )
      return;
    return { value, collectorProps };
  }

  //Enter to collector ecosystem
  static enterToCollectorEcosystem({
    orderId,
    mutate1ObjectInCollector,
    updateCollectorRequiredValidation,
    forceReadOnlyCollector,
  }) {
    //Update Review Manage - orderId
    mutate1ObjectInCollector("reviewManage", {
      orderId,
      forceReadOnlyCollector,
    });
    //Update collector required validation
    updateCollectorRequiredValidation({
      highlight: {},
      state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING,
    });
  }
  //Left from collector ecosystem
  static leftFromCollectorEcosystem(doReset) {
    AssetRepository.removeSuccess();
    doReset();
  }

  //HANDLERS
  //Handle on click confirm send report
  static handleOnClickConfirmSendReport({
    profile,
    order,
    docId,
    filteredCollectorLayout,
    collectorValues,
    resources,
    updateCollectorRequiredValidation,
    mutate1ObjectInCollector,
    completeAuditedReport,
    sendToast,
  }) {
    //Collector photo config
    const collectorPhotoConfig = this.getCollectorPhotoConfig(order, profile);
    if (
      this.checkIsCollectorAndResourceRequiredDataPending({
        isActiveDataValidator: collectorPhotoConfig.required,
        validateData: {
          order: {
            order_id: order.order_id,
            docId,
          },
          filteredCollectorLayout,
          collectorValues,
          resources,
        },
        updateCollectorRequiredValidation,
        sendToast,
      })
    ) {
      mutate1ObjectInCollector("reviewManage", {
        showSendConfirmationModal: false,
        sendingReport: true,
      });
      completeAuditedReport(order.order_id);
    }
  }
  //Handle on set auto fill service task collectors
  static async handleOnSetAutoFillServiceTaskCollectors(
    order,
    docId,
    collectorLayout,
    autoFillCollector,
    mutate1ObjectInCollector,
    setAutoSync
  ) {
    //Build new Auto Fill Collector
    const newAutoFillCollector = {
      ...autoFillCollector,
      na: {
        ...autoFillCollector.na,
        [autoFillCollector.combineOrderAndServiceTaskId]:
          autoFillCollector.isActive,
      },
    };
    //Get updated collector values
    const collectorValues = await this.setAutoFillServiceTaskCollectors({
      order: { order_id: order.order_id, docId },
      collectorLayout,
      serviceTaskId: newAutoFillCollector.serviceTaskId,
      process: COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.NA,
      active:
        newAutoFillCollector.na[
          newAutoFillCollector.combineOrderAndServiceTaskId
        ],
    });
    this.mutateOfflineAutoFillCollector({ na: { ...newAutoFillCollector.na } });
    mutate1ObjectInCollector("reviewManage", {
      autoFillCollector: {
        ...newAutoFillCollector,
        isOpenConfirmation: false,
        serviceTaskId: undefined,
        combineOrderAndServiceTaskId: undefined,
        isActive: undefined,
      },
    });
    if (collectorValues.length) {
      await CollectorValueRepository.bulkPut(collectorValues);
      setAutoSync({ active: true });
    }
  }
  //Handle on cancel auto fill service task collectors
  static handleOnCancelAutoFillServiceTaskCollectors(
    autoFillCollector,
    mutate1ObjectInCollector
  ) {
    mutate1ObjectInCollector("reviewManage", {
      autoFillCollector: {
        ...autoFillCollector,
        isOpenConfirmation: false,
        serviceTaskId: undefined,
        combineOrderAndServiceTaskId: undefined,
        isActive: undefined,
      },
    });
  }

  static async handleOnConfirmDuplicateElement({
    level,
    newDuplicatedElements,
    incrementedSortSequence,
    setAutoSync,
  }) {
    //Increment sort
    if (incrementedSortSequence) {
      if (level !== DUPLICATION.LEVELS.PHOTO)
        newDuplicatedElements = this.incrementCollectorSort(
          incrementedSortSequence,
          newDuplicatedElements
        );

      newDuplicatedElements = UploadResourceUtils.incrementCollectorPhotoSort(
        incrementedSortSequence,
        newDuplicatedElements
      );
    }

    await this.mergeDuplicatedCollectorLayouts({
      collectorLayouts: newDuplicatedElements,
    });
    setAutoSync({ duplicatedLayoutIdsActive: true });
  }
  static async handleOnConfirmDeleteElementCollectorValues(
    level,
    deletedElement,
    filteredCollectorLayout,
    setAutoSync,
    uploadResource
  ) {
    //Get updated collector values
    const collectorValues = this.deleteDuplicatedCollectorValues(
      level,
      deletedElement,
      { docId: deletedElement.docId, order_id: deletedElement.orderId },
      filteredCollectorLayout,
      uploadResource
    );

    if (collectorValues.length) {
      await CollectorValueRepository.bulkPut(collectorValues);
      setAutoSync({ active: true });
    }
  }
  static async handleOnConfirmDeleteElement(
    level,
    deletedElement,
    onLoadSegmentedResources
  ) {
    if (level === DUPLICATION.LEVELS.PHOTO)
      return this.mergeDuplicatedCollectorLayouts({
        collectorLayouts: [{ ...deletedElement, photoLayoutDeleted: true }],
      });

    const newSegment = (() =>
      ({
        [DUPLICATION.LEVELS.REVIEW]: {
          reviewId: undefined,
          groupId: undefined,
          subgroupId: undefined,
        },
        [DUPLICATION.LEVELS.GROUP]: {
          groupId: undefined,
          subgroupId: undefined,
        },
        [DUPLICATION.LEVELS.SUBGROUP]: { subgroupId: undefined },
      }[level]))();

    if (newSegment) onLoadSegmentedResources(newSegment);

    return CollectorLayoutRepository.deleteDuplicatedCollectorLayout(
      deletedElement
    );
  }
}
