//Libs
import React from "react";
import { Card, Tooltip } from "antd";
//Utils
import { formatNumber } from "../../../../../../utils/libs";
//Styles
import "./PaymentLineResumeCard.css";

const { Meta } = Card;

export default function PaymentLineResumeCard(props) {
  const {
      id,
      line,
      order_id,
      odt_id,
      description,
      amount,
      tracts,
      apply_to_name,
      payment_id,
      action_label,
      creator_name,
      entity_name,
      created_at,
      updated_at,
      customProps,
    } = props,
    { coinSymbol, orders, getOrder, mutate1ObjectInOrders } = customProps,
    amountPrice = formatNumber.new(amount, coinSymbol, 2);

  return (
    <Meta
      id="payment_line-card-container"
      title={
        <div className="payment_line-body">
          <div className="payment_line-title">
            <div className="payment_line-title-line-creator-container">
              <Tooltip
                placement="top"
                className="payment_line-title-line"
                title={description}
              >
                <span>{`${line} #${tracts}`}</span>
              </Tooltip>
              <div
                style={{
                  height: "20px",
                  overflowX: "auto",
                  overflowY: "hidden",
                }}
              >
                <span className="payment_line-concept-item">Creado por:</span>
                <span className="payment_line-concept-value">
                  {creator_name} - {entity_name}
                </span>
              </div>
            </div>

            <div className="payment_line-price-action-container">
              <span className="payment_line-price">{amountPrice}</span>
              <span className="payment_line-action">{action_label}</span>
            </div>
          </div>

          <div className="payment_line-footer">
            <div>
              <div style={{ height: "18px" }}>
                <span className="payment_line-concept-item">Fecha:</span>
                <span className="payment_line-concept-value">{created_at}</span>
              </div>
              <div style={{ height: "18px" }}>
                <span className="payment_line-concept-item">Actualizado:</span>
                <span className="payment_line-concept-value">{updated_at}</span>
              </div>
              <div>
                <span className="payment_line-concept-item">Orden:</span>
                {order_id && (
                  <span
                    className="payment_line-order"
                    onClick={() => {
                      if (!orders.filter((ord) => ord.order_id === order_id)[0])
                        getOrder(order_id);
                      mutate1ObjectInOrders("getOrderInformationModal", {
                        order_id,
                        isOpen: true,
                      });
                    }}
                  >
                    {odt_id}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
