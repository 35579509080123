//Libs
import React from "react";
//Utils
import COLLECTOR_KEYWORDS from "./keywords";
//Components
import {
  Wrapper,
  CollectorUtils,
  Collapse as _Collapse,
  AddIcon,
  DeleteIcon,
} from "components/components";
import UnsubgroupedCollectors from "./UnsubgroupedCollectors";
import SubgroupedCollectors from "./SubgroupedCollectors";
import DynamicHeaderTitle from "./DynamicHeaderTitle";

const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;
const { Collapse, Panel } = _Collapse;

const GroupHeader = React.memo(
  ({
    isActive,
    group,
    isDuplicable,
    highlightRequired,
    selectedCurrentCollector,
    groupedCollectors,
    collectorValues,
    handleOnDuplicateElement,
    handleOnDeleteElement,
  }) => {
    const collectors = React.useMemo(
      () =>
        CollectorUtils.getGroupedCollectorsFromGroup(
          groupedCollectors,
          group.groupId
        ),
      [group.groupId, groupedCollectors]
    );
    return (
      <Wrapper padding="0" justifyContent="space-between">
        <DynamicHeaderTitle
          level={DUPLICATION.LEVELS.GROUP}
          highlightElement={group}
          defaultTitle={group.groupName}
          highlightRequired={highlightRequired}
          selectedCurrentCollector={selectedCurrentCollector}
          collectors={collectors}
          collectorValues={collectorValues}
        />
        {!isActive && isDuplicable && (
          <Wrapper padding="0">
            <Wrapper padding="0" margin="0 10px 0 0">
              <DeleteIcon
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnDeleteElement({
                    level: DUPLICATION.LEVELS.GROUP,
                    element: group, //Formatted duplicate element already
                  });
                }}
              />
            </Wrapper>

            <AddIcon
              onClick={(e) => {
                e.stopPropagation();
                handleOnDuplicateElement({
                  level: DUPLICATION.LEVELS.GROUP,
                  element: group, //Formatted duplicate element already
                });
              }}
            />
          </Wrapper>
        )}
      </Wrapper>
    );
  }
);

const Group = React.memo(
  ({
    segment,
    profile,
    order,
    docId,
    readOnlyCollector,
    review,
    group,
    photoCount,
    groupedCollectors,
    highlightRequired,
    selectedCurrentCollector,
    isPhotoBackupActive,
    isSynapseCameraActive,
    filteredCollectorLayout,
    collectorValues,
    resources,
    collectorEcosystemAutoSyncRunning,
    handleOnDuplicateElement,
    handleOnDeleteElement,
    onLoadSegmentedResources,
  }) => {
    const collectors = React.useMemo(
      () =>
        CollectorUtils.getGroupedCollectorsFromGroup(
          groupedCollectors,
          group.groupId
        ),
      [group.groupId, groupedCollectors]
    );

    const [unsubgroupedCollectors, subgroupedCollectors] = React.useMemo(
      () => [
        CollectorUtils.getUnsubgroupedCollectors(collectors),
        CollectorUtils.getSubgroupedCollectors(collectors),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [collectors.length, photoCount, groupedCollectors]
    );

    const subgroups = React.useMemo(
      () =>
        CollectorUtils.getGroupsFromGroupedCollectors(
          DUPLICATION.LEVELS.SUBGROUP,
          {
            docId,
            orderId: order.order_id,
            review: {
              name: review.name,
              serviceId: review.serviceId,
            },
          },
          subgroupedCollectors
        ),
      [
        docId,
        order.order_id,
        review.name,
        review.serviceId,
        subgroupedCollectors,
      ]
    );

    return (
      <>
        {/* UNSUBGROUPED COLLECTORS */}
        <UnsubgroupedCollectors
          profile={profile}
          order={order}
          docId={docId}
          unsubgroupedCollectors={unsubgroupedCollectors}
          readOnlyCollector={readOnlyCollector}
          highlightRequired={highlightRequired}
          selectedCurrentCollector={selectedCurrentCollector}
          isPhotoBackupActive={isPhotoBackupActive}
          isSynapseCameraActive={isSynapseCameraActive}
          filteredCollectorLayout={filteredCollectorLayout}
          collectorValues={collectorValues}
          resources={resources}
          collectorEcosystemAutoSyncRunning={collectorEcosystemAutoSyncRunning}
          handleOnDuplicateElement={handleOnDuplicateElement}
          handleOnDeleteElement={handleOnDeleteElement}
        />

        {/* SUBGROUPED COLLECTORS */}
        {!!subgroups.length && (
          <SubgroupedCollectors
            segment={segment}
            profile={profile}
            order={order}
            docId={docId}
            readOnlyCollector={readOnlyCollector}
            review={review}
            subgroups={subgroups}
            subgroupedCollectors={subgroupedCollectors}
            highlightRequired={highlightRequired}
            selectedCurrentCollector={selectedCurrentCollector}
            isPhotoBackupActive={isPhotoBackupActive}
            isSynapseCameraActive={isSynapseCameraActive}
            filteredCollectorLayout={filteredCollectorLayout}
            collectorValues={collectorValues}
            resources={resources}
            collectorEcosystemAutoSyncRunning={
              collectorEcosystemAutoSyncRunning
            }
            handleOnDuplicateElement={handleOnDuplicateElement}
            handleOnDeleteElement={handleOnDeleteElement}
            onLoadSegmentedResources={onLoadSegmentedResources}
          />
        )}
      </>
    );
  }
);

const GroupedCollectors = React.memo(
  ({
    segment,
    profile,
    order,
    docId,
    readOnlyCollector,
    review,
    groups,
    groupedCollectors,
    photoCount,
    highlightRequired,
    selectedCurrentCollector,
    isPhotoBackupActive,
    isSynapseCameraActive,
    filteredCollectorLayout,
    collectorValues,
    resources,
    collectorEcosystemAutoSyncRunning,
    handleOnDuplicateElement,
    handleOnDeleteElement,
    onLoadSegmentedResources,
  }) => {
    const duplicatedGroupLastNames = React.useMemo(
      () =>
        groups.reduce((acc, group) => {
          //Get current last element
          const { originalElementName, lastSequenceNumber } =
            CollectorUtils.getCurrentLastElement(
              DUPLICATION.LEVELS.GROUP,
              group.groupName,
              groupedCollectors,
              {
                docId,
                orderId: order.order_id,
                review: {
                  name: review.name,
                  serviceId: review.serviceId,
                },
              }
            );
          if (!originalElementName || !lastSequenceNumber) return acc;

          acc[`${originalElementName} #${lastSequenceNumber}`] = true;
          return acc;
        }, {}),
      [
        docId,
        order.order_id,
        review.name,
        review.serviceId,
        groups,
        groupedCollectors,
      ]
    );

    const setGroupKey = (group) =>
      CollectorUtils.checkDuplicable(group.groupId)
        ? group.groupName
        : group.groupId;

    const setActiveKey = (segment) =>
      CollectorUtils.checkDuplicable(segment.groupId)
        ? segment.groupName
        : segment.groupId;

    return (
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={["-1"]}
        activeKey={setActiveKey(segment)}
        destroyInactivePanel={true}
        onChange={(activeKey) => {
          const segment = {
            groupId: activeKey,
            groupName: undefined,
            subgroupId: undefined,
          };
          if (!activeKey) return onLoadSegmentedResources(segment);

          const isDuplicable = CollectorUtils.checkDuplicable(activeKey);
          if (!isDuplicable) return onLoadSegmentedResources(segment);

          const group = groups.find((g) => g.groupName === activeKey);
          segment.groupId = group?.groupId || activeKey;
          segment.groupName = group?.groupName;

          onLoadSegmentedResources(segment);
        }}
      >
        {groups.map((group) => (
          <Panel
            key={setGroupKey(group)}
            style={{
              padding: "8px 8px 0 8px",
              margin: "0 0 8px 0",
              boxShadow: "rgb(0 0 0 / 35%) 0px 5px 15px",
            }}
            header={
              <GroupHeader
                // eslint-disable-next-line eqeqeq
                isActive={setGroupKey(group) == setActiveKey(segment)}
                group={group}
                isDuplicable={duplicatedGroupLastNames[group.groupName]}
                highlightRequired={highlightRequired}
                selectedCurrentCollector={selectedCurrentCollector}
                groupedCollectors={groupedCollectors}
                collectorValues={collectorValues}
                handleOnDuplicateElement={handleOnDuplicateElement}
                handleOnDeleteElement={handleOnDeleteElement}
              />
            }
          >
            <Group
              segment={segment}
              profile={profile}
              order={order}
              docId={docId}
              readOnlyCollector={readOnlyCollector}
              review={review}
              group={group}
              photoCount={photoCount}
              groupedCollectors={groupedCollectors}
              highlightRequired={highlightRequired}
              selectedCurrentCollector={selectedCurrentCollector}
              isPhotoBackupActive={isPhotoBackupActive}
              isSynapseCameraActive={isSynapseCameraActive}
              filteredCollectorLayout={filteredCollectorLayout}
              collectorValues={collectorValues}
              resources={resources}
              collectorEcosystemAutoSyncRunning={
                collectorEcosystemAutoSyncRunning
              }
              handleOnDuplicateElement={handleOnDuplicateElement}
              handleOnDeleteElement={handleOnDeleteElement}
              onLoadSegmentedResources={onLoadSegmentedResources}
            />
          </Panel>
        ))}
      </Collapse>
    );
  }
);

export default GroupedCollectors;
