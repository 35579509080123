//Libs
import Immutable from "immutable";
import { PhotoUtils } from "utils/libs";
//Utils
import GENERAL from "utils/constants/general";
import { CollectorUtils } from "../Collector";
import { formatDate } from "utils/libs/dateFormats";
//Keywords
import COLLECTOR_KEYWORDS from "components/components/Collector/keywords";
import { AssetRepository } from "core/database";
import { SerializedAsset } from "core/database/Assets/models";

const { ENV } = GENERAL;
const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;

export default class UploadResourceUtils {
  //Check is array
  static checkArray(arr) {
    return Array.isArray(arr) ? arr : [];
  }
  //Select and include resource by your fileProps
  static selectResourceByProps(r, fileProps) {
    return r.id === fileProps.id;
  }
  static async mergeAssets(newResources, { format } = {}) {
    if (format === "beforeMatch")
      return AssetRepository.merge(this.getFormattedResources(newResources));

    //The purpose of this formatting is mainly to update the value of the "id" prop
    if (!format || format === "afterMatch")
      return AssetRepository.merge(
        this.getFormattedResources(newResources, ["id"])
      );
  }
  //Get Photo config
  static getPhotoConfig(config = {}) {
    const {
      ACCEPT,
      GET_COORDS,
      CAPTURE,
      COMPRESS: ZIP,
      VERSION,
    } = ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.KEYS;
    const { STRATEGIES } = ENV.UPLOAD_RESOURCE.COLLECTOR_PHOTO.COMPRESS;

    //Compress config
    const compress = config[ZIP.NAME] ?? {};
    //Active compress?
    const isPicaStrategy = compress[ZIP.STRATEGY] === STRATEGIES.PICA;
    const isPhotonStrategy = compress[ZIP.STRATEGY] === STRATEGIES.PHOTON;
    const isResampleStrategy = compress[ZIP.STRATEGY] === STRATEGIES.RESAMPLE;
    const samplingFilter = compress[ZIP.SAMPLING_FILTER];
    //Width
    const width = compress[ZIP.WIDTH];
    //Height
    const height = compress[ZIP.HEIGHT];
    //CompressFormat
    const compressFormat = compress[ZIP.FORMAT] ?? "image/jpeg";
    //Accept
    const accept = compress[ACCEPT] ?? "image/*";
    //Quality
    const quality = compress[ZIP.QUALITY] ?? 0.8;
    //Must get coords
    const getCoords = config[GET_COORDS];
    //Capture
    const capture = config[CAPTURE];
    const version = config[VERSION];

    return {
      width,
      height,
      isPicaStrategy,
      isPhotonStrategy,
      samplingFilter,
      isResampleStrategy,
      compressFormat,
      quality,
      getCoords,
      capture,
      accept,
      version,
    };
  }
  //Get resource index from resources array
  static getResourceIdxFromResources(resources = [], fileProps) {
    if (!fileProps || !fileProps.resourceType) return -1;

    if (
      fileProps.resourceType ===
      ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO
    ) {
      return resources.findIndex((r) =>
        this.selectResourceByProps(r, fileProps)
      );
    }
  }
  //Get dynamic and configured api url by resource type validation
  static getDynamicApiUrlByResourceType(resourceType) {
    if (resourceType === ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO) {
      return `/collector_values/v2/saveAuditedOrderPhoto`;
    }
    return null;
  }
  //Get status of a resource
  static getStatus(status) {
    return {
      isLoading: status === ENV.UPLOAD_RESOURCE.STATUS.LOADING,
      isError: status === ENV.UPLOAD_RESOURCE.STATUS.ERROR,
      isSuccess: status === ENV.UPLOAD_RESOURCE.STATUS.SUCCESS,
    };
  }

  static async loadSegment(segmentData) {
    const segmentedResources = await AssetRepository.loadSegment(segmentData);
    return segmentedResources.map((resource) => {
      delete resource.file;
      return resource;
    });
  }
  //Add file resource
  static addFileResource({ file, fileProps, coords }) {
    const _coords = coords || {};
    return {
      file,
      fileProps: {
        ...fileProps,
        coords: { ..._coords, createdAt: new Date().toISOString() },
        createdAt: new Date().toISOString(),
      },
    };
  }
  //Fetch remote image url
  static async getRemoteImageResource({ src, file, noCached }) {
    function buildNonCachedSrc(path) {
      return noCached ? `${path}?t=${new Date().getMilliseconds()}` : path;
    }
    //Check loaded image
    function checkImage(path) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve("ok");
        img.onerror = () => resolve("error");
        img.src = path;
      });
    }

    const thumbnailSrc = PhotoUtils.getThumbnail({
      src,
      folder: "photoCollectors",
      size: 70,
    });
    let imageSrc = buildNonCachedSrc(thumbnailSrc);
    let status = await checkImage(imageSrc);
    if (status === "error") {
      imageSrc = buildNonCachedSrc(src);
      status = await checkImage(imageSrc);
      if (status === "error") return file;
    }
    return imageSrc;
  }
  //Get duplicated photo level Idx
  static getDuplicatedPhotoIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    subgroupId,
    collectorId,
    duplicatedPhotoName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${subgroupId}collector${collectorId}photo${duplicatedPhotoName}`;
  }
  //Get collector resource fileProps object
  static getCollectorResourceFileProps(order, collector, photo) {
    return new SerializedAsset({
      ...collector,
      ...photo,
      orderId: collector.auditOrderId || order.order_id,
      docId: collector.docId || order.docId,
      photoId: photo.photoId || photo.id,
      photoName: photo.photoName || photo.name,
      collectorId: collector.collectorId || collector.id,
      collectorName: collector.collectorName || collector.name,
      rliSort: photo.rliSort || collector.sort,
      resourceType: ENV.UPLOAD_RESOURCE.RESOURCE_TYPES.COLLECTOR_PHOTO,
    });
  }
  //Is there a pending collector resource to complete order auditory?
  static async isRequiredCollectorResourcePending(order, collectors) {
    //I'm looking for a required resource that hasn't been completed in <resources>
    const unsuccessResources = await AssetRepository.unsuccessPrimaryKeys({
      auditOrderId: order.order_id,
      docId: order.docId,
    });
    return collectors.reduce((acc, collector) => {
      if (!Array.isArray(collector.photos) || !collector.photos.length)
        return acc;

      collector.photos.forEach((photo) => {
        if (!photo.required) return acc;

        const fileProps = this.getCollectorResourceFileProps(
          order,
          collector,
          photo
        );
        const unsuccessResourceIdx = this.getResourceIdxFromResources(
          unsuccessResources,
          fileProps
        );
        if (unsuccessResourceIdx === -1) return acc; // It's completed

        acc = {
          ...acc,
          ...CollectorUtils.setHighlightCollector({
            ...fileProps,
            id: fileProps.collectorId,
          }),
          [`id_${fileProps.id}`]: true,
        };
        return acc;
      });
      return acc;
    }, {});
  }
  //Get formatted resources
  static getFormattedResources(resources, onlyKeys) {
    return Immutable.List(resources)
      .toJS()
      .map((r) => {
        const fileProps = this.getCollectorResourceFileProps(
          { order_id: r.auditOrderId, docId: r.docId },
          r,
          r
        );
        if (!onlyKeys)
          return {
            ...r,
            ...fileProps,
            duplicatedId: r.id,
          };
        for (let key of onlyKeys) {
          r.duplicatedId = r.id;
          r[key] = fileProps[key];
        }
        return r;
      });
  }
  static getFormattedResourceFromCollectorValues(order, collectorValues) {
    return collectorValues
      .filter((cv) => !!cv.photos?.length)
      .reduce((acc, cv) => {
        const photos = cv.photos.map((photo) => {
          const fileProps = this.getCollectorResourceFileProps(
            order,
            cv,
            photo
          );
          return { ...photo, ...fileProps };
        });
        return [...acc, ...photos];
      }, []);
  }
  //Increment photo sort
  static incrementCollectorPhotoSort(incrementedSortSequence, collectors) {
    return collectors.map((c) => {
      c.photos = c.photos.map((p) => {
        p.sort = (p.sort || Number(p.layoutPhotoId)) + incrementedSortSequence;
        return p;
      });
      return c;
    });
  }
  //Get duplicated photo last names
  static getDuplicatedPhotoLastNames(photos) {
    return photos.reduce((acc, photo) => {
      //Get original element name (without sequencial number)
      const { originalElementName, lastSequenceNumber } =
        CollectorUtils.getCurrentLastElement(
          DUPLICATION.LEVELS.PHOTO,
          photo.name,
          photos
        );
      if (!originalElementName || !lastSequenceNumber) return acc;

      acc[`${originalElementName} #${lastSequenceNumber}`] = true;
      return acc;
    }, {});
  }
  //Download photo
  static downloadPhoto(filename, base64Image) {
    try {
      var a = document.createElement("a"); //Create <a>
      a.href = base64Image; //Image Base64 Goes here
      a.download = `${filename}.jpg`; //File name Here
      a.click(); //Downloaded file
    } catch (err) {}
  }
  //Download unsuccess photos
  static async downloadUnsuccessResources(profile) {
    return AssetRepository.loadUnsuccess({ limit: null }).then(
      (unsuccessResources) =>
        unsuccessResources.forEach((resource) => {
          this.downloadPhoto(
            formatDate(resource.createdAt, profile, "YYYY-MM-DD.HH.mm.ss"),
            resource.file
          );
        })
    );
  }
}
