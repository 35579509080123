import CommonOperations from "core/database/CommonOperations";

export default class SerializedAsset {
  constructor(data) {
    this.id = CommonOperations.assetId(data);
    this.src = data.src;
    this.file = data.file;
    this.thumbnail = data.thumbnail;
    this.coords = data.coords;
    // Owned
    this.orderId = data.auditOrderId || data.orderId;
    this.auditOrderId = this.orderId; // TODO: REDUNDANT: Migrate and remove it when it's possible
    this.docId = data.docId || "null";
    // Template
    this.templateId = data.templateId;
    // Service
    this.serviceId = data.serviceId;
    // Service Task
    this.serviceTaskId = data.serviceTaskId;
    this.serviceTaskName = data.serviceTaskName;
    // Review
    this.reviewId = data.reviewId;
    this.reviewName = data.reviewName;
    // Group
    this.groupId = data.groupId;
    this.groupName = data.groupName;
    // Subgroup
    this.subgroupId = data.subgroupId;
    this.subgroupName = data.subgroupName;
    // Collector
    this.collectorId = data.collectorId;
    this.collectorName = data.collectorName;
    this.collectorValueId = data.collectorValueId;
    // Photo
    this.photoId = data.photoId;
    this.photoName = data.photoName;
    // Layout
    this.layoutId = data.layoutId;
    this.layoutPhotoId = data.layoutPhotoId;
    // Type
    this.typeKey = data.typeKey;
    this.typeProps = data.typeProps;
    this.resourceType = data.resourceType;
    // Unit
    this.unitKey = data.unitKey;
    this.unitName = data.unitName;
    // Metadata
    this.duplicated = data.duplicated;
    this.duplicatedId = data.duplicatedId; //TODO: Experimentación
    this.required = data.required;
    this.sort = data.sort;
    this.rliSort = data.rliSort;
    this.creator = data.creator;
    this.entity = data.entity;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.photoDeleted = data.photoDeleted;
    this.collectorIdx = data.collectorIdx; //TODO: Pending to delete
    this.subgroupIdx = data.subgroupIdx; //TODO: Pending to delete
    this.groupIdx = data.groupIdx; //TODO: Pending to delete
    this.reviewIdx = data.reviewIdx; //TODO: Pending to delete
  }
}
