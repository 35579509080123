const deserializeNullValue = (value) => (value === "null" ? null : value);

export default class DeserializedCollectorLayout {
  constructor(cl) {
    Object.assign(this, cl);
    this.id = cl.collectorId;
    this.name = cl.collectorName;
    this.orderId = deserializeNullValue(cl.orderId);
    this.docId = deserializeNullValue(cl.docId);
    this.layoutId = deserializeNullValue(cl.layoutId);
    this.duplicated = cl.duplicated === "true";
    this.hasUnsuccessPhotoLayouts = cl.hasUnsuccessPhotoLayouts === "true";
    this.inventoryFilter = cl.inventoryFilter || [];
    this.listValues = cl.listValues || [];
    this.photos = cl.photos || [];
  }
}
