import CommonOperations from "core/database/CommonOperations";

export default class AdaptCollectorLayoutsToReviews {
  constructor(collectorLayouts) {
    return this.run(collectorLayouts);
  }

  run(collectorLayouts) {
    const reviews = collectorLayouts.reduce((reviews, c) => {
      const review = `${c.templateId}/${c.serviceId}/${c.serviceTaskId}/${c.reviewId}`;

      if (!reviews[review]) {
        reviews[review] = {
          id: c.reviewId,
          name: c.reviewName,
          serviceId: c.serviceId,
          serviceTaskId: c.serviceTaskId,
          serviceTaskName: c.serviceTaskName,
          serviceTaskProps: c.serviceTaskProps,
          reviewIdx: c.reviewIdx,
          orderId: c.orderId,
          docId: c.docId,
          templateId: c.templateId,
          duplicated: c.duplicated === "true",
          collectors: [c],
          sort: c.sort,
        };
        return reviews;
      }

      const collectorIdx = reviews[review].collectors.findIndex(
        (_c) =>
          _c.id === c.id &&
          _c.subgroupId === c.subgroupId &&
          _c.groupId === c.groupId &&
          _c.reviewId === c.reviewId
      );
      if (collectorIdx === -1) {
        reviews[review].collectors.push(c);
        reviews[review].collectors.sort(CommonOperations.sortElements);
        reviews[review].sort = Math.max(
          ...reviews[review].collectors.map((c) => c.sort || 0)
        );
        return reviews;
      }

      c.photos.forEach((p) => {
        const photoExists = !!reviews[review].collectors[
          collectorIdx
        ].photos.find((_p) => _p.id === p.id);

        if (!photoExists) {
          reviews[review].collectors[collectorIdx].photos.push(p);
          reviews[review].collectors[collectorIdx].photos.sort(
            CommonOperations.sortElements
          );
        }
      });

      return reviews;
    }, {});

    return Object.values(reviews)
      .reduce((acc, value) => [...acc, value], [])
      .sort(CommonOperations.sortElements);
  }
}
