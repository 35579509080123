//Libs
import React from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "styled-components";
//Keywords
import COLLECTOR_KEYWORDS from "./keywords";
//Utils
import { getGeolocation } from "components/CoordManager/actions";
import { filterOption } from "utils/libs";
import GENERAL from "utils/constants/general";
import {
  GlobalUtils,
  ProjectUtils,
  ServiceUtils,
  OrderUtils,
  UserUtils,
} from "utils";
import { formatDate } from "utils/libs/dateFormats";
import CheckUtils from "./CheckUtils";
import { CollectorDynamics } from "components/components/Collector/utils";
import { CollectorEcosystemIntegration } from "../ConsumeInventory";
//Actions
import { sendToast as _sendToast } from "components/containers/Dashboard/actions";
import { mutate1Object } from "./actions";
//Selectors
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
//Hooks
import { useCollectorApi, useCollectorValues } from "hooks";
//Components
import InputMask from "react-input-mask";
import {
  Grid,
  Wrapper,
  Span,
  TextField,
  InputNumber,
  TimePicker,
  DatePicker,
  Switch,
  Icon,
  UploadResource,
  Select,
  InfoIcon,
  Tooltip,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  CollectorUtils,
  Popover,
  Subtitle,
  Title,
} from "components/components";
import { UploadIcon } from "components/components/Icons";
import LogoIcon from "../LogoIcon";
import DynamicHeaderTitle from "./DynamicHeaderTitle";

const { ENV } = GENERAL;
const { Input } = TextField;
const { Option } = Select;

const getInputProp = ({ collector, currentValue, prop }) => {
  if (prop === "placeholder") {
    return (
      (currentValue === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE
        ? COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE
        : undefined) ??
      collector.props?.placeholder ??
      (collector.required ? "Requerido" : "")
    );
  }
  if (prop === "defaultChecked")
    return collector.props?.defaultChecked ?? false;

  return collector.props?.[prop];
};

const WithPhotos = React.memo(
  ({
    profile,
    collectorTypeKey,
    order,
    docId,
    collector,
    highlightRequired,
    readOnlyCollector,
    isPhotoBackupActive,
    isSynapseCameraActive,
    resources,
    handleOnDuplicateElement,
    handleOnDeleteElement,
  }) => {
    if (!CollectorUtils.checkCollectorPhotosValid(collector)) return null;

    const duplicatedPhotoLastNames =
      UploadResourceUtils.getDuplicatedPhotoLastNames(collector.photos);

    return (
      <Grid
        margin={
          collectorTypeKey !== COLLECTOR_KEYWORDS.COLLECTORS.TYPES.VOID
            ? "5px 0 0 0"
            : 0
        }
        width="100%"
        overflowX="auto"
        className="animated fadeIn"
        gridAutoFlow="row"
        columns="repeat(auto-fit, minmax(70px, 70px))"
        gap=".3em"
      >
        {collector.photos.map((photo, idx) => {
          //Collector photo config
          const authConfig = UploadResourceUtils.getPhotoConfig(
            GlobalUtils.selectCurrentProps([
              ProjectUtils.getProjectPropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
              ServiceUtils.getServicePropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
              UserUtils.getUserPropsFromProfile(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                profile
              ),
              CollectorUtils.getCollectorPhotoProps(photo),
              OrderUtils.getOrderPropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
            ])
          );
          //FileProps
          const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
            { order_id: order.order_id, docId },
            collector,
            photo
          );

          return (
            <Wrapper key={idx} padding="0" flexDirection="column">
              <UploadResource
                profile={profile}
                isDuplicable={duplicatedPhotoLastNames[photo.name]}
                authConfig={authConfig}
                fileProps={fileProps}
                options={{
                  showActions: !readOnlyCollector,
                  label: photo.name,
                  hideLabel: photo.hideLabel,
                  highlighLabel: !!highlightRequired[`id_${fileProps.id}`],
                  isPhotoBackupActive,
                  isSynapseCameraActive,
                }}
                resources={resources}
                handleOnDuplicateElement={handleOnDuplicateElement}
                handleOnDeleteElement={handleOnDeleteElement}
              />
            </Wrapper>
          );
        })}
      </Grid>
    );
  }
);

const WithSignerCanvas = React.memo(
  ({
    profile,
    order,
    docId,
    collector,
    readOnlyCollector,
    highlightRequired,
    resources,
  }) => {
    if (!CollectorUtils.checkCollectorPhotosValid(collector)) {
      return null;
    }
    const photo = collector.photos[0];

    //Collector photo config
    const authConfig = UploadResourceUtils.getPhotoConfig(
      GlobalUtils.selectCurrentProps([
        ProjectUtils.getProjectPropsFromOrder(
          ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
          order
        ),
        ServiceUtils.getServicePropsFromOrder(
          ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
          order
        ),
        UserUtils.getUserPropsFromProfile(
          ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
          profile
        ),
        CollectorUtils.getCollectorPhotoProps(photo),
        OrderUtils.getOrderPropsFromOrder(
          ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
          order
        ),
      ])
    );

    //FileProps
    const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
      { order_id: order.order_id, docId },
      collector,
      photo
    );

    return (
      <UploadResource
        authConfig={authConfig}
        fileProps={{
          ...fileProps,
          signercanvas:
            collector.typeKey ===
            COLLECTOR_KEYWORDS.COLLECTORS.TYPES.SIGNER_CANVAS,
        }}
        options={{
          showActions: !readOnlyCollector,
          label: photo.name,
          hideLabel: photo.hideLabel,
          highlighLabel: !!highlightRequired[`id_${fileProps.id}`],
        }}
        resources={resources}
      />
    );
  }
);

const String = React.memo(
  ({ collector, currentValue, onFocus, onBlur, onChangeState }) => (
    <Input
      width="100%"
      value={CollectorUtils.getCollectorValue({
        value: currentValue,
        typeKey: collector.typeKey,
      })}
      placeholder={getInputProp({
        collector,
        currentValue,
        prop: "placeholder",
      })}
      onChange={(e) => onChangeState(e.target.value)}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
);

const Number = React.memo(
  ({ collector, currentValue, onFocus, onChangeState }) => {
    const mask = getInputProp({ collector, prop: "mask" });

    if (mask)
      return (
        <InputMask
          style={{
            width: "100%",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            padding: "4px 11px",
          }}
          mask={mask}
          value={
            CollectorUtils.getCollectorValue({
              value: currentValue,
              typeKey: collector.typeKey,
            }) || ""
          }
          onChange={(e) => {
            e.persist();
            let value = e.target.value;
            value = value.replace(/\D/g, ""); //Get only numbers
            onChangeState(value);
          }}
          onFocus={onFocus}
        />
      );

    return (
      <InputNumber
        width="100%"
        type="number"
        value={CollectorUtils.getCollectorValue({
          value: currentValue,
          typeKey: collector.typeKey,
        })}
        placeholder={getInputProp({
          collector,
          currentValue,
          prop: "placeholder",
        })}
        step={getInputProp({ collector, prop: "step" })}
        min={getInputProp({ collector, prop: "min" })}
        max={getInputProp({ collector, prop: "max" })}
        onFocus={onFocus}
        onChange={onChangeState}
      />
    );
  }
);

const Time = React.memo(
  ({ collector, currentValue, onFocus, onChangeState }) => (
    <TimePicker
      width="100%"
      allowClear={!collector.required}
      placeholder={getInputProp({
        collector,
        currentValue,
        prop: "placeholder",
      })}
      value={CollectorUtils.getCollectorValue({
        value: currentValue,
        typeKey: collector.typeKey,
      })}
      onFocus={onFocus}
      onChange={(date) => onChangeState(date.utc().format())}
    />
  )
);

const DateType = React.memo(
  ({ collector, currentValue, onFocus, onChangeState }) => (
    <DatePicker
      width="100%"
      allowClear={!collector.required}
      placeholder={getInputProp({
        collector,
        currentValue,
        prop: "placeholder",
      })}
      value={CollectorUtils.getCollectorValue({
        value: currentValue,
        typeKey: collector.typeKey,
      })}
      onFocus={onFocus}
      onChange={(date) => onChangeState(date.utc().format())}
    />
  )
);

const Datetime = React.memo(
  ({ collector, currentValue, onFocus, onChangeState }) => (
    <Wrapper padding="0">
      <TimePicker
        width="100%"
        allowClear={!collector.required}
        placeholder={getInputProp({
          collector,
          currentValue,
          prop: "placeholder",
        })}
        value={CollectorUtils.getCollectorValue({
          value: currentValue,
          typeKey: collector.typeKey,
        })}
        onFocus={onFocus}
        onChange={(date) => onChangeState(date.utc().format())}
      />
      <DatePicker
        width="100%"
        allowClear={!collector.required}
        placeholder={getInputProp({
          collector,
          currentValue,
          prop: "placeholder",
        })}
        value={CollectorUtils.getCollectorValue({
          value: currentValue,
          typeKey: collector.typeKey,
        })}
        onFocus={onFocus}
        onChange={(date) => onChangeState(date.utc().format())}
      />
    </Wrapper>
  )
);

const Boolean = React.memo(
  ({ collector, currentValue, onFocus, onChangeState }) => (
    <Switch
      size="small"
      checked={CollectorUtils.getCollectorValue({
        value: currentValue,
        typeKey: collector.typeKey,
      })}
      defaultChecked={getInputProp({ collector, prop: "defaultChecked" })}
      checkedChildren={<Icon type="check" />}
      unCheckedChildren={<Icon type="close" />}
      onFocus={onFocus}
      onChange={onChangeState}
    />
  )
);

const List = React.memo(
  ({
    collector,
    currentValue,
    collectorValues,
    filteredCollectorLayout,
    onFocus,
    onChangeState,
  }) => {
    const listValues = React.useMemo(
      () =>
        collector.listValues.reduce((acc, listValue) => {
          if (!listValue.filters?.length) {
            acc.push(listValue);
            return acc;
          }
          const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
            listValue.filters,
            { collectorLayout: filteredCollectorLayout }
          );
          const replacedReferences =
            CollectorDynamics.replaceDuplicatedReferences(
              absoluteReferences,
              collector
            );
          const referencedFilter = CollectorDynamics.getReferencedFilter(
            replacedReferences,
            collector
          );
          const referencedCollectorValue =
            CollectorDynamics.getReferencedCollectorValue(
              referencedFilter,
              collectorValues
            );
          const checkedlistValue = CollectorDynamics.checkFilteredCollector(
            referencedFilter,
            referencedCollectorValue,
            filteredCollectorLayout
          );
          if (!checkedlistValue) return acc;
          return [...acc, listValue];
        }, []),
      [collector, filteredCollectorLayout, collectorValues]
    );

    return (
      <Select
        width="100%"
        showSearch
        placeholder="Seleccione un valor"
        value={CollectorUtils.getCollectorValue({
          value: currentValue,
          typeKey: collector.typeKey,
        })}
        filterOption={filterOption}
        onChange={onChangeState}
        onFocus={onFocus}
      >
        <Option key="delete" value={""}>
          Seleccionar
        </Option>
        {listValues.map(({ id, name }) => (
          <Option key={id} value={name}>
            {name}
          </Option>
        ))}
      </Select>
    );
  }
);

const CollectorName = React.memo(
  ({
    collector,
    collectorProps,
    highlightRequired,
    selectedCurrentCollector,
  }) => {
    return (
      <Wrapper padding="0">
        <DynamicHeaderTitle
          level={COLLECTOR_KEYWORDS.COLLECTORS.DUPLICATION.LEVELS.COLLECTOR}
          highlightElement={collectorProps}
          defaultTitle={collector.name}
          highlightRequired={highlightRequired}
          selectedCurrentCollector={selectedCurrentCollector}
          customStyle={{ margin: "0 2px 0 0" }}
        />
        {collector.description && (
          <Tooltip title={collector.description}>
            <div>
              <InfoIcon size="small" />
            </div>
          </Tooltip>
        )}
      </Wrapper>
    );
  }
);

const CollectorValue = React.memo(
  ({
    collector,
    currentValue,
    collectorValues,
    filteredCollectorLayout,
    profile,
    theme,
  }) => {
    const { replacedReferences = [], referencedCollectors = [] } =
      React.useMemo(() => {
        if (!collector.layoutProps?.readOnly || !collector.autoCalc?.length)
          return {};
        const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
          collector.autoCalc,
          { collectorLayout: filteredCollectorLayout }
        );
        const replacedReferences =
          CollectorDynamics.replaceDuplicatedReferences(
            absoluteReferences,
            collector
          );
        const referencedCollectors = CollectorDynamics.getReferencedCollectors(
          replacedReferences,
          { collectorLayout: filteredCollectorLayout }
        );
        return { replacedReferences, referencedCollectors };
      }, [filteredCollectorLayout, collector.autoCalc]);

    const referencedCollectorValues = React.useMemo(
      () =>
        CollectorDynamics.getReferencedCollectorValues(
          replacedReferences,
          collectorValues
        ),
      [replacedReferences, collectorValues]
    );

    const autoCalculatedValue = React.useMemo(
      () =>
        CollectorDynamics.getAutoCalculatedCollectorValue(
          replacedReferences,
          referencedCollectors,
          referencedCollectorValues
        ),
      [replacedReferences, referencedCollectors, referencedCollectorValues]
    );

    const value = React.useMemo(
      () =>
        autoCalculatedValue ||
        CollectorUtils.getCollectorValue({
          value: currentValue,
          typeKey: collector.typeKey,
          readOnlyCollector: true,
          profile,
        }),
      [currentValue, autoCalculatedValue]
    );

    if (!value) return null;

    if (collector.typeKey === COLLECTOR_KEYWORDS.COLLECTORS.TYPES.COORDS) {
      return <CoordInformation value={value} profile={profile} theme={theme} />;
    }
    if (collector.typeKey === COLLECTOR_KEYWORDS.COLLECTORS.TYPES.FILE) {
      return <CheckIcon button onClick={() => window.open(value)} />;
    }

    return (
      <Span padding="0 0 0 10px" fontSize="m" color={theme.colors.text.high}>
        {value}
      </Span>
    );
  }
);

const AutoCalcHelperOverlay = ({
  autoCalculatedValue,
  referencedCollectors,
  referencedCollectorValues,
}) => {
  const getReferencedPath = (refCollector, idx) => {
    const {
      reviewName,
      groupName,
      subgroupName,
      name: collectorName,
    } = refCollector;

    const referencedItemPath = `${reviewName} ${
      !!groupName ? ` / ${groupName}` : ""
    } ${!!subgroupName ? ` / ${subgroupName}` : ""} / ${collectorName}`;

    const referencedCollectorValue = referencedCollectorValues.find((rcv) =>
      CollectorDynamics.getReferencedCollector(refCollector, rcv)
    )?.value;

    return (
      <Wrapper
        key={`${idx}`}
        padding="0"
        width="100%"
        alignItems="baseline"
        margin="0 0 10px 0"
      >
        <Subtitle margin="0 3px">{idx})</Subtitle>
        <Wrapper
          padding="0"
          width="100%"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Subtitle>{referencedItemPath}:</Subtitle>
          <Span
            fontSize="xs"
            fontWeight="bold"
            maxWidth="94%"
            margin="0 0 0 10px"
          >
            {referencedCollectorValue}
          </Span>
        </Wrapper>
      </Wrapper>
    );
  };

  return (
    <Wrapper
      padding="0"
      flexDirection="column"
      width="300px"
      alignItems="flex-start"
      maxHeight="300px"
      overflowY="auto"
    >
      {referencedCollectors.map((refCollector, idx) =>
        getReferencedPath(refCollector, idx + 1)
      )}
      <Wrapper
        padding="0"
        width="100%"
        justifyContent="space-between"
        margin="10px 0 0 0"
      >
        <Span fontWeight="bold">Resultado</Span>
        <Span fontWeight="bold">{autoCalculatedValue}</Span>
      </Wrapper>
    </Wrapper>
  );
};

const AutoCalcHelper = React.memo(
  ({ collector, collectorValues, filteredCollectorLayout }) => {
    const { replacedReferences, referencedCollectors } = React.useMemo(() => {
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        collector.autoCalc,
        { collectorLayout: filteredCollectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedCollectors = CollectorDynamics.getReferencedCollectors(
        replacedReferences,
        {
          collectorLayout: filteredCollectorLayout,
        }
      );
      return { replacedReferences, referencedCollectors };
    }, [filteredCollectorLayout, collector.autoCalc]);

    const referencedCollectorValues = React.useMemo(
      () =>
        CollectorDynamics.getReferencedCollectorValues(
          replacedReferences,
          collectorValues
        ),
      [replacedReferences, collectorValues]
    );

    const autoCalculatedValue = React.useMemo(
      () =>
        CollectorDynamics.getAutoCalculatedCollectorValue(
          replacedReferences,
          referencedCollectors,
          referencedCollectorValues
        ),
      [replacedReferences, referencedCollectors, referencedCollectorValues]
    );

    return (
      <Popover
        content={
          <AutoCalcHelperOverlay
            autoCalculatedValue={autoCalculatedValue}
            referencedCollectors={referencedCollectors}
            referencedCollectorValues={referencedCollectorValues}
          />
        }
        placement="bottomRight"
        title={<Title>Análisis de los datos ingresados</Title>}
        trigger="hover"
      >
        <div
          style={{ margin: "0 0 0 10px" }}
          onClick={(e) => e.stopPropagation()}
        >
          <InfoIcon size="small" />
        </div>
      </Popover>
    );
  }
);

const CoordInformation = React.memo(({ value, profile, theme }) => {
  //Get Coords format
  const coords = React.useMemo(
    () => CollectorUtils.getCoordsFromValue(value),
    [value]
  );

  if (!value) return null;
  return (
    <Wrapper
      width="100%"
      padding="0 0 0 10px"
      flexDirection="column"
      alignItems="stretch"
    >
      <Wrapper padding="0" justifyContent="space-between">
        <Span fontSize="s" color={theme.colors.text.high}>
          Latitud:
        </Span>
        <Span fontSize="s" color={theme.colors.text.high}>
          {coords.latitude}
        </Span>
      </Wrapper>
      <Wrapper padding="0" justifyContent="space-between">
        <Span fontSize="s" color={theme.colors.text.high}>
          Longitud:
        </Span>
        <Span fontSize="s" color={theme.colors.text.high}>
          {coords.longitude}
        </Span>
      </Wrapper>
      <Wrapper padding="0" justifyContent="space-between">
        <Span fontSize="s" color={theme.colors.text.high}>
          Fecha:
        </Span>
        <Span fontSize="s" color={theme.colors.text.high}>
          {formatDate(coords.createdAt, profile)}
        </Span>
      </Wrapper>
    </Wrapper>
  );
});

const CoordSwitch = React.memo(
  ({
    collector,
    currentValue,
    onChangeState,
    onFocus,
    sendToast,
    profile,
    theme,
  }) => {
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
      setLoading(false);
    }, [currentValue]);

    const handleOnCoords = (checked) => {
      setLoading(true);
      if (!checked) onChangeState(false);
      else {
        getGeolocation()
          .then(({ coords, message, type }) => {
            let value;
            if (message) {
              value = JSON.stringify({
                latitude: COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE,
                longitude:
                  COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE,
                createdAt: new Date(),
              });
              sendToast({ message, type });
            } else if (coords) {
              value = JSON.stringify({ ...coords, createdAt: new Date() });
            }
            onChangeState(value);
          })
          .catch(({ message, type }) => {
            setLoading(false);
            sendToast({ message, type });
          });
      }
    };

    return (
      <Wrapper padding="0" width="100%">
        <Switch
          size="small"
          checked={!!currentValue}
          defaultChecked={getInputProp({ collector, prop: "defaultChecked" })}
          checkedChildren={
            loading ? <Icon type="loading" /> : <Icon type="check" />
          }
          unCheckedChildren={
            loading ? <Icon type="loading" /> : <Icon type="close" />
          }
          onFocus={onFocus}
          onChange={handleOnCoords}
        />
        <CoordInformation
          value={currentValue}
          profile={profile}
          theme={theme}
        />
      </Wrapper>
    );
  }
);

const FileInput = React.memo(
  ({ collector, currentValue, savedCollector, sendToast, onChangeState }) => {
    const { uploadFile } = useCollectorApi();
    const [status, setStatus] = React.useState(null);

    const accept = collector.layoutProps?.accept;
    if (!accept) return;

    const handleOnChange = async (e) => {
      e.preventDefault();
      try {
        const file = e.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append("collectorFile", file);

        setStatus("uploading");
        const { src } = await uploadFile(formData);
        setStatus("success");
        onChangeState(src);
      } catch (err) {
        setStatus("error");
        if (err.showError) {
          const { message, type } = err;
          sendToast({
            message,
            type,
          });
          return;
        }
        sendToast({
          message: "Tuve un problema al subir el archivo. Inténtalo más tarde",
          type: "warn",
        });
      }
    };

    //Error
    if (status === "error")
      return (
        <Wrapper padding="0 0 0 10px" width="100%">
          <CloseIcon onClick={() => setStatus("upload")} />
        </Wrapper>
      );

    //Uploading
    if (
      status === "uploading" ||
      savedCollector?.status === COLLECTOR_KEYWORDS.COLLECTORS.STATUS.LOADING
    )
      return (
        <Wrapper padding="0 0 0 10px" width="100%">
          <LogoIcon spin />
        </Wrapper>
      );

    //Button to upload
    if (!currentValue || status === "upload")
      return (
        <Wrapper padding="0 0 0 10px" width="100%">
          <label>
            <UploadIcon />
            <input
              style={{ display: "none" }}
              accept={accept}
              type="file"
              onChange={handleOnChange}
            />
          </label>
        </Wrapper>
      );

    //Uploaded
    if (!!currentValue)
      return (
        <Wrapper padding="0 0 0 10px" width="100%">
          <CheckIcon
            margin="0 10px 0 0"
            onClick={() => window.open(currentValue)}
          />
          <DeleteIcon
            onClick={() => {
              setStatus("upload");
              onChangeState("");
            }}
          />
        </Wrapper>
      );
  }
);

const Collector = React.memo(
  ({
    profile,
    order,
    docId,
    collector,
    readOnlyCollector,
    highlightRequired,
    selectedCurrentCollector,
    isPhotoBackupActive,
    isSynapseCameraActive,
    filteredCollectorLayout,
    collectorValues,
    resources,
    collectorEcosystemAutoSyncRunning,
    handleOnDuplicateElement,
    handleOnDeleteElement,
  }) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const { onCollectorValuesChange } = useCollectorValues();
    const sendToast = (payload) => dispatch(_sendToast(payload));

    const [value, setValue] = React.useState(undefined);

    const { collectorProps, savedCollector } = React.useMemo(() => {
      const collectorProps = CollectorUtils.getCollectorProps(
        { order_id: order.order_id, docId },
        collector
      );
      const savedCollectorIdx =
        CollectorUtils.getCollectorIdxFromCollectorValues(
          collectorValues,
          collectorProps
        );
      const savedCollector = CollectorUtils.getCollectorValueFromIdx(
        collectorValues,
        savedCollectorIdx
      );

      return { collectorProps, savedCollector };
    }, [collector, order.order_id, docId, collectorValues]);

    const currentValue = React.useMemo(() => {
      if (value !== undefined) return value;
      return savedCollector?.value;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, savedCollector?.value]);

    //Reset local value on filter this collector in CollectorLayout
    // React.useEffect(() => {
    //   setValue(undefined);
    // }, [savedCollector?.value]);

    const mutate1ObjectInCollector = (obj1Name, keyValuePairs) =>
      dispatch(mutate1Object(obj1Name, keyValuePairs));

    const onChangeState = (value) => {
      if (value === currentValue) return;
      setValue(value);
      onCollectorValuesChange({
        value,
        collectorProps,
      });
    };

    const onBlur = (e) => {
      let value = e.target.value;
      if (typeof value === "string") value = value.trim();
      onChangeState(value);
    };

    const onFocus = () => {
      mutate1ObjectInCollector("reviewManage", {
        selectedCurrentCollector:
          CollectorUtils.setHighlightCollector(collectorProps),
      });
    };

    const readOnly = readOnlyCollector || collector.layoutProps?.readOnly;

    return (
      <>
        {/* COLLECTOR NAME */}
        {CheckUtils.showCollectorName(collector) && (
          <CollectorName
            collector={collector}
            collectorProps={collectorProps}
            selectedCurrentCollector={selectedCurrentCollector}
            highlightRequired={highlightRequired}
          />
        )}
        {readOnly && (
          <CollectorValue
            collector={collector}
            currentValue={currentValue}
            collectorValues={collectorValues}
            filteredCollectorLayout={filteredCollectorLayout}
            profile={profile}
            theme={theme}
          />
        )}

        <Wrapper padding="0" width="100%">
          <Wrapper padding="0" width="100%">
            {/* STRING */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.STRING && (
                <String
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChangeState={onChangeState}
                />
              )}
            {/* NUMBER */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.NUMBER && (
                <Number
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* TIME */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.TIME && (
                <Time
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* DATE */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATE && (
                <DateType
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* DATETIME */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATETIME && (
                <Datetime
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* BOOLEAN */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.BOOLEAN && (
                <Boolean
                  collector={collector}
                  currentValue={currentValue}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* LIST */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.LIST && (
                <List
                  collector={collector}
                  currentValue={currentValue}
                  collectorValues={collectorValues}
                  filteredCollectorLayout={filteredCollectorLayout}
                  onFocus={onFocus}
                  onChangeState={onChangeState}
                />
              )}
            {/* COORDS */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.COORDS && (
                <CoordSwitch
                  collector={collector}
                  currentValue={currentValue}
                  onChangeState={onChangeState}
                  onFocus={onFocus}
                  sendToast={sendToast}
                  profile={profile}
                  theme={theme}
                />
              )}
            {/* FILE INPUT */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.FILE && (
                <FileInput
                  collector={collector}
                  currentValue={currentValue}
                  savedCollector={savedCollector}
                  sendToast={sendToast}
                  onChangeState={onChangeState}
                />
              )}

            {/* CONSUME INVENTORY INTEGRATION */}
            {!readOnly &&
              collector.typeKey ===
                COLLECTOR_KEYWORDS.COLLECTORS.TYPES.CONSUME_INVENTORY && (
                <CollectorEcosystemIntegration
                  docId={docId}
                  order={order}
                  collector={collector}
                  collectorValues={collectorValues}
                  collectorEcosystemAutoSyncRunning={
                    collectorEcosystemAutoSyncRunning
                  }
                  onCollectorValuesChange={onCollectorValuesChange}
                />
              )}
          </Wrapper>
          {/* AUTO CALC HELPER */}
          {!!collector.autoCalc?.length && (
            <AutoCalcHelper
              collector={collector}
              collectorValues={collectorValues}
              filteredCollectorLayout={filteredCollectorLayout}
            />
          )}
        </Wrapper>
        {/* SIGNER CANVAS */}
        {collector.typeKey ===
          COLLECTOR_KEYWORDS.COLLECTORS.TYPES.SIGNER_CANVAS && (
          <WithSignerCanvas
            profile={profile}
            order={order}
            docId={docId}
            collector={collector}
            readOnlyCollector={readOnlyCollector}
            highlightRequired={highlightRequired}
            resources={resources}
          />
        )}
        {/* PHOTOS */}
        {collector.typeKey !==
          COLLECTOR_KEYWORDS.COLLECTORS.TYPES.SIGNER_CANVAS && (
          <WithPhotos
            profile={profile}
            collectorTypeKey={collector.typeKey}
            order={order}
            docId={docId}
            collector={collector}
            highlightRequired={highlightRequired}
            readOnlyCollector={readOnlyCollector}
            isPhotoBackupActive={isPhotoBackupActive}
            isSynapseCameraActive={isSynapseCameraActive}
            resources={resources}
            handleOnDuplicateElement={handleOnDuplicateElement}
            handleOnDeleteElement={handleOnDeleteElement}
          />
        )}
      </>
    );
  }
);

export default Collector;
