//Libs
import React from "react";
//Hooks
import { useClientRect } from "hooks";
//Components
import { Spin } from "antd";
import { Modal, Wrapper } from "components/components";
import {
  //ORDERS
  GetOrdersModal,
  SendEventModal,
  UninstallItemModal,
  CompletedCodeModal,
  //PAYMENTS
  SignPaymentModal,
  //WAREHOUSE
  CommentPhotoReportModal,
  CreateTransactionModal,
  GetTransactionsModal,
  SerieHistoryModal,
  UserProfileData,
} from "./subs";
//Styles
import "./index.css";

export default function RenderModal(props) {
  const {
    modalId,
    title,
    visible,
    onOk,
    onCancel,
    footer,
    isSpinning,
    sizeSpinning,
    delaySpinning,
    tipSpinning,
    customProps,
  } = props;
  const [rect, ref] = useClientRect();

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
    >
      <Spin
        spinning={isSpinning}
        size={sizeSpinning}
        delay={delaySpinning}
        tip={tipSpinning}
      >
        <Wrapper padding="0" ref={ref}>
          {modalId === "GetOrdersModal" && <GetOrdersModal {...customProps} />}
          {modalId === "SendEventModal" && <SendEventModal {...customProps} />}
          {modalId === "SignPaymentModal" && (
            <SignPaymentModal {...customProps} />
          )}
          {modalId === "UninstallItemModal" && (
            <UninstallItemModal {...customProps} />
          )}
          {modalId === "CompletedCodeModal" && (
            <CompletedCodeModal {...customProps} />
          )}
          {modalId === "CommentPhotoReportModal" && (
            <CommentPhotoReportModal {...customProps} />
          )}
          {modalId === "CreateTransactionModal" && (
            <CreateTransactionModal {...customProps} />
          )}
          {modalId === "GetTransactionsModal" && (
            <GetTransactionsModal {...customProps} />
          )}
          {modalId === "SerieHistoryModal" && (
            <SerieHistoryModal {...customProps} rect={rect} />
          )}
          {modalId === "UserProfileData" && (
            <UserProfileData {...customProps} rect={rect} />
          )}
        </Wrapper>
      </Spin>
    </Modal>
  );
}
