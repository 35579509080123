import CommonOperations from "core/database/CommonOperations";
import { GlobalUtils } from "utils";

export default class SerializedCollectorLayout {
  constructor(data) {
    // Template
    this.templateId = data.templateId;
    // Service
    this.serviceId = data.serviceId;
    // Service Task
    this.serviceTaskId = data.serviceTaskId;
    this.serviceTaskName = data.serviceTaskName;
    this.serviceTaskProps = data.serviceTaskProps;
    // Review
    this.reviewId = data.reviewId;
    this.reviewName = data.reviewName;
    // Group
    this.groupId = data.groupId;
    this.groupName = data.groupName;
    // Subgroup
    this.subgroupId = data.subgroupId;
    this.subgroupName = data.subgroupName;
    // Collector
    this.collectorId = data.collectorId || data.id;
    this.collectorName = data.collectorName || data.name;
    this.name = this.collectorName; // TODO: REDUNDANT: Migrate and remove it when it's possible
    this.description = data.description;
    this.props = data.props;
    // Layout
    this.layoutId = data.layoutId || "null";
    this.layoutProps = data.layoutProps;
    this.listValues = data.listValues;
    this.autoCalc = data.autoCalc;
    this.autoCalcPlaceholder = data.autoCalcPlaceholder;
    this.filters = data.filters;
    this.inventoryFilter = data.inventoryFilter;
    // Type
    this.typeKey = data.typeKey;
    this.typeProps = data.typeProps;
    // Unit
    this.unitKey = data.unitKey;
    this.unitName = data.unitName;
    // Metadata
    this.duplicated = data.duplicated ? "true" : "false";
    this.required = data.required;
    this.sort = data.rliSort || data.sort;
    this.createdAt = data.createdAt;
    this.hasUnsuccessPhotoLayouts =
      data.hasUnsuccessPhotoLayouts ||
      !!GlobalUtils.checkArray(data.photos).find(
        (p) => !p.layoutPhotoId || isNaN(p.layoutPhotoId)
      )
        ? "true"
        : "false";
    this.collectorLayoutDeleted = data.collectorLayoutDeleted;
    this.id = CommonOperations.collectorLayoutId({
      ...data,
      collectorId: this.collectorId,
    });
    this.duplicatedId =
      (this.isDuplicable() &&
        (data.duplicatedId || CommonOperations.collectorLayoutId(data.id))) ||
      undefined;
    // Owned
    this.orderId =
      (this.isDuplicable() && (data.auditOrderId || data.orderId)) || "null";
    this.docId = (this.isDuplicable() && data.docId) || "null";
    /////////////////////////////////////////////////////////////////////
    this.collectorIdx = data.collectorIdx; //TODO: Pending to delete
    this.subgroupIdx = data.subgroupIdx; //TODO: Pending to delete
    this.groupIdx = data.groupIdx; //TODO: Pending to delete
    this.reviewIdx = data.reviewIdx; //TODO: Pending to delete
  }

  isDuplicable() {
    return this.duplicated === "true";
  }
}
