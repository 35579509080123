//Libs
import React, { useRef } from "react";
import { useTheme } from "styled-components";
//Components
import { VirtualizedList } from "../../../";
import {
  Wrapper,
  Span,
  Menu,
  Button,
  IconButton,
  Icon,
  Divider,
  Popover,
} from "../../../../../components";
//Utils
import {
  getTotalProduction,
  getTotalBonifications,
  getTotalDiscounts,
  getTotalToPay,
  groupResumeOrders,
  recordsHaveAllowedStates,
} from "../../../../Payments/utils";
import { coinsUtils } from "../../../../../../utils/libs";
import orderBy from "../../../../../../utils/libs/orderBy";
//Styles
import "./SignPaymentModal.css";

function GetOptionsResumeContent(props) {
  const { signPaymentModal, mutate1ObjectInPayments } = props;
  const theme = useTheme();

  return (
    <Menu.Wrapper>
      <Menu.Group title="Producción">
        <Menu.Item
          style={
            !signPaymentModal.groupOrders && {
              backgroundColor: theme.colors.primary,
            }
          }
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", {
              groupOrders: false,
            })
          }
        >
          Individual
        </Menu.Item>
        <Menu.Item
          style={
            signPaymentModal.groupOrders && {
              backgroundColor: theme.colors.primary,
            }
          }
          onClick={() =>
            mutate1ObjectInPayments("signPaymentModal", { groupOrders: true })
          }
        >
          Agrupadas
        </Menu.Item>
      </Menu.Group>
    </Menu.Wrapper>
  );
}

export function HeadSignPaymentModal(props) {
  const {
    resume,
    getPaymentResume,
    pay,
    signPaymentModal,
    mutate1ObjectInPayments,
  } = props;

  return (
    <Wrapper width="95%" height="28px" justifyContent="space-between">
      <Span fontSize="xl">Resumen</Span>
      <div>
        <Popover
          trigger="click"
          placement="bottomRight"
          title={null}
          content={
            <GetOptionsResumeContent
              signPaymentModal={signPaymentModal}
              mutate1ObjectInPayments={mutate1ObjectInPayments}
            />
          }
        >
          <IconButton type="eye" />
        </Popover>

        {resume.loading ? (
          <IconButton type="loading" />
        ) : (
          <IconButton type="reload" onClick={() => getPaymentResume(pay.id)} />
        )}
      </div>
    </Wrapper>
  );
}

function GetResumeItem({ concept, value, color }) {
  const theme = useTheme();

  return (
    <Wrapper padding="5px" justifyContent="space-between" width="95%">
      <Span fontSize="l" color={color ? color : theme.colors.text.medium}>
        {concept}
      </Span>
      <Span fontSize="l" color={color ? color : theme.colors.text.high}>
        {value}
      </Span>
    </Wrapper>
  );
}

export default function SignPaymentModal(props) {
  const {
    pay,
    totalUsers,
    signPaymentModal,
    signPayment,
    resume,
    coins,
    orders,
    profile,
    getOrder,
    mutate1ObjectInOrders,
  } = props;
  const theme = useTheme();
  const coinSymbol = coinsUtils.getDataByCoinId(pay.coin_id, "symbol", coins);
  const bonifications = resume.lines.filter((line) => line.action === "credit");
  const discounts = resume.lines.filter((line) => line.action === "debit");
  const prodAmounts = getTotalProduction(resume.orders, pay.iva, coinSymbol);
  const bonusAmounts = getTotalBonifications(
    bonifications,
    pay.iva,
    coinSymbol
  );
  const discountsAmounts = getTotalDiscounts(discounts, pay.iva, coinSymbol);
  const totalToPay = getTotalToPay(
    prodAmounts.productionTotal,
    bonusAmounts.bonificationTotal,
    discountsAmounts.discountTotal,
    coinSymbol
  );
  const targetRef = useRef();
  const signed = resume.payments.reduce((acc, payment) => {
    if (Array.isArray(payment.signed)) acc = acc.concat(payment.signed);
    return acc;
  }, []);
  orderBy(signed, "objectDate", {
    objectKey: "created_at",
    dateFormat: profile?.user?.settings?.date_format,
  });

  return (
    <Wrapper
      width="100%"
      padding="0"
      flexDirection="column"
      backgroundColor={theme.colors.primary}
      overflow="auto"
    >
      <Wrapper
        width="100%"
        ref={targetRef}
        flexDirection="column"
        justifyContent="space-between"
      >
        {/* PRODUCTION */}
        {resume.orders.length > 0 && (
          <>
            <Divider color="gray">Producción ({resume.orders.length})</Divider>
            <Wrapper flexDirection="column" width="100%">
              <VirtualizedList
                cardId="paymentOrderResumeCard"
                listStyle={{
                  height: document.documentElement.clientHeight * 0.5,
                  width: targetRef.current ? targetRef.current.offsetWidth : 0,
                }}
                rowHeight={80}
                rows={
                  signPaymentModal.groupOrders
                    ? groupResumeOrders(resume.orders)
                    : resume.orders
                }
                customProps={{
                  coinSymbol,
                  orders,
                  mutate1ObjectInOrders,
                  getOrder,
                  groupOrders: signPaymentModal.groupOrders,
                }}
              />
              <GetResumeItem concept="Subtotal" value={prodAmounts.prod} />
              <GetResumeItem concept="IVA" value={prodAmounts.prodIva} />
            </Wrapper>
          </>
        )}

        {/* BONIFICATION */}
        {bonifications.length > 0 && (
          <>
            <Divider color="gray">
              Bonificación ({bonifications.length})
            </Divider>
            <Wrapper flexDirection="column" width="100%">
              <VirtualizedList
                cardId="paymentLineResumeCard"
                listStyle={{
                  height: document.documentElement.clientHeight * 0.5,
                  width: targetRef.current ? targetRef.current.offsetWidth : 0,
                }}
                rowHeight={125}
                spinTitle="Consultando registros..."
                rows={bonifications}
                customProps={{
                  coinSymbol,
                  orders,
                  getOrder,
                  mutate1ObjectInOrders,
                }}
              />
              <GetResumeItem concept="Subtotal" value={bonusAmounts.bonus} />
              <GetResumeItem concept="IVA" value={bonusAmounts.bonusIva} />
            </Wrapper>
          </>
        )}

        {/* DISCOUNTS */}
        {discounts.length > 0 && (
          <>
            <Divider color="gray">Descuentos ({discounts.length})</Divider>
            <Wrapper flexDirection="column" width="100%">
              <VirtualizedList
                cardId="paymentLineResumeCard"
                listStyle={{
                  height: document.documentElement.clientHeight * 0.5,
                  width: targetRef.current ? targetRef.current.offsetWidth : 0,
                }}
                rowHeight={125}
                spinTitle="Consultando registros..."
                rows={discounts}
                customProps={{
                  coinSymbol,
                  orders,
                  getOrder,
                  mutate1ObjectInOrders,
                }}
              />
              <GetResumeItem concept="Subtotal" value={discountsAmounts.disc} />
              <GetResumeItem concept="IVA" value={discountsAmounts.discIva} />
            </Wrapper>
          </>
        )}

        {/* RESUME */}
        <Divider color="gray">Resumen</Divider>
        <Wrapper flexDirection="column" width="100%">
          <GetResumeItem concept="Producción" value={prodAmounts.prodTotal} />
          <GetResumeItem
            concept="Bonificación"
            value={`+ ${bonusAmounts.bonusTotal}`}
          />
          <GetResumeItem
            concept="Descuentos"
            value={`- ${discountsAmounts.discTotal}`}
          />
          <GetResumeItem concept="IVA" value={`${pay.iva}%`} />
          <GetResumeItem
            concept="Total a Pagar"
            value={totalToPay}
            color={theme.colors.success.color}
          />
        </Wrapper>

        {/* SIGNED */}
        <Divider color="gray">Firmas</Divider>
        <Wrapper
          width="100%"
          margin="20px 0 10px 0"
          borderRadius="20px"
          backgroundColor={theme.colors.secondary}
          overflow="auto"
        >
          {signed.map((sign, idx) => {
            const user = totalUsers.filter(
              (user) => user.id === sign.user_id
            )[0];
            let style = { color: "rgba(255, 255, 255, 0.6)" };

            if (sign.paid) style = { color: "green" };
            else if (sign.released) style = { color: "yellow" };
            else if (sign.agreed) style = { color: "deepskyblue" };

            return (
              user && (
                <Wrapper key={idx} padding="0px">
                  <Icon type="user" style={{ ...style, fontSize: "15px" }} />
                  <Wrapper
                    margin="0 10px 10px 0"
                    padding="0px"
                    flexDirection="column"
                    alignItems="baseline"
                  >
                    <Span style={style} margin="0 10px">
                      {user.name} ({sign.payment_id})
                    </Span>
                    <Span margin="0 10px" color="cadetblue">
                      {sign.created_at}
                    </Span>
                  </Wrapper>
                </Wrapper>
              )
            );
          })}
        </Wrapper>

        {recordsHaveAllowedStates(resume.payments, [
          "ready",
          // "agreed",
          "released",
        ]) && (
          <Wrapper width="100%" justifyContent="flex-end">
            {signPaymentModal.loading ? (
              <IconButton type="loading" />
            ) : (
              <Button mode="primary" onClick={() => signPayment(pay.id)}>
                Firmar
              </Button>
            )}
          </Wrapper>
        )}
      </Wrapper>
    </Wrapper>
  );
}
