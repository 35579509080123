//Types
import COLLECTOR_TYPES from "./types";

//Actions
export function mutateDirectProps(keyValuePairs) {
  return {
    type: COLLECTOR_TYPES.MUTATE_DIRECT_PROPS,
    payload: keyValuePairs,
  };
}

export function mutate1Object(obj1Name, keyValuePairs) {
  return {
    type: COLLECTOR_TYPES.MUTATE_1OBJECT,
    payload: { obj1Name, keyValuePairs },
  };
}

export function setCollectorValuesV2(payload = []) {
  return {
    type: COLLECTOR_TYPES.SET_COLLECTOR_VALUES_V2,
    payload,
  };
}

export function setAutoFillCollector(payload) {
  return {
    type: COLLECTOR_TYPES.SET_AUTO_FILL_COLLECTOR,
    payload,
  };
}

export function saveCollectorValues(collectorValues) {
  return {
    type: COLLECTOR_TYPES.SAVE_COLLECTOR_VALUES,
    payload: { collectorValues },
  };
}

export function updateCollectorRequiredValidation(payload) {
  return {
    type: COLLECTOR_TYPES.UPDATE_COLLECTOR_REQUIRED_VALIDATION,
    payload,
  };
}

export function getSavedCollectorValuesByOrder(orderId) {
  return {
    type: COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_ORDER,
    payload: { orderId },
  };
}

export function getSavedCollectorValuesByTransactionDoc(docId, orderId) {
  return {
    type: COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC,
    payload: { docId, orderId },
  };
}

export function getAuditedTransactionDocs(orderId) {
  return {
    type: COLLECTOR_TYPES.GET_AUDITED_TRANSACTION_DOCS,
    payload: { orderId },
  };
}

export function setAutoSync(payload) {
  return {
    type: COLLECTOR_TYPES.SET_AUTO_SYNC,
    payload,
  };
}

export function resetAuditedTransactionDocs() {
  return {
    type: COLLECTOR_TYPES.RESET_AUDITED_TRANSACTION_DOCS,
  };
}

export function resetAutoFillCollector() {
  return {
    type: COLLECTOR_TYPES.RESET_AUTOFILL_COLLECTOR,
  };
}

export function autoSyncCollectorValues(collectorValues) {
  return {
    type: COLLECTOR_TYPES.START_AUTO_SYNC_COLLECTOR_VALUES,
    payload: { collectorValues },
  };
}

export function completeAuditedReport(orderId) {
  return {
    type: COLLECTOR_TYPES.COMPLETE_AUDITED_REPORT,
    payload: { orderId },
  };
}

export function updateStateAuditedTransactionDoc({ docId, orderId, state }) {
  return {
    type: COLLECTOR_TYPES.UPDATE_STATE_AUDITED_TRANSACTION_DOC,
    payload: { docId, orderId, state },
  };
}

export function doReset() {
  return {
    type: COLLECTOR_TYPES.RESET,
  };
}

export function onGenerateReport({ docId, serviceTaskId, format }) {
  return {
    type: COLLECTOR_TYPES.GENERATE_REPORT,
    payload: { docId, serviceTaskId, format },
  };
}
