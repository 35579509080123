// Libs
import { useMemo, useEffect } from "react";
import Immutable from "immutable";
// Utils
import { CollectorUtils } from "components/components";
import { CollectorDynamics } from "components/components/Collector/utils";
// Hooks
import { useCollectorData, useCollectorValues } from "hooks";
//Actions
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
import { AssetRepository } from "core/database";

const useCollectorLayout = ({ docId, orderId } = {}) => {
  const accessGranted = docId || orderId;
  const {
    segment,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    collectorValues,
    resources,
    setAutoFillCollector,
    onLoadSegmentedResources,
  } = useCollectorData();
  const { onCollectorValuesFilter } = useCollectorValues();

  //Reset collector value by filter list value
  const filterListValues = (collector, toDeleteCollectorValues) => {
    collector.listValues.forEach((listValue) => {
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        listValue.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedlistValue = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedlistValue) {
        const toDeleteCollectorValue = CollectorUtils.getToDeleteCollectorValue(
          { order_id: orderId, docId },
          collector,
          collectorValues
        );
        if (toDeleteCollectorValue)
          toDeleteCollectorValues.push(toDeleteCollectorValue);
      }
    });
  };

  const filterPhotos = (collector) =>
    collector.photos.reduce((photos, photo) => {
      if (!photo.filters?.length) {
        photos.push(photo);
        return photos;
      }
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        photo.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return photos;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedPhoto = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedPhoto) {
        const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
          { order_id: orderId, docId },
          collector,
          photo
        );
        if (fileProps) AssetRepository.delete(fileProps.id);
        return photos;
      }
      return [...photos, photo];
    }, []);

  const filterCollectors = (review, toDeleteCollectorValues) =>
    review.collectors.reduce((acc, collector) => {
      if (!collector.filters?.length) {
        filterListValues(collector, toDeleteCollectorValues);
        collector.photos = filterPhotos(collector);
        acc.push(collector);
        return acc;
      }
      const absoluteReferences = CollectorDynamics.getAbsoluteReferences(
        collector.filters,
        { collectorLayout }
      );
      const replacedReferences = CollectorDynamics.replaceDuplicatedReferences(
        absoluteReferences,
        collector
      );
      const referencedFilter = CollectorDynamics.getReferencedFilter(
        replacedReferences,
        collector
      );
      if (!referencedFilter) return acc;

      const referencedCollectorValue =
        CollectorDynamics.getReferencedCollectorValue(
          referencedFilter,
          collectorValues
        );
      const checkedCollector = CollectorDynamics.checkFilteredCollector(
        referencedFilter,
        referencedCollectorValue,
        collectorLayout
      );
      if (!checkedCollector) {
        const toDeleteCollectorValue = CollectorUtils.getToDeleteCollectorValue(
          { order_id: orderId, docId },
          collector,
          collectorValues
        );
        if (toDeleteCollectorValue)
          toDeleteCollectorValues.push(toDeleteCollectorValue);
        return acc;
      }

      filterListValues(collector, toDeleteCollectorValues);
      collector.photos = filterPhotos(collector);

      return [...acc, collector];
    }, []);

  const filteredCollectorLayout = useMemo(() => {
    if (!accessGranted) return;

    let toDeleteCollectorValues = [];
    const _filteredCollectorLayout = Immutable.List(collectorLayout)
      .toJS()
      .map((review) => {
        review.collectors = filterCollectors(review, toDeleteCollectorValues);
        return review;
      });
    if (!!toDeleteCollectorValues.length)
      onCollectorValuesFilter(toDeleteCollectorValues);
    return _filteredCollectorLayout;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docId, orderId, accessGranted, collectorLayout, collectorValues]);

  //Reload offlineCollectorValues and sync with onlineCollectorValues
  useEffect(() => {
    if (!orderId) return;

    CollectorUtils.reloadOfflineAutoFillCollector().then(
      (offlineAutoFillCollector) =>
        setAutoFillCollector(offlineAutoFillCollector)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  return {
    segment,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
    onLoadSegmentedResources,
  };
};

export default useCollectorLayout;
