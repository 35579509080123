//Libs
import React from "react";
import { useTheme } from "styled-components";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
//Utils
import GENERAL from "utils/constants/general";
import {
  GlobalUtils,
  ProjectUtils,
  ServiceUtils,
  OrderUtils,
  UserUtils,
} from "utils";
import KEYWORDS from "components/components/ConsumeInventory/keywords";
//Actions
import { updateCollectorRequiredValidation } from "components/components/Collector/actions";
import { sendToast } from "components/containers/Dashboard/actions";
import {
  getCurrentConsumedItems,
  mutateConsumeInventoryControl,
  generateResume,
  resetResumeControl,
} from "components/components/ConsumeInventory/actions";
import { resetCompleteOrderControl } from "components/containers/Orders/actions";
//Selectors
import {
  selectReviewManage,
  selectSelectedTransactionDocId,
} from "components/components/Collector/selectors";
import {
  selectIsCollectorAndConsumedItemSuccess,
  selectCompleteOrder,
} from "components/containers/Orders/selectors";
import { selectCollectorEcosystemAutoSync } from "components/components/Collector/selectors";
import { selectProfile } from "components/containers/Login/selectors";
import {
  selectResumeControl,
  selectControlledInventory,
} from "components/components/ConsumeInventory/selectors";
//Hooks
import { useCollectorLayout } from "hooks";
//Components
import {
  Spin,
  CollectorUtils,
  ConsumeInventoryUtils,
  Tabs,
  CollectorLayout,
  ConsumeInventory,
  Span,
} from "components/components";
import Resume from "./Resume";
import { AssetRepository } from "core/database";

const { ENV } = GENERAL;
const { TabWrap, TabPane } = Tabs;

function CompleteOrder({
  collectorEcosystemAutoSync,
  docId,
  order,
  reviewManage,
  isCollectorAndConsumedItemSuccess,
  resumeControl,
  controlledInventory,
  mutateConsumeInventoryControl,
  updateCollectorRequiredValidation,
  getCurrentConsumedItems,
  generateResume,
  resetResumeControl,
  resetCompleteOrderControl,
  sendToast,
  profile,
}) {
  const theme = useTheme();
  const { orderId, sendingReport } = reviewManage;
  const [canViewResume, setCanViewResume] = React.useState(false);
  const {
    segment,
    readOnlyCollector,
    collectorLayout,
    duplicatedCollectorLayout,
    filteredCollectorLayout,
    collectorValues,
    resources,
    onLoadSegmentedResources,
  } = useCollectorLayout({
    docId,
    orderId,
  });

  React.useEffect(() => {
    let isMounted = true;

    AssetRepository.unsuccessCount({ auditOrderId: orderId }).then(
      (pendingResourceCount) =>
        isMounted &&
        setCanViewResume(
          isCollectorAndConsumedItemSuccess && pendingResourceCount === 0
        )
    );
    return () => {
      isMounted = false;
    };
  }, [
    collectorEcosystemAutoSync.running,
    orderId,
    isCollectorAndConsumedItemSuccess,
    resources,
  ]);

  React.useEffect(() => {
    if (!orderId) return;
    //Reload offline consumed items and get saved transaction items
    getCurrentConsumedItems(orderId);
    ConsumeInventoryUtils.getOfflineManageInventory().then(
      ({ offlineConsumedItems }) => {
        mutateConsumeInventoryControl({
          consumedItems: offlineConsumedItems,
        });
      }
    );
  }, [orderId]);

  //MyState
  const [myState, setMyState] = React.useState({ activeTab: "1" });
  const changeState = (newState) => setMyState({ ...myState, ...newState });

  React.useEffect(() => {
    let isMounted = true;
    if (resumeControl.status === KEYWORDS.STATUS.ERROR) {
      var t1 = setTimeout(
        () => isMounted && changeState({ activeTab: "2" }),
        700
      );
    }
    return () => {
      isMounted = false;
      clearTimeout(t1);
    };
  }, [resumeControl.status]);

  if (!orderId || !order)
    return <Redirect to={ENV.ROUTES.PATHS.ORDERS_MANAGE} />;

  return (
    <Spin
      spinning={sendingReport}
      size="small"
      delay={50}
      tip={"Enviando Reporte..."}
    >
      <TabWrap
        tabBarStyle={{
          color: "black",
        }}
        smallTabs
        tabPosition="top"
        activeKey={myState.activeTab}
        onChange={(activeTab) => {
          changeState({ activeTab });
          if (activeTab !== "3") {
            mutateConsumeInventoryControl({ editableInventory: true });
          } else {
            resetResumeControl();
            resetCompleteOrderControl();

            //Collector Ecosystem config
            const collectorEcosystemConfig = GlobalUtils.selectCurrentProps([
              ProjectUtils.getProjectPropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
              ServiceUtils.getServicePropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
              OrderUtils.getOrderPropsFromOrder(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                order
              ),
              UserUtils.getUserPropsFromProfile(
                ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
                profile
              ),
            ]);

            CollectorUtils.checkIsCollectorAndResourceRequiredDataPending({
              isActiveDataValidator: collectorEcosystemConfig.required,
              validateData: {
                order: {
                  order_id: order.order_id,
                  docId,
                },
                filteredCollectorLayout,
                collectorValues,
              },
              updateCollectorRequiredValidation,
              sendToast,
            }).then((checkDataPending) => {
              if (!checkDataPending) {
                changeState({ activeTab: "1" });
                return;
              }
              mutateConsumeInventoryControl({ editableInventory: false });
              generateResume();
            });
          }
        }}
      >
        <TabPane tab="Reportería" key="1">
          <CollectorLayout
            segment={segment}
            height={`${document.documentElement.clientHeight - 213}px`}
            order={order}
            docId={docId}
            readOnlyCollector={readOnlyCollector}
            collectorLayout={collectorLayout}
            duplicatedCollectorLayout={duplicatedCollectorLayout}
            filteredCollectorLayout={filteredCollectorLayout}
            collectorValues={collectorValues}
            resources={resources}
            onLoadSegmentedResources={onLoadSegmentedResources}
          />
        </TabPane>
        {!controlledInventory && (
          <TabPane tab="Inventario" key="2">
            {myState.activeTab === "2" && <ConsumeInventory order={order} />}
          </TabPane>
        )}
        <TabPane
          key="3"
          tab={
            <Span
              color={
                !canViewResume
                  ? theme.colors.text.disabled
                  : theme.colors.text.high
              }
            >
              {!canViewResume ? "Sync..." : "Resumen"}
            </Span>
          }
          disabled={!canViewResume}
        >
          {myState.activeTab === "3" && <Resume order={order} />}
        </TabPane>
      </TabWrap>
    </Spin>
  );
}

const mapStateToProps = (state) => ({
  collectorEcosystemAutoSync: selectCollectorEcosystemAutoSync(state),
  docId: selectSelectedTransactionDocId(state),
  order: selectCompleteOrder(state),
  reviewManage: selectReviewManage(state),
  isCollectorAndConsumedItemSuccess:
    selectIsCollectorAndConsumedItemSuccess(state),
  resumeControl: selectResumeControl(state),
  controlledInventory: selectControlledInventory(state),
  profile: selectProfile(state),
});
const actions = {
  getCurrentConsumedItems,
  generateResume,
  mutateConsumeInventoryControl,
  updateCollectorRequiredValidation,
  resetResumeControl,
  resetCompleteOrderControl,
  sendToast,
};

export default connect(mapStateToProps, actions)(CompleteOrder);
