/* eslint-disable array-callback-return */
// Libs
import { SentryService } from "services";
// Database
import DatabaseOperation from "../init";
// Utils
import GENERAL from "utils/constants/general";
import CommonOperations from "../CommonOperations";
import CollectorValueRepository from "../CollectorValues/CollectorValueRepository";
import { CollectorUtils } from "components/components";
import { DeserializedAsset, SerializedAsset } from "./models";

const { STATUS } = GENERAL.ENV.UPLOAD_RESOURCE;

const unsuccessStatusList = Object.values(STATUS).filter(
  (s) => s !== STATUS.SUCCESS
);

const serializeAsset = (data) => new SerializedAsset(data);
const deserializeAsset = (asset) => asset && new DeserializedAsset(asset);
const deserializeAssets = (assets) => assets.map(deserializeAsset);

export default class AssetRepository extends CommonOperations {
  static storeName = DatabaseOperation.storeNames.assets;

  static get(clause) {
    return DatabaseOperation.get(this.storeName, clause).then(deserializeAsset);
  }
  static put(item) {
    const asset = serializeAsset(item);
    delete asset.duplicatedId;

    return DatabaseOperation.put(this.storeName, asset);
  }
  static delete(id) {
    return DatabaseOperation.delete(this.storeName, id);
  }
  static clear() {
    return DatabaseOperation.clear(this.storeName);
  }
  /**
   * na: new asset
   * sa: stored/offline asset
   */
  static async merge(items) {
    const batchSize = 10;

    const assets = items.map(serializeAsset);

    return DatabaseOperation.transaction(
      DatabaseOperation.stores.assets,
      async () => {
        const neighborhood = {};
        for (let i = 0; i < assets.length; i += batchSize) {
          try {
            const slicedAssets = assets.slice(i, i + batchSize);
            const slicedAssetIds = [
              ...new Set(
                slicedAssets
                  .reduce((acc, na) => [...acc, na.id, na.duplicatedId], [])
                  .filter((na) => !!na)
              ),
            ];
            const storedAssets = await DatabaseOperation.bulkGet(
              this.storeName,
              slicedAssetIds
            ).then((sas) =>
              sas
                .filter((sa) => !!sa)
                .map((sa) => ({ id: sa.id, createdAt: sa.createdAt }))
            );

            const promises = slicedAssets.map((na) => {
              CollectorUtils.serializeNeighborhood(na, neighborhood);

              const sa = storedAssets.find((sa) =>
                [na.id, na.duplicatedId].includes(sa.id)
              );

              const deleteDuplicatedRecordId =
                na.id !== na.duplicatedId && na.duplicatedId;

              if (!sa) return !na.photoDeleted && this.put(na);

              const shouldUpdate =
                new Date(na.createdAt) - new Date(sa.createdAt) >= 0;
              if (!shouldUpdate) return;

              if (na.photoDeleted) return this.delete(sa.id);

              return Promise.all([
                deleteDuplicatedRecordId &&
                  this.delete(deleteDuplicatedRecordId),
                this.put(na),
              ]);
            });

            await Promise.all(promises);
          } catch (err) {
            SentryService.sendError(err);
          }
        }

        await this.syncNeighboringRecords(neighborhood);
        return neighborhood;
      }
    ).then((neighborhood) =>
      CollectorValueRepository.syncNeighboringRecords(neighborhood, {
        withTransaction: true,
      })
    );
  }

  static async syncNeighboringRecords(neighborhood, { withTransaction } = {}) {
    if (withTransaction)
      return DatabaseOperation.transaction(
        DatabaseOperation.stores.assets,
        () =>
          super.syncNeighboringRecords(this.storeName, neighborhood, (data) =>
            super.assetId(data)
          )
      );

    return super.syncNeighboringRecords(this.storeName, neighborhood, (data) =>
      super.assetId(data)
    );
  }

  static async loadSegment(seg) {
    return super
      .loadSegment(this.storeName, seg)
      .then(deserializeAssets)
      .catch(SentryService.sendError);
  }

  static async loadUnsuccess({ limit = 1 } = {}) {
    const unsuccessAssets = () =>
      DatabaseOperation.where(
        this.storeName,
        DatabaseOperation.indexes.status
      ).notEqual(STATUS.SUCCESS);

    if (!limit) return unsuccessAssets().toArray().then(deserializeAssets);

    return unsuccessAssets().limit(limit).toArray().then(deserializeAssets);
  }

  static async unsuccessCount(seg = {}) {
    const { orderId, docId } = super.segment(seg);

    if (!orderId)
      return DatabaseOperation.where(
        this.storeName,
        DatabaseOperation.indexes.status
      )
        .notEqual(STATUS.SUCCESS)
        .count();

    let count = 0;
    for (let status of unsuccessStatusList) {
      const _count = await DatabaseOperation.where(
        this.storeName,
        DatabaseOperation.indexes.data.status
      )
        .equals([orderId, docId, status])
        .count();
      count += _count;
    }

    return count;
  }

  static async unsuccessPrimaryKeys(seg) {
    const { orderId, docId } = super.segment(seg);

    let keys = [];
    for (let status of unsuccessStatusList) {
      const _keys = await DatabaseOperation.where(
        this.storeName,
        DatabaseOperation.indexes.data.status
      )
        .equals([orderId, docId, status])
        .primaryKeys();
      keys = keys.concat(_keys);
    }
    return keys;
  }

  static async removeSuccess() {
    const isOrderPending = await super.isOrderPending();
    const successAndNoPendingKeys = await DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.status
    )
      .equals(STATUS.SUCCESS)
      .filter((sa) => !isOrderPending[sa.auditOrderId])
      .primaryKeys();

    return DatabaseOperation.bulkDelete(
      this.storeName,
      successAndNoPendingKeys
    );
  }
}
