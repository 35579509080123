import CommonOperations from "../CommonOperations";

export default class CollectorLayoutPhotoUtils {
  static serializeNeighborhood(record, neighborhood) {
    if (record.duplicated === "false") return neighborhood;

    const {
      auditOrderId,
      orderId,
      docId,
      duplicatedId,
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
      collectorId,
    } = record;
    if (!duplicatedId) return neighborhood;

    const collectorLayoutId = CommonOperations.collectorLayoutId(duplicatedId);

    if (
      !collectorLayoutId ||
      !this.isDuplicatedCollectorLayoutPhotoOutOfSync(collectorLayoutId)
    )
      return neighborhood;

    neighborhood[collectorLayoutId] = {
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
      collectorId,
      orderId: auditOrderId || orderId || null,
      docId,
    };
    return neighborhood;
  }

  static isDuplicatedCollectorLayoutPhotoOutOfSync(collectorLayoutId) {
    return String(collectorLayoutId).includes("duplicated#");
  }
}
