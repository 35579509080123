/* eslint-disable array-callback-return */
import { CollectorUtils } from "components/components";
import CommonOperations from "../CommonOperations";
import DatabaseOperation from "../init";
import { SentryService } from "services";
// Utils
import GENERAL from "utils/constants/general";
import AssetRepository from "../Assets/AssetRepository";
import { DeserializedCollectorValue, SerializedCollectorValue } from "./models";

const { STATUS } = GENERAL.ENV.UPLOAD_RESOURCE;

const serializeCollectorValue = (data) => new SerializedCollectorValue(data);
const deserializeCollectorValue = (cv) =>
  cv && new DeserializedCollectorValue(cv);
const deserializeCollectorValues = (collectorValues) =>
  collectorValues.map(deserializeCollectorValue);

export default class CollectorValueRepository extends CommonOperations {
  static storeName = DatabaseOperation.storeNames.collectorValues;

  static bulkGet(keys) {
    return DatabaseOperation.bulkGet(this.storeName, keys).then(
      deserializeCollectorValues
    );
  }
  static put(item) {
    const collectorValue = serializeCollectorValue(item);
    delete collectorValue.duplicatedId;

    return DatabaseOperation.put(this.storeName, collectorValue);
  }
  static bulkPut(items) {
    const collectorValues = items.map((i) => {
      const collectorValue = serializeCollectorValue(i);
      delete collectorValue.duplicatedId;

      return collectorValue;
    });
    return DatabaseOperation.bulkPut(this.storeName, collectorValues);
  }
  static delete(id) {
    return DatabaseOperation.delete(this.storeName, id);
  }
  static clear() {
    return DatabaseOperation.clear(this.storeName);
  }

  /**
   * ncv: new collector value
   * scv: stored/offline collector value
   */
  static async merge(items) {
    const batchSize = 10;

    const collectorValues = items.map(serializeCollectorValue);

    return DatabaseOperation.transaction(
      DatabaseOperation.stores.collectorValues,
      async () => {
        const neighborhood = {};
        for (let i = 0; i < collectorValues.length; i += batchSize) {
          try {
            const slicedCollectorValues = collectorValues.slice(
              i,
              i + batchSize
            );
            const slicedCollectorValueIds = [
              ...new Set(
                slicedCollectorValues
                  .reduce((acc, ncv) => [...acc, ncv.id, ncv.duplicatedId], [])
                  .filter((ncv) => !!ncv)
              ),
            ];
            const storedCollectorValues = await DatabaseOperation.bulkGet(
              this.storeName,
              slicedCollectorValueIds
            ).then((scvs) =>
              scvs
                .filter((scv) => !!scv)
                .map((scv) => ({ id: scv.id, createdAt: scv.createdAt }))
            );

            const promises = slicedCollectorValues.map((ncv) => {
              CollectorUtils.serializeNeighborhood(ncv, neighborhood);

              const scv = storedCollectorValues.find((scv) =>
                [ncv.id, ncv.duplicatedId].includes(scv.id)
              );
              const deleteDuplicatedRecordId =
                ncv.id !== ncv.duplicatedId && ncv.duplicatedId;

              if (!scv)
                return (
                  (!ncv.collectorDeleted ||
                    CollectorUtils.isTruthy(ncv.value)) &&
                  this.put(ncv)
                );

              const shouldUpdate =
                new Date(ncv.createdAt) - new Date(scv.createdAt) >= 0;
              if (!shouldUpdate) return;

              if (ncv.collectorDeleted || !CollectorUtils.isTruthy(ncv.value))
                return this.delete(scv.id);

              return Promise.all([
                deleteDuplicatedRecordId &&
                  this.delete(deleteDuplicatedRecordId),
                this.put(ncv),
              ]);
            });

            await Promise.all(promises);
          } catch (err) {
            SentryService.sendError(err);
          }
        }

        await this.syncNeighboringRecords(neighborhood);
        return neighborhood;
      }
    ).then((neighborhood) =>
      AssetRepository.syncNeighboringRecords(neighborhood, {
        withTransaction: true,
      })
    );
  }

  static async syncNeighboringRecords(neighborhood, { withTransaction } = {}) {
    if (withTransaction)
      return DatabaseOperation.transaction(
        DatabaseOperation.stores.collectorValues,
        () =>
          super.syncNeighboringRecords(this.storeName, neighborhood, (data) =>
            super.collectorValueId(data)
          )
      );

    return super.syncNeighboringRecords(this.storeName, neighborhood, (data) =>
      super.collectorValueId(data)
    );
  }

  static async loadSegment(seg) {
    return super
      .loadSegment(this.storeName, seg)
      .then(deserializeCollectorValues)
      .catch(SentryService.sendError);
  }

  static async loadUnsuccess({ limit = 50 } = {}) {
    return DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.status
    )
      .notEqual(STATUS.SUCCESS)
      .limit(limit)
      .toArray()
      .then(deserializeCollectorValues);
  }

  static async loadSuccessInventory() {
    return DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.inventoryItemId
    )
      .above(0)
      .and((cv) => cv.status === STATUS.SUCCESS)
      .toArray()
      .then(deserializeCollectorValues);
  }

  static async removeSuccess() {
    const isOrderPending = await super.isOrderPending();
    const successAndNoPendingKeys = await DatabaseOperation.where(
      this.storeName,
      DatabaseOperation.indexes.status
    )
      .equals(STATUS.SUCCESS)
      .filter((cv) => !isOrderPending[cv.auditOrderId])
      .primaryKeys();

    return DatabaseOperation.bulkDelete(
      this.storeName,
      successAndNoPendingKeys
    );
  }
}
