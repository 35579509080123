import CommonOperations from "core/database/CommonOperations";

export default class SerializedCollectorValue {
  constructor(data) {
    this.value = data.value;
    // Owned
    this.orderId = data.auditOrderId || data.orderId;
    this.auditOrderId = this.orderId; // TODO: REDUNDANT: Migrate and remove it when it's possible
    this.docId = data.docId || "null";
    // Template
    this.templateId = data.templateId;
    // Service
    this.serviceId = data.serviceId;
    // Service Task
    this.serviceTaskId = data.serviceTaskId;
    this.serviceTaskName = data.serviceTaskName;
    // Review
    this.reviewId = data.reviewId;
    this.reviewName = data.reviewName;
    // Group
    this.groupId = data.groupId;
    this.groupName = data.groupName;
    // Subgroup
    this.subgroupId = data.subgroupId;
    this.subgroupName = data.subgroupName;
    // Collector
    this.collectorId = data.collectorId || data.id;
    this.collectorName = data.collectorName || data.name;
    this.name = this.collectorName; // TODO: REDUNDANT: Migrate and remove it when it's possible
    // Layout
    this.layoutId = data.layoutId;
    // Type
    this.typeKey = data.typeKey;
    this.typeProps = data.typeProps;
    // Unit
    this.unitKey = data.unitKey;
    this.unitName = data.unitName;
    // Inventory
    this.inventoryItemId = data.inventoryItemId || null;
    this.inventorySerieId = data.inventorySerieId || null;
    this.inventoryAmount = data.inventoryAmount || null;
    this.inventoryInitialAmount = data.inventoryInitialAmount || null;
    this.inventoryFinalAmount = data.inventoryFinalAmount || null;
    // Metadata
    this.duplicated = data.duplicated;
    this.duplicatedId = data.duplicatedId; //TODO: Experimentación
    this.required = data.required;
    this.sort = data.sort;
    this.creator = data.creator;
    this.entity = data.entity;
    this.createdAt = data.createdAt;
    this.status = data.status;
    this.collectorDeleted = data.collectorDeleted;
    this.id = CommonOperations.collectorValueId({
      ...data,
      collectorId: this.collectorId,
    });
    this.collectorIdx = data.collectorIdx; //TODO: Pending to delete
    this.subgroupIdx = data.subgroupIdx; //TODO: Pending to delete
    this.groupIdx = data.groupIdx; //TODO: Pending to delete
    this.reviewIdx = data.reviewIdx; //TODO: Pending to delete
  }
}
