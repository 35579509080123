// Libs
import { useCallback } from "react";
import { useDispatch } from "react-redux";
// Utils
import { debounce } from "utils/libs";
import { CollectorUtils } from "components/components";
// Actions
import {
  saveCollectorValues,
  setAutoSync,
} from "components/components/Collector/actions";

const useCollectorValues = () => {
  const dispatch = useDispatch();

  const sendCollectorValues = (collectorValues) => {
    dispatch(
      saveCollectorValues(
        CollectorUtils.getUnsuccessCollectors({
          collectors: collectorValues,
        })
      )
    );
  };

  const onCollectorValuesChange = useCallback(
    debounce(({ value, collectorProps }) => {
      if (value === null) return;

      CollectorUtils.onCollectorValueChange({
        value,
        collectorProps,
      }).then(
        (collectorValue) =>
          collectorValue && sendCollectorValues([collectorValue])
      );
    }, 1000),
    []
  );

  const onCollectorValuesFilter = (toDeleteCollectorValues) => {
    Promise.all(
      toDeleteCollectorValues.map(CollectorUtils.onCollectorValueChange)
    ).then(() => dispatch(setAutoSync({ active: true })));
  };

  return { onCollectorValuesChange, onCollectorValuesFilter };
};

export default useCollectorValues;
