import COLLECTOR_KEYWORDS from "./keywords";

export default class CheckUtils {
  //Collector
  static collectorExistsInCollectorValues = (collector, { id } = {}) =>
    collector.id === id;

  static invalidIdx = (idx) => idx === -1;

  static showCollectorName = (collector) =>
    !collector.hideLabel &&
    collector.typeKey !== COLLECTOR_KEYWORDS.COLLECTORS.TYPES.CONSUME_INVENTORY;

  static showCollectorGroupName = (collector) => collector.groupName !== null;
}
