const deserializeNullValue = (value) => (value === "null" ? null : value);

export default class DeserializedCollectorLayoutPhoto {
  constructor(clp) {
    Object.assign(this, clp);
    this.id = clp.photoId;
    this.layoutPhotoId = deserializeNullValue(clp.layoutPhotoId);
    this.duplicated = clp.duplicated === "true";
  }
}
