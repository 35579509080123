//Libs
import Immutable from "immutable";
//Utils
import CollectorUtils from "./CollectorUtils";
//Keywords
import COLLECTOR_KEYWORDS from "./keywords";
//Types
import COLLECTOR_TYPES from "./types";

//******** INITIAL STATE ***********/
const initialState = {
  //Review Manage
  reviewManage: {
    workflow: COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD,
    isControlPanelOpen: false,
    orderId: undefined,
    isFetching: false,
    sendingReport: false,
    sendingCollectorValues: false,
    selectedCurrentCollector: {},
    showSendConfirmationModal: false,
    forceReadOnlyCollector: undefined,
    collectorValues: [],
    collectorLayout: [],
    duplicatedCollectorLayout: [],
    transactionDocs: {
      docId: undefined,
      isFetching: false,
      data: [],
      updateState: {},
      generatedReports: {},
    },
    autoFillCollector: {
      isOpenConfirmation: false,
      serviceTaskId: undefined,
      combineOrderAndServiceTaskId: undefined,
      isActive: undefined,
      na: {},
    },
    collectorRequiredValidation: {
      highlight: {},
      state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING,
    },
    searchInData: {
      renderToolbar: false,
      data: "",
    },
    autoSync: {
      active: true,
      duplicatedLayoutIdsActive: true,
      isFetching: false,
    },
  },
};

const collectorReducer = (state = initialState, action) => {
  switch (action.type) {
    case COLLECTOR_TYPES.MUTATE_DIRECT_PROPS: {
      const keyValuePairs = action.payload;
      return {
        ...state,
        ...keyValuePairs,
      };
    }
    case COLLECTOR_TYPES.MUTATE_1OBJECT: {
      const { obj1Name, keyValuePairs } = action.payload,
        newObj = { ...state[obj1Name], ...keyValuePairs };
      return {
        ...state,
        [obj1Name]: newObj,
      };
    }
    case COLLECTOR_TYPES.SET_AUTO_FILL_COLLECTOR: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          autoFillCollector: {
            ...state.reviewManage.autoFillCollector,
            ...action.payload,
          },
        },
      };
    }
    case COLLECTOR_TYPES.UPDATE_COLLECTOR_REQUIRED_VALIDATION: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          collectorRequiredValidation: {
            ...state.reviewManage.collectorRequiredValidation,
            ...action.payload,
          },
        },
      };
    }
    case COLLECTOR_TYPES.SAVE_AUDITED_ORDER_COLLECTOR_VALUES_SUCCESS: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          sendingCollectorValues: false,
        },
      };
    }
    case COLLECTOR_TYPES.SET_COLLECTOR_VALUES_V2: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          collectorValues: action.payload,
        },
      };
    }
    case COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_SUCCESS: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          isFetching: false,
        },
      };
    }
    case COLLECTOR_TYPES.GET_SAVED_COLLECTOR_VALUES_BY_OTD_SUCCESS: {
      const { collectorValues, collectorLayout } = action.payload;
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          collectorValues,
          collectorLayout,
          isFetching: false,
        },
      };
    }
    case COLLECTOR_TYPES.SET_AUTO_SYNC: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          autoSync: {
            ...state.reviewManage.autoSync,
            ...action.payload,
          },
        },
      };
    }
    case COLLECTOR_TYPES.SET_TRANSACTION_DOCS: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: {
            ...state.reviewManage.transactionDocs,
            ...action.payload,
          },
        },
      };
    }
    case COLLECTOR_TYPES.SET_STATE_COLLECTOR_TRANSACTION_DOC: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: {
            ...state.reviewManage.transactionDocs,
            updateState: {
              ...state.reviewManage.transactionDocs.updateState,
              ...action.payload,
            },
          },
        },
      };
    }
    case COLLECTOR_TYPES.RESET_AUDITED_TRANSACTION_DOCS: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: initialState.reviewManage.transactionDocs,
        },
      };
    }
    case COLLECTOR_TYPES.RESET_AUTOFILL_COLLECTOR: {
      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          autoFillCollector: initialState.reviewManage.autoFillCollector,
        },
      };
    }
    case COLLECTOR_TYPES.GENERATE_REPORT_START: {
      const { docId, serviceTaskId, format } = action.payload;

      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: {
            ...state.reviewManage.transactionDocs,
            generatedReports: {
              ...state.reviewManage.transactionDocs.generatedReports,
              [docId]: {
                ...state.reviewManage.transactionDocs.generatedReports[docId],
                [serviceTaskId]: {
                  [format]: {
                    status: COLLECTOR_KEYWORDS.REPORTS.STATUS.LOADING,
                  },
                },
              },
            },
          },
        },
      };
    }
    case COLLECTOR_TYPES.GENERATE_REPORT_FAILED: {
      const { docId, serviceTaskId, format } = action.payload;

      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: {
            ...state.reviewManage.transactionDocs,
            generatedReports: {
              ...state.reviewManage.transactionDocs.generatedReports,
              [docId]: {
                ...state.reviewManage.transactionDocs.generatedReports[docId],
                [serviceTaskId]: {
                  [format]: {
                    status: COLLECTOR_KEYWORDS.REPORTS.STATUS.ERROR,
                  },
                },
              },
            },
          },
        },
      };
    }
    case COLLECTOR_TYPES.GENERATE_REPORT_SUCCESS: {
      const { docId, serviceTaskId, reportUrl, format } = action.payload;

      const availableReports = CollectorUtils.getAvailableReports(
        docId,
        state.reviewManage.transactionDocs.data
      ).map((serviceTask) => {
        if (serviceTask.id === serviceTaskId) {
          //PDF
          if (format === COLLECTOR_KEYWORDS.REPORTS.OUTPUT_FORMATS.PDF) {
            serviceTask.pdfReportUrl = reportUrl;
          }
          //XLSX
          else if (format === COLLECTOR_KEYWORDS.REPORTS.OUTPUT_FORMATS.XLSX) {
            serviceTask.xlsxReportUrl = reportUrl;
          }
        }
        return serviceTask;
      });

      const data = Immutable.List(state.reviewManage.transactionDocs.data)
        .toJS()
        .map((t) => {
          if (t.id === docId) t.availableReports = availableReports;
          return t;
        });

      return {
        ...state,
        reviewManage: {
          ...state.reviewManage,
          transactionDocs: {
            ...state.reviewManage.transactionDocs,
            data,
            generatedReports: {
              ...state.reviewManage.transactionDocs.generatedReports,
              [docId]: {
                ...state.reviewManage.transactionDocs.generatedReports[docId],
                [serviceTaskId]: {
                  [format]: {
                    status: COLLECTOR_KEYWORDS.REPORTS.STATUS.SUCCESS,
                  },
                },
              },
            },
          },
        },
      };
    }
    case COLLECTOR_TYPES.RESET: {
      return initialState;
    }
    default:
      return state;
  }
};

export default collectorReducer;
