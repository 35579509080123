// Libs
import React from "react";
// Components
import { Wrapper, Title, Caption } from "components/components";

const ArticleTitle = ({ inventoryItem }) => (
  <Wrapper
    padding="0"
    width="100%"
    height="50px"
    flexDirection="column"
    alignItems="stretch"
  >
    <Title ellipsis>{inventoryItem.item_name}</Title>
    <Wrapper padding="0" justifyContent="space-between">
      <Caption>ID: {inventoryItem.item_code}</Caption>
      {inventoryItem.serie && <Caption>Serie: {inventoryItem.serie}</Caption>}
    </Wrapper>
  </Wrapper>
);
export default ArticleTitle;
