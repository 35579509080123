//Libs
import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";
//IndexedDB
import "./utils/libs/indexedDB/idbHandler";
import "core/database/init";
//Service Worker
import registerServiceWorker from "./registerServiceWorker";
//Routes
import routes from "./utils/routes";
import RouteWithSubRoutes from "./utils/routes/RouteWithSubRoutes";
//Store
import store from "./store/configureStore";
//Context
import { RealtimeProvider } from "context/RealtimeContext";
//Components
import { ParticlesContainer } from "components/components";
//Styles
import "./index.css";
import { dark, lightness, GlobalStyle } from "./themes";
//Services
import { SentryService } from "services";
dotenv.config();
//Run services
SentryService.run();

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <Router>
      <RealtimeProvider>
        {/* TODO: El tema se tiene que guardar en el perfil del usuario y de all condicionarlo aqui */}
        <ThemeProvider theme={lightness}>
          <GlobalStyle />
          <ParticlesContainer
            width="100vw"
            height="100vh"
            backgroundColor="rgba(0,0,0,.2)"
            noParticles
            content={
              <div>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
              </div>
            }
          />
        </ThemeProvider>
      </RealtimeProvider>
    </Router>
  </Provider>,
  rootElement
);

//Register Official Service Worker
registerServiceWorker();
