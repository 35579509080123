//Libs
import React from "react";
import styled, { css } from "styled-components";
//Components
import IconButton from "@material-ui/core/IconButton";
import icon from "./eye.svg";
import selectedIcon from "./selected-eye.svg";

const Icon = styled.div`
  background-image: ${(props) =>
    css`url(${props.selected ? selectedIcon : icon})`};
  width: 24px;
  height: 24px;
`;

const EyeIcon = ({ button, selected, onClick }) =>
  button ? (
    <IconButton onClick={onClick}>
      <Icon selected={selected} />
    </IconButton>
  ) : (
    <Icon onClick={onClick} selected={selected} />
  );

export default EyeIcon;
