import DatabaseOperation from "./init";
import { idbHandler } from "utils/libs";
// Utils
import GENERAL from "utils/constants/general";
import { CollectorUtils } from "components/components";
import Immutable from "immutable";
import { GlobalUtils } from "utils";

const { STATUS } = GENERAL.ENV.UPLOAD_RESOURCE;

const checkBaseLoadParams = (seg) =>
  seg.templateId && seg.serviceId && seg.serviceTaskId && seg.reviewId;

export default class CommonOperations {
  constructor() {
    if (new.target === CommonOperations) {
      throw new Error("You cann't instantiate an abstract class");
    }
  }

  static removeFalsifyProps(record) {
    const props = Immutable.Map(record).toJS();
    Object.entries(props).forEach(([key, value]) => {
      if (
        value === null ||
        value === undefined ||
        value === "null" ||
        value === "undefined"
      )
        delete props[key];
      else if (GlobalUtils.checkIsNumber(value)) props[key] = +value;
    });
    return props;
  }

  static sortElements(a, b) {
    return a.sort > b.sort ? 1 : a.sort < b.sort ? -1 : 0;
  }

  static isSynchedId(value) {
    return GlobalUtils.checkIsNumber(value);
  }

  static segment(seg) {
    return {
      orderId: Number(seg.auditOrderId) || Number(seg.orderId) || null,
      docId: Number(seg.docId) || "null",
      templateId: Number(seg.templateId),
      serviceId: Number(seg.serviceId),
      serviceTaskId: Number(seg.serviceTaskId),
      reviewId: Number(seg.reviewId) || seg.reviewId,
      groupId: Number(seg.groupId) || seg.groupId || null,
      subgroupId: Number(seg.subgroupId) || seg.subgroupId || null,
      duplicated: seg.duplicated ? "true" : "false",
    };
  }

  static collectorEcosystemId({
    templateId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    subgroupId,
    collectorId,
    photoId = null,
    ...rest
  }) {
    const docId = !rest.docId || rest.docId === "null" ? null : rest.docId;
    const orderId = rest.auditOrderId || rest.orderId || null;
    const inventoryItemId = rest.itemId || rest.inventoryItemId || null;
    return `${orderId}.${docId}.${templateId}.${serviceId}.${serviceTaskId}.${reviewId}.${groupId}.${subgroupId}.${collectorId}.${inventoryItemId}.${photoId}`;
  }

  static deserializeCollectorEcosystemId = (id) => {
    const [
      orderId,
      docId,
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
      collectorId,
      inventoryItemId,
      photoId,
    ] = String(id).split(".");

    return {
      orderId,
      docId,
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
      collectorId,
      inventoryItemId,
      photoId,
    };
  };

  static collectorLayoutId(data) {
    if (!data || (typeof data !== "string" && typeof data !== "object")) return;

    const getId = (id) => String(id).split(".").slice(0, 9).join(".");

    if (typeof data === "string") {
      const id = getId(data);

      // Check valid id
      const [templateId, serviceId, serviceTaskId] = id.split(".").slice(2, 5);
      if (isNaN(templateId) || isNaN(serviceId) || isNaN(serviceTaskId)) return;

      return id;
    }

    return getId(this.collectorEcosystemId(data));
  }

  static collectorValueId(data) {
    const getId = (id) => String(id).split(".").slice(0, 10).join(".");
    return getId(this.collectorEcosystemId(data));
  }

  static subgroupId(data) {
    const getId = (id) => String(id).split(".").slice(0, 8).join(".");
    return getId(data);
  }

  static groupId(data) {
    const getId = (id) => String(id).split(".").slice(0, 7).join(".");
    return getId(data);
  }

  static reviewId(data) {
    const getId = (id) => String(id).split(".").slice(0, 6).join(".");
    return getId(data);
  }

  static assetId(data) {
    const getId = (id) => {
      const ids = String(id).split(".");
      if (ids.length > 10) ids.splice(9, 1); // Remove "inventoryItemId"
      return ids.join(".");
    };
    return getId(this.collectorEcosystemId(data));
  }

  static getNearestNeighbor(neighborhood, collectorLayoutId) {
    const neighbor = neighborhood[collectorLayoutId];
    if (neighbor) return neighbor;

    return (
      (() => {
        for (let key of Object.keys(neighborhood)) {
          if (
            CommonOperations.subgroupId(key) ===
            CommonOperations.subgroupId(collectorLayoutId)
          ) {
            const { reviewId, groupId, subgroupId } = neighborhood[key];
            return { reviewId, groupId, subgroupId };
          }

          if (
            CommonOperations.groupId(key) ===
            CommonOperations.groupId(collectorLayoutId)
          ) {
            const { reviewId, groupId } = neighborhood[key];
            return { reviewId, groupId };
          }

          if (
            CommonOperations.reviewId(key) ===
            CommonOperations.reviewId(collectorLayoutId)
          ) {
            const { reviewId } = neighborhood[key];
            return { reviewId };
          }
        }
      })() || {}
    );
  }

  static async loadSegment(storeName, seg) {
    if (!checkBaseLoadParams(seg)) return [];

    const {
      orderId,
      docId,
      templateId,
      serviceId,
      serviceTaskId,
      reviewId,
      groupId,
      subgroupId,
    } = this.segment(seg);
    const isNeighborActive = !serviceId; // If neighbor is active, we must delete serviceId from the query
    const serviceTaskLevel = () => {
      const serviceTaskIndex = isNeighborActive
        ? DatabaseOperation.indexes.data.neighborServiceTask
        : DatabaseOperation.indexes.data.serviceTask;
      const serviceTaskQuery = isNeighborActive
        ? [orderId, docId, templateId, serviceTaskId]
        : [orderId, docId, templateId, serviceId, serviceTaskId];

      return DatabaseOperation.where(storeName, serviceTaskIndex).equals(
        serviceTaskQuery
      );
    };

    const reviewLevel = () => {
      const reviewIndex = isNeighborActive
        ? DatabaseOperation.indexes.data.neighborReview
        : DatabaseOperation.indexes.data.review;
      const reviewQuery = isNeighborActive
        ? [orderId, docId, templateId, serviceTaskId, reviewId]
        : [orderId, docId, templateId, serviceId, serviceTaskId, reviewId];

      return DatabaseOperation.where(storeName, reviewIndex)
        .equals(reviewQuery)
        .and((cv) => !cv.groupId && !cv.subgroupId);
    };

    const groupLevel = () => {
      const groupIndex = isNeighborActive
        ? DatabaseOperation.indexes.data.neighborGroup
        : DatabaseOperation.indexes.data.group;
      const groupQuery = isNeighborActive
        ? [orderId, docId, templateId, serviceTaskId, reviewId, groupId]
        : [
            orderId,
            docId,
            templateId,
            serviceId,
            serviceTaskId,
            reviewId,
            groupId,
          ];

      return reviewLevel()
        .or(groupIndex)
        .equals(groupQuery)
        .and((cv) => !cv.subgroupId);
    };

    const subgroupLevel = () => {
      const subgroupIndex = isNeighborActive
        ? DatabaseOperation.indexes.data.neighborSubgroup
        : DatabaseOperation.indexes.data.subgroup;
      const subgroupQuery = isNeighborActive
        ? [
            orderId,
            docId,
            templateId,
            serviceTaskId,
            reviewId,
            groupId,
            subgroupId,
          ]
        : [
            orderId,
            docId,
            templateId,
            serviceId,
            serviceTaskId,
            reviewId,
            groupId,
            subgroupId,
          ];
      return groupLevel().or(subgroupIndex).equals(subgroupQuery);
    };

    const load = () => {
      if (subgroupId) return subgroupLevel();
      if (groupId) return groupLevel();
      if (reviewId) return reviewLevel();
      return serviceTaskLevel();
    };

    return load().toArray();
  }

  static async isOrderPending() {
    return idbHandler.getOrders().then((orders) =>
      orders.reduce((acc, o) => {
        acc[o.order_id] = true;
        return acc;
      }, {})
    );
  }

  static async syncNeighboringRecords(storeName, neighborhood, buildRecordId) {
    for (let [key, value] of Object.entries(neighborhood)) {
      const [templateId, serviceTaskId, reviewName, groupName, subgroupName] =
        CollectorUtils.deserializeNeighborhood(key);

      const reviewId = CollectorUtils.getDuplicableKeyOrId({
        level: !groupName && "review",
        reviewName,
        reviewId: value.reviewId,
      });

      const reviewLevel = () => {
        const reviewIndex =
          DatabaseOperation.indexes.data.anonymousNeighborReview;
        const reviewQuery = [
          Number(templateId),
          Number(serviceTaskId),
          reviewId,
        ];
        return DatabaseOperation.where(storeName, reviewIndex)
          .equals([...reviewQuery, STATUS.LOADING])
          .or(reviewIndex)
          .equals([...reviewQuery, STATUS.ERROR])
          .and((r) => r.reviewName === reviewName)
          .toArray()
          .then((records) =>
            records.map((record) => ({ ...record, file: null }))
          );
      };

      const promises = await reviewLevel().then((records) =>
        records.reduce((acc, record) => {
          const changes = {};

          if (
            this.isSynchedId(value.reviewId) &&
            CollectorUtils.checkDuplicable(record.reviewId)
          )
            changes.reviewId = value.reviewId;

          if (
            this.isSynchedId(value.groupId) &&
            CollectorUtils.checkDuplicable(record.groupId) &&
            groupName &&
            record.groupName === groupName
          )
            changes.groupId = value.groupId;

          if (
            this.isSynchedId(value.subgroupId) &&
            CollectorUtils.checkDuplicable(record.subgroupId) &&
            subgroupName &&
            record.subgroupName === subgroupName
          )
            changes.subgroupId = value.subgroupId;

          if (!Object.keys(changes).length) return acc;

          changes.id = buildRecordId({ ...record, ...changes });

          acc.push(DatabaseOperation.update(storeName, record.id, changes));

          return acc;
        }, [])
      );

      await Promise.all(promises);
    }
  }
}
